import { Alert, AlertTitle, Button, CircularProgress, Grid, Paper, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { BSEARCH_URI } from "../common/config";
import { useGlobalState } from "../common/globalState";
import { Box } from "@mui/system";
import { CreateHeader, CreateProperty, setGlobalVal } from "./common-tool";
import { MintNFTASICView } from "./mintNftAsic";
import { refine_html_text } from "./common-tool";
import { v4 as uuidv4 } from 'uuid';
async function search_acn(query, callback_fn) {
    try {
        //alert(JSON.stringify(params));
        axios.post(
            BSEARCH_URI + "acn_query", { "query": query }
        )
            .then(response => {
                if (response && response.data) {
                    var result = response.data;

                    if (!result || Object.keys(result).length === 0) {
                        result = { 'error': "The data does not exist in ABR database that we collected." };
                    }

                    callback_fn(result);
                }
                else {
                    callback_fn({});
                }

            })
    }
    catch (err) {
        var result = { 'error': err };
        callback_fn(result);
    }
}


export function ACNView(props) {
    const [state, dispatch] = useGlobalState();
    const [onSpin, setSpin] = useState(false);
    const [content, setContent] = useState({});
    const [query, setQuery] = useState("");
    const getRDFString = async () => {
      
        let uri = state.rdfHistory.result.uri;
        if (uri === undefined) {
            return '';
        }

        var convert_table = {
        
        };

        let asic = content;
        if (Object.keys(asic).length === 0) return;

        let record = '';
        let id = uuidv4();
        let time = new Date();
        let time_str = time.toISOString();
        id = id + time_str
        if (asic._id) {
            record += `
                <${uri}> azp:ASICID [
                    azp:value "${asic._id}" ;
                    azpr:public "N" ;
                    azpr:resource "${id}" ;
                ] .

                <${uri}> azp:personalTimestamp [
                    azp:value "${time_str}" ;
                    azpr:resource "${id}" ;
                    azpr:public "Y" ;
                ] . 
            `;
        }

        var keys = Object.keys(asic);
        keys.forEach(key => {
            if (convert_table[key] == undefined) return;
            record += `
                <${uri}> azp:${convert_table[key]} [
                    azp:value "${asic[key]}" ;
                    azpr:public "N" ;
                    azpr:resource "${id}" ;
                ] .
            `;
        });

        if (asic.BUS_ADDRESS) {
            var bus_addr = refine_html_text(asic.BUS_ADDRESS);

            var res = await axios.post(BSEARCH_URI + "query_address", {
                params: {
                    address: bus_addr,
                }
            });
            res = res.data;

            if (res.entity) {
                var value = res.entity;

                if (res.detailed_address != '') {
                    record += `
                        <${uri}> azp:locateAt [
                            azp:value ${value} ;
                            azp:details <${res.detailed_address}>
                            azpr:public "N" ;
                            azpr:resource "${id}" ;
                        ] .
                    `;
                } else {
                    record += `
                        <${uri}> azp:locateAt [
                            azp:value ${value} ;
                            azpr:public "N" ;
                            azpr:resource "${id}" ;
                        ] .
                    `;
                }

            }
        }
        return { id, time: time_str, record: record };
    }
    useEffect(() => {
      
        var q = "";
        if (state.entityID.length === 9) {
            q = state.entityID
        }
        if (state.acn !== "") {
            q = state.acn
        }

        if (q === "") {
            setContent([]);
            setSpin(false);
            return;
        }
        if (q === query) {
            setSpin(false);
            return;
        }

        setQuery(q);

    }, [state.entityID, state.acn, query, dispatch, state.acnResult, state])

    useEffect(() => {
        if (query === "") {
            setContent({ 'error': "No Found ACN Number: This information required ACN number." });
            return;
        }

        if (query === state.acnHistory.query) {
            setContent(state.acnHistory.result);
            setSpin(false);
            return;
        }
        setSpin(true);
        search_acn(query, result => {
            setContent(result);
            setSpin(false);
            dispatch({
                acn: query,
                acnHistory: { query: query, result: result }
            })
            var props = result;
            Object.keys(props).forEach(k => {
                var val = props[k];
                if (Array.isArray(val)) val = val[0];
                setGlobalVal(k, val, state, dispatch);
            })
        })
    }, [query])

    if (onSpin) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <CircularProgress size={50} />
            </Box>
        )
    } else {

        return (
            <div>

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant='h5'>
                            ASIC Search Result
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography sx={{ pl: 4, pr: 2, textAlign: 'left' }}>
                            {"This pane displays the information of legal entity from Australian Securities & Investments Commission(ASIC) public data."}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Paper sx={{ p: 4 }}>
                            {
                                content.error ?
                                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                        <Alert severity="info">
                                            <AlertTitle>Info</AlertTitle>
                                            {content.error}
                                        </Alert>
                                    </Paper>
                                    :
                                    CreateProperty('', content, null)
                            }

                        </Paper>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                        <Paper sx={{ p: 4 }} >
                            <MintNFTASICView uri={state.rdfHistory.result.uri} getRDFString={getRDFString} data={content} />
                        </Paper>
                    </Grid>
            </div>

        )
    }
}