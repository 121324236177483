import { Web3Provider } from "@ethersproject/providers";
import {
  Alert,
  AlertTitle,
  Button,
  DialogActions,
  DialogContent,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useGlobalState } from "../common/globalState";
import { AmountTextField } from "./amountText";
import button_style from "./style/button_style";
import dialog_button_style from "./style/dialog_button_style";
import { GasLimitOption } from "./components/GasLimitOption";
import { estimate_gas_fee, secure_query_with_type_data } from "./comm_token";
import { useWeb3React } from "@web3-react/core";
const {
  BootstrapDialog,
  BootstrapDialogTitle,
} = require("./components/bootstrap_dialog");

export function DepositButton() {
  const context = useWeb3React();
  const [open, setOpen] = useState(false);
  const [state, dispatch] = useGlobalState();
  const [view, setView] = useState(false);
  const [error, setError] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [gasFee, setGasFee] = useState(1);

  useEffect(() => {
    if (state.inputAmountError) {
      setBtnDisabled(true);
      return;
    }
    setBtnDisabled(false);
  }, [state.inputAmountError]);

  const estimateGasFee = async (event) => {
    let res = await estimate_gas_fee({
      type: "deposit",
    });
    setGasFee(res);
  };

  const handleClickDeposit = async () => {
    setError("");
    if (parseInt(state.amount) <= parseInt(gasFee)) {
      console.log(`${state.amount} <= ${gasFee}`);
      setError("Amount is less than gas limit.");
      return;
    }
    setView(true);
    try {
      if (gasFee > 0) {
        let result = await secure_query_with_type_data(
          {
            address: context.account,
            type: "call_deposit",
            amount: state.amount,
            gasLimit: gasFee,
            kind: "deposit",
          },
          state.exchanger,
          state.merc_contract_address
        );
        if (result.status != true) {
          if (result.error) {
            setError(result.error);
          } else {
            setError(
              "The requried gas fee exceeded the given gas limit. Please increase the gas limit."
            );
          }
          setView(false);
          return;
        }
      } else {
        var provider = new Web3Provider(window.ethereum);
        await state.mrc20_contract
          .connect(provider.getSigner())
          .deposit(state.amount);
      }
    } catch (err) {
      setError("Canceled the transaction.");
      console.log(err);
      setView(false);
      return;
    }
    setView(false);
    setOpen(false);
  };
  const handleClickOpen = () => {
    dispatch({
      amount: "",
      inputAmountError: true,
    });
    setError("");
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {view ? (
        <Alert severity="info" sx={{ marginBottom: "10px" }}>
          <AlertTitle>Info</AlertTitle>
          Transaction is submitting. You can close this dialog.
        </Alert>
      ) : (
        <div></div>
      )}
      {error !== "" ? (
        <Alert severity="error" sx={{ marginBottom: "10px" }}>
          <AlertTitle>Error</AlertTitle>
          {error}
        </Alert>
      ) : (
        <div></div>
      )}
      <Stack
        direction="column"
        spacing={{ xs: 1, sm: 1, md: 3 }}
        justifyContent="center"
        alignItems="center"
      >
        <div>
          Deposit from Layer 1 to Layer 2 to use in Accziom services (e.g.
          Business Search Service). Please enter the amount to deposit and click
          Deposit button. The transaction may take several minutes.
        </div>
        <div>
          <AmountTextField max={state.mrcBalance} />
        </div>
        <div>
          <GasLimitOption
            defaultValue={gasFee}
            setValue={setGasFee}
            min={1}
            estimate={estimateGasFee}
          />
        </div>
        <Button
          variant="outlined"
          disabled={btnDisabled}
          style={
            btnDisabled
              ? dialog_button_style.disabledButton
              : dialog_button_style.enabledButton
          }
          onClick={handleClickDeposit}
        >
          Deposit
        </Button>
      </Stack>
    </div>
  );
}
