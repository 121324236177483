import React from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Grid,
    IconButton,
    Link,
    List,
    ListItem,
    Paper,
    Switch,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { green, grey } from "@mui/material/colors";
import { makeStyles } from '@material-ui/core/styles';
import LockIcon from "@mui/icons-material/Lock";
import { useGlobalState } from "../common/globalState";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownOutlinedIcon from "@mui/icons-material/ThumbDownOutlined";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import { BSEARCH_URI } from "../common/config";
import { Stack } from "@mui/system";
import CancelIcon from '@mui/icons-material/Cancel';

const DATA_TYPE_SINGLE_VALUE = 0;
const DATA_TYPE_LINK_VALUE = 1;
const DATA_TYPE_ARRAY_SINGLE_VALUE = 2;
const DATA_TYPE_ARRAY_COMPLEX_VALUE = 3;
const DATA_TYPE_ARRAY_SINGLE_KIND_VALUE = 4;
const DATA_TYPE_DICT_SINGLE_VALUE = 5;
const DATA_TYPE_DICT_COMPLEX_VALUE = 6;

const header_colors = [
    '#ffffff',
    '#e0dede',
    '#ebd4a6',
    '#db9999'
]
const body_colors = [
    '#ffffff',
    '#f2efef',
    '#ffe5b3',
    '#efb9b9'
]


function is_simple_data(data) {
    if (data === undefined || data === null) return true;
    if (Array.isArray(data)) return false;
    if (data.constructor === Object) {
        let keys = Object.keys(data);
        let fkeys = keys.filter((e) => !e.startsWith("__"));
        if (data.uri && is_simple_type(data.uri) && fkeys.length <= 2) return true;
        if (data.value && fkeys.length === 1 && is_simple_value(data.value)) {
            return true;
        }
        return false;
    }
    return true;
}

function isEqualLength(a, b) {
    let a1 = a.filter((e) => !e.startsWith("__"));
    let b1 = b.filter((e) => !e.startsWith("__"));
    return a1.length === b1.length;
}

function is_single_kind_array(data) {
    if (data === undefined || data === null) return false;
    if (data.length <= 1) return false;

    if (data[0].constructor !== Object) return false;
    let skeys = Object.keys(data[0]);
    let l = skeys.length;

    for (var i = 0; i < data.length; i++) {
        if (data[i].constructor !== Object) return false;
        let keys = Object.keys(data[i]);
        let ll = keys.length;
        if (isEqualLength(keys, skeys)) return false;
        for (var j = 0; j < ll; j++) {
            let k = keys[j];
            if (k.startsWith("__")) continue;
            if (data[0][k] === undefined) {
                return false;
            }
            if (!is_simple_data(data[i][j])) return false;
        }
    }
    return true;
}

function is_simple_type(data) {
    if (data.constructor === Object) return false;
    if (Array.isArray(data)) return false;
    return true;
}

function is_simple_value(data) {
    if (is_simple_type(data)) return true;
    if (data.constructor !== Object) return false;
    let keys = Object.keys(data);
    let fkeys = keys.filter((e) => !e.startsWith("__"));
    if (data.uri && is_simple_type(data.uri) && fkeys.length <= 2) return true;
    return false;
}
function get_data_type(data) {
    if (data === undefined || data === null) return DATA_TYPE_SINGLE_VALUE;

    if (Array.isArray(data)) {
        if (is_single_kind_array(data)) return DATA_TYPE_ARRAY_SINGLE_KIND_VALUE;
        for (let i = 0; i < data.length; i++) {
            if (!is_simple_data(data[i])) return DATA_TYPE_ARRAY_COMPLEX_VALUE;
        }
        return DATA_TYPE_ARRAY_SINGLE_VALUE;
    }

    if (data.constructor === Object) {
        let keys = Object.keys(data);
        let fkeys = keys.filter((e) => !e.startsWith("__"));
        if (data.uri && is_simple_type(data.uri) && fkeys.length <= 2) return DATA_TYPE_LINK_VALUE;
        if (data.value && fkeys.length === 1 && is_simple_value(data.value)) {
            return DATA_TYPE_SINGLE_VALUE;
        }
        for (let i = 0; i < keys.length; i++) {
            if (!is_simple_data(data[keys[i]])) return DATA_TYPE_DICT_COMPLEX_VALUE;
        }
        return DATA_TYPE_DICT_SINGLE_VALUE;
    }

    return DATA_TYPE_SINGLE_VALUE;
}

export function refine_html_text(html_str) {
    var result = html_str.replace(/[&]lt[;].*[&]gt[;]/g, ", ");
    result = result.replace(/[<].*[>]/g, ", ");
    result = result.replace(/  /g, " ");
    return result;
}

function RemarkBtn(props) {
    return (
        <div>
            <IconButton size="small">
                <ThumbUpOutlinedIcon />
                {props["##yes##"] || 0}
            </IconButton>
            <IconButton size="small">
                <ThumbDownOutlinedIcon />
                {props["##no##"] || 0}
            </IconButton>
            <IconButton size="small">
                <EditIcon />
                Edit
            </IconButton>
        </div>
    );
}
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: green[600],
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

function lookupTableHeader(data, ord, result = {}) {
    if (Array.isArray(data)) {
        if (data.length <= 1) return [];

        data.map((e) => lookupTableHeader(e, ord, result));
    }
    if (data.constructor == Object) {
        var key_list = Object.keys(data);
        if (key_list.length === 2 && data.uri && data.label) {
            return Object.keys(result);
        }
        var sorted_key_list = key_list;
        if (ord) {
            sorted_key_list = key_list.sort((a, b) => {
                var aa = parseInt(ord[a]);
                var bb = parseInt(ord[b]);
                return aa - bb;
            });
        }
        sorted_key_list.forEach((e) => {
            result[e] = 1;
        });
    }
    return Object.keys(result);
}

var g_index = 0;

function CreateLinkValue(data, remark = false, action = {}, ignoreSet, isTimeStamp = false) {
    if (data.label === "(Payment is required to access the data)") {
        return (
            <div>
                <Link href="#" underline="none" sx={{ px: "26px" }}>
                    <LockIcon sx={{ fontSize: "16px" }} />
                </Link>
            </div>
        );
    }
    let label = data.label ? data.label : data.uri;

    if (isDeletedData(data, ignoreSet)) return null;

    return (
        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: "100%", px: "26px" }}>
            <Link href="#" underline="none" metauri={data.uri} metalabel={label} onClick={action.hyperlink} sx={{ fontSize: "14px" }}>
                {label}
            </Link>
            {remark && createVoteValue(data, action)}
        </Stack>
    );
}

function getHyperValue(data, index = 0, remark = false, action = {}, ignoreSet) {
    g_index += 1;
    if (Array.isArray(data)) {
        return data.map((e, index) => getHyperValue(e, index, remark));
    }

    if (data.constructor === Object) {
        var key_list = Object.keys(data);
        var fkey_list = key_list.filter((e) => !e.startsWith("__"));
        if (fkey_list.length <= 2 && data.uri) {
            if (data.label === "(Payment is required to access the data)") {
                return (
                    <div>
                        <Link key={index} hfref="#" underline="none">
                            <LockIcon />
                        </Link>
                    </div>
                );
            }
            let label = data.label ? data.label : data.uri;
            if (isDeletedData(data, ignoreSet)) return null;
            return (
                <Stack direction="row" spacing={2}>
                    <Link key={index} href="#" underline="none" metauri={data.uri} metalabel={label} onClick={action.hyperlink}>
                        {label}
                    </Link>
                    {remark && createVoteValue(data)}
                </Stack>
            );
        }

        var tableHeader = lookupTableHeader(data, null);
        if (tableHeader.length > 0) {
            return (
                <TableContainer key={index} component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {tableHeader.map((e, index) => {
                                    return (
                                        <StyledTableCell align="center" key={index}>
                                            {e}
                                        </StyledTableCell>
                                    );
                                })}
                                {remark && (
                                    <StyledTableCell align="center" key="remark">
                                        remark
                                    </StyledTableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>{CreateTableRow("", data, tableHeader, g_index, remark, action.hyperlink)}</TableBody>
                    </Table>
                </TableContainer>
            );
        }
        return (
            <div key={index}>
                <Typography sx={{ overflowWrap: "break-word" }}>{JSON.stringify(data)}</Typography>

                {remark && <RemarkBtn />}
            </div>
        );
    }

    if (data === "(Payment is required to access the data)") {
        return (
            <div key={index}>
                <LockIcon key={index} />
            </div>
        );
    }

    return (
        <div key={index}>
            <Typography sx={{ overflowWrap: "break-word" }}>{JSON.stringify(data)}</Typography>
        </div>
    );
}

function isDeletedData(d, s) {
    for (var i = 0; i < s.length; i++) {
        if (d["__id"] === s[i]["__id"]) return true;
    }
    return false;
}

function CreateTableRow(prop, data, fields, pindex, remark, action = {}, ignoreSet = []) {
    if (isDeletedData(data, ignoreSet)) return null;
    return (
        <StyledTableRow
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            onClick={(e) => {
                if (action.hyperlink) action.hyperlink(e);
            }}
        >
            {fields.map((k, index) => {
                var cont = data[k];
                if (cont === undefined) {
                    return <StyledTableCell align="center" key={index}></StyledTableCell>;
                }
                return (
                    <StyledTableCell
                        align="center"
                        key={index}
                        onClick={(e) => {
                            if (action.hyperlink) action.hyperlink(e);
                        }}
                    >
                        {
                            CreateView(cont, null, false, action, ignoreSet)
                            //getHyperValue(cont, 0, false, action)
                        }
                    </StyledTableCell>
                );
            })}
            {remark && createVoteValue(data, action)}
        </StyledTableRow>
    );
}

function CreateValue(prop, result, ord, remark, clickHyperLink = null) {
    var tableHeader = lookupTableHeader(result, ord);
    if (Array.isArray(result)) {
        if (tableHeader.length > 0) {
            return (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {tableHeader.map((e, index) => {
                                    return (
                                        <StyledTableCell align="center" key={index}>
                                            {e}
                                        </StyledTableCell>
                                    );
                                })}
                                {remark && (
                                    <StyledTableCell align="center" key="remark">
                                        remark
                                    </StyledTableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {result.map((e, index) => {
                                return CreateTableRow(prop, e, tableHeader, index, remark, clickHyperLink);
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            );
        }
        return (
            <List>
                {result.map((e, index) => {
                    return <ListItem key={index}>{getHyperValue(e, index, remark, clickHyperLink)}</ListItem>;
                })}
            </List>
        );
    }

    return getHyperValue(result, 0, remark, clickHyperLink);
}

export function getNumber(query) {
    let q = query.replace(/ /g, "");
    var a = parseInt(q, 10);
    if (a == q) {
        return q;
    }
    return "";
}

export function setGlobalVal(key, val, state, dispatch) {
    try {
        switch (key) {
            case "ABN":
                if(val.constructor === Object && val.value != undefined) {
                    var str_value = val.value.toString();
                    if (state.abn !== str_value.trim()) {
                        dispatch({
                            abn: str_value.trim(),
                        });
                    }
                }else{
                    var str_value = val.toString();
                    if (state.abn !== str_value.trim()) {
                        dispatch({
                            abn: str_value.trim(),
                        });
                    }
                }
                break;
            case "ACN":      
            case "ASIC Number":
                var str_value = "";
                if(val.constructor === Object && val.value != undefined) {
                    str_value = val.value.toString();
                    if (state.acn !== str_value.trim() && str_value.trim() !== "") {
                        dispatch({
                            acn: str_value.trim(),
                        });
                    }
                }else{
                    str_value = val.toString();
                    if (state.acn !== str_value.trim() && str_value.trim() !== "") {
                        dispatch({
                            acn: str_value.trim(),
                        });
                    }
                }
                break;
            case "Entity Name":
            case "Legal Name":
            case "Company Name":
            case "Current Name":
            case "Trading Name":
            case "Business Name":
            case "Business":
                {
                    let name_array = [];
                    if (Array.isArray(val)) {
                        name_array = val;
                    } else if (val.value) {
                        name_array = val.value;
                    } else {
                        val = val.trim();
                        name_array = val.split(";");
                    }
                    let filtered_array = name_array.filter((e) => {
                        return state.entityName.indexOf(e) === -1;
                    });
                    if (filtered_array.length > 0) {
                        var newVal = [...state.entityName, ...filtered_array];
                        dispatch({
                            entityName: newVal,
                        });
                    }
                }
    
                break;
            default:
                break;
        }
    } catch (error) {
        console.log(error);
        console.log(val);
    }
    
}

export function CreateProperty(group, result, ord, remark = false, action = {}) {
    return CreateView(result, ord, false, action);
    /*
          if (Array.isArray(result)) {
              return CreateValue(group, result, ord, remark, clickHyperLink);
          }
          
          if (result.constructor === Object) {
              var key_list = Object.keys(result);
      
              var sorted_key_list = key_list;
              if (ord) {
                  sorted_key_list = key_list.sort((a,b) => {
                      var aa = parseInt(ord[a]);
                      var bb = parseInt(ord[b]);
                      return aa-bb;
                  });
              }
      
              return (
                  <div>
                      {
                          sorted_key_list.map((e,index)=>{
                              //console.log(`${group}_${index}`)
                              return (
                                  
                                  <Grid key={index}>
                                      <Grid item sx={{textAlign:"left", ml:5, mr:5}}>
                                          <Typography variant="BUTTON TEXT">{e}</Typography>
                                      </Grid>
                                      <Grid item sx={{textAlign:"left", ml:10, mr:5, mt:1, mb:2}}>
                                      {
                                          CreateValue(`${group}_${e}`, result[e], ord, remark, clickHyperLink)
                                      }
                                      </Grid>
                                  </Grid>
                              )
                          })
                      }
                      
                  </div>
              )
          }
      
          return (
              <Typography>
                 {result.toString()}
              </Typography>
          )
          */
}

function createVoteValue(result, action = {}) {
    let y = result.__yes || 0;
    let n = result.__no || 0;
    let id = result.__id || "";
    if (id == "") return null;
    return (
        <Stack direction="row">
            {/* <Button
                variant="text"
                color="success"
                startIcon={<ThumbUpOutlinedIcon />}
                onClick={evt => { if (action.agree) action.agree(id, result) }}
            >
                {
                    y
                }
            </Button> */}
            <IconButton
                size="small"
                onClick={(evt) => {
                    if (action.disagree) action.disagree(id, result);
                }}
            >
                <ThumbDownOutlinedIcon sx={{ color: "black", fontSize: "14px" }} />
            </IconButton>
            <IconButton
                size="small"
                onClick={(evt) => {
                    if (action.delete) action.delete(id, result);
                }}
            >
                <CancelIcon sx={{ color: "#c70000", fontSize: "14px" }} />
            </IconButton>
        </Stack>
    );
}

/*
    action:
        agree(id)
        disagree(id)
        delete(id)
        hyperlink()
*/

function CreateView(result, ord, remark = false, action = {}, ignoreSet = [], isTimeStamp = false) {
    let type = get_data_type(result);
    let PAY_INFORM = "(Payment is required to access the data)";
    if (type === DATA_TYPE_SINGLE_VALUE) {
        if (result === PAY_INFORM || (result.value && result.value === PAY_INFORM)) {
            return (
                <div sx={{ px: "26px" }}>
                    <LockIcon sx={{ fontSize: "16px" }} />
                </div>
            );
        } else {
            if (result["__id"]) {
                if (isDeletedData(result, ignoreSet)) return null;

                if (result.value.label === PAY_INFORM) {
                    remark = false;
                }
                
                return (
                    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: "100%", "&:hover": { bgcolor: "#d5d5d5", cursor: "pointer" }, "&:hover .vote-box": { visibility: 'visible' } }}>
                        {CreateView(result.value, ord, false, action, ignoreSet)}
                        <Box className="vote-box" sx={{ pr: "12px", visibility: 'hidden' }}>
                            {remark && createVoteValue(result, action)}
                        </Box>
                    </Stack>
                );
            } else if (result.value) {
                return (
                    <div>
                        <Typography sx={{ overflowWrap: "break-word", fontSize: "14px", px: "26px" }}>{result.value.toString()}</Typography>
                    </div>
                );
            } else {
                return (
                    <div>
                        <Typography sx={{ overflowWrap: "break-word", fontSize: "14px", px: "26px" }}>{result.toString()}</Typography>
                    </div>
                );
            }
        }
    } else if (type === DATA_TYPE_LINK_VALUE) {
        return CreateLinkValue(result, remark, action, ignoreSet, isTimeStamp);
    } else if (type === DATA_TYPE_ARRAY_COMPLEX_VALUE || type === DATA_TYPE_ARRAY_SINGLE_VALUE) {
        return CreateArrayDicData(result, ord, remark, action, ignoreSet, isTimeStamp);
    } else if (type === DATA_TYPE_ARRAY_SINGLE_KIND_VALUE) {
        return CreateTableData(result, ord, remark, action, ignoreSet, isTimeStamp);
    } else if (type === DATA_TYPE_DICT_SINGLE_VALUE) {
        return CreateSimpleDictData(result, ord, remark, action, ignoreSet, isTimeStamp);
    } else if (type === DATA_TYPE_DICT_COMPLEX_VALUE) {
        return CreateComplexDictData(result, ord, remark, action, ignoreSet, isTimeStamp);
    }
}

function CreateComplexDictData(result, ord, remark, action, ignoreSet, isTimeStamp = false) {
    var key_list = Object.keys(result);

    var sorted_key_list = key_list.filter((e) => !e.startsWith("__"));
    if (ord) {
        sorted_key_list = sorted_key_list.sort((a, b) => {
            var aa = parseInt(ord[a]);
            var bb = parseInt(ord[b]);
            return aa - bb;
        });
    }

    var isSetTimeStamp = false;
    sorted_key_list.forEach((e) => {
        if(e === "##order##") {
            isSetTimeStamp = true;
        }
    });

    return (
        <Box>
            {sorted_key_list.map((e, index) => {
                if (e === "##order##") return null;
                if(isSetTimeStamp) {
                    isTimeStamp = e === "TimeStamp" ? true : false;
                }
                return (
                    <>
                        <Typography sx={{ overflowWrap: "break-word", fontSize: "14px", color: "black", fontWeight: "700", mt: 1, px: "26px" }}>{e}</Typography>
                        <Box sx={{ pl: "26px" }}>{CreateView(result[e], ord, remark, action, ignoreSet, isTimeStamp)}</Box>
                    </>
                );
            })}
        </Box>
    );
}

function CreateSimpleDictData(result, ord, remark, action, ignoreSet, isTimeStamp = false) {
    var key_list = Object.keys(result);

    var sorted_key_list = key_list.filter((e) => !e.startsWith("__"));
    if (ord) {
        sorted_key_list = sorted_key_list.sort((a, b) => {
            var aa = parseInt(ord[a]);
            var bb = parseInt(ord[b]);
            return aa - bb;
        });
    }

    var isSetTimeStamp = false;
    sorted_key_list.forEach((e) => {
        if(e === "##order##") {
            isSetTimeStamp = true;
        }
    });

    return (
        <div>
            {sorted_key_list.map((e, index) => {
                if (e === "##order##") return null;
                if(isSetTimeStamp) {
                    isTimeStamp = e === "TimeStamp" ? true : false;
                }
                return (
                    <Stack direction="row" alignItems="center" sx={{ width: "100%", "&:hover": { bgcolor: "#d5d5d5", cursor: "pointer" }, "&:hover .vote-box": { visibility: 'visible' } }}>
                        <Typography sx={{ overflowWrap: "break-word", fontSize: "14px", color: "black", pl: "26px", minWidth: "300px" }}>
                            {e}
                        </Typography>
                        {CreateView(result[e], ord, remark, action, ignoreSet, isTimeStamp)}
                    </Stack>
                );
            })}
        </div>
    );
}

function CreateTableData(result, ord, remark, action, ignoreSet, isTimeStamp = false) {
    var tableHeader = lookupTableHeader(result, ord);
    if (tableHeader.length === 0) {
        return (
            <List>
                {result.map((e, index) => (
                    <ListItem key={index}>{CreateView(e, ord, remark, action, ignoreSet)}</ListItem>
                ))}
            </List>
        );
    }
    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        {tableHeader.map((e, index) => {
                            return (
                                <StyledTableCell align="center" key={index}>
                                    {e}
                                </StyledTableCell>
                            );
                        })}
                        {remark && (
                            <StyledTableCell align="center" key="remark">
                                remark
                            </StyledTableCell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {result.map((e, index) => {
                        return CreateTableRow("", e, tableHeader, index, remark, action, ignoreSet);
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}


function CreateArrayDicData(result, ord, remark, action, ignoreSet, isTimeStamp = false) {
    
    if(!Array.isArray(result) || result.length == 0) return;
    var max_cnt = 0;
    var sum_cnt = 0;
    var colIndex = 0;
    var is_all_same = false;
    var recommend_result = {};
    is_all_same = isAllSame(result);
    if(!is_all_same) {
        result.forEach((r) => {
            var r_count = r['__count'] == undefined ? 0 : Number(r['__count']);
            sum_cnt = sum_cnt + r_count;
            if(r_count > max_cnt) {
                max_cnt = r_count;
            }
        });
        colIndex = getProbabilityColorIndex(max_cnt, sum_cnt);
        recommend_result = result.find((r) => {
            return Number(r['__count']) == max_cnt;
        });
    }else{
        colIndex = 1;
        recommend_result = result[0];
    }
    if(isTimeStamp) {
        return (
            <List sx={{ p: 0 }}>
                {result.map((e, index) => {
                    return (
                        <ListItem key={index} sx={{ p: 0, m: 0 }}>
                            {CreateView(e, ord, remark, action, ignoreSet)}
                        </ListItem>
                    );
                })}
            </List>
        )
    }else {
        return (
            <Accordion sx={{boxShadow: 0}}>
                <AccordionSummary
                expandIcon={<ArrowDropDownIcon />}
                sx={{ m:0, boxShadow: 0, borderRadius : 0, backgroundColor: header_colors[colIndex], 
                    minHeight:0, fontSize: 16, fontWeight: 'bold', 
                    "&.Mui-expanded": {m:0, minHeight:0},
                    "&.css-g8ubsv-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded": {m:0, minHeight:0},
                    "& .Mui-expanded": {m:0, minHeight:0},
                    "& .MuiAccordionSummary-content": {m:0, minHeight:0},
                    "& .css-o4b71y-MuiAccordionSummary-content.Mui-expanded": {m:0, minHeight:0}}}
                >
                    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: "100%", "&:hover": { cursor: "pointer" }, "&:hover .vote-box": { visibility: 'visible' } }}>
                        {CreateView(recommend_result, ord, false, action, ignoreSet)}
                        <Box className="vote-box" sx={{ pr: "12px", visibility: 'hidden' }}>
                            {remark && createVoteValue(recommend_result, action)}
                        </Box>
                    </Stack>
                </AccordionSummary>
                <AccordionDetails sx={{ backgroundColor: body_colors[colIndex] }}>
                    <List sx={{ p: 0 }}>
                        {result.map((e, index) => {
                            return (
                                <ListItem key={index} sx={{ p: 0, m: 0 }}>
                                    {CreateView(e, ord, false, action, ignoreSet)}
                                </ListItem>
                            );
                        })}
                    </List>
                </AccordionDetails>
            </Accordion>
        );
    }
}

function isAllSame(result) {
    var is_all_same = true;
    var f_v = result[0];
    for(var i = 0; i < result.length; i++) {
        if(f_v['__count'] != result[i]['__count']) {
            is_all_same = false;
            break;
        }
    }
    return is_all_same;
};

function getProbabilityColorIndex(max, total) {
    if(total == 0) return 0;
    var percent = (max / total) * 100;
    if(percent < 33) {
        return 3;
    }else if(percent >= 34 && percent < 68) {
        return 2;
    }else{
        return 0;
    }
}

export function CreateHeader(result, ord, discount = 1, remark = false, action = {}, ignoreSet = []) {
    return CreateView(result, ord, true, action, ignoreSet);
    /*
      if (Array.isArray(result)) {
          return (
              <List>
              {
                  result.map((e, index)=>{
                      return (
                          <ListItem key={index}>
                              {CreateHeader(e, ord, discount, remark, clickHyperLink)}
                          </ListItem>
                      )
                  })
              }
              </List>
          )
      }
  
      if (result.constructor === Object) {
          var key_list = Object.keys(result);
  
          var sorted_key_list = key_list;
          if (ord) {
              sorted_key_list = key_list.sort((a,b) => {
                  var aa = parseInt(ord[a]);
                  var bb = parseInt(ord[b]);
                  return aa-bb;
              });
          } 
          
          return (
              <div>
                  {
                      sorted_key_list.map((e,index)=>{
                          if (e === "##order##") return (null);
                          return (
                              <Accordion defaultExpanded={true} key={index}>
                                  <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                  >
                                      <Typography variant="h6">{e}</Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                      {
                                          discount <= 1 ?
                                          CreateProperty(e, result[e], ord, remark, clickHyperLink) :
                                          CreateHeader(result[e], ord, discount-1, remark, clickHyperLink)
                                      }
                                  </AccordionDetails>
                              </Accordion>
                          )
                      })
                  }
                  
              </div>
          )
      }
      return (
          <Typography>
             {result.toString()}
          </Typography>
      )
      */
}

export async function request(resolve, address, fee, reason) {
    axios
        .post(BSEARCH_URI + "request", {
            address: address,
            fee: fee,
            reason: reason,
        })
        .then((response) => {
            resolve(response.data);
        });
}

export const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    "& .MuiSwitch-track": {
        borderRadius: 22 / 2,
        "&:before, &:after": {
            content: '""',
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            width: 16,
            height: 16,
        },
        "&:before": {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main)
            )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
            left: 12,
        },
        "&:after": {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main)
            )}" d="M19,13H5V11H19V13Z" /></svg>')`,
            right: 12,
        },
    },
    "& .MuiSwitch-thumb": {
        boxShadow: "none",
        width: 16,
        height: 16,
        margin: 2,
    },
}));
