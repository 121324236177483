import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Avatar, Box, Button, CircularProgress, Divider, FormControl, IconButton, InputBase, InputLabel, MenuItem, Select } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useState } from 'react';
import axios from 'axios';
import EntityCandidate from './EntityCandidate';
import { useGlobalState } from '../common/globalState';
import { BSEARCH_URI } from '../common/config';
import { RDFView } from './RDFView';
import { blueGrey, brown, grey, purple, yellow } from '@mui/material/colors';


import * as React from 'react';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';
import { styled } from '@mui/material/styles';
import AcnIcon from '../icons/acn.ico';
import AbrIcon from '../icons/abr.ico';
import AnzsicIcon from '../icons/anzsic.ico';
import AsxIcon from '../icons/asx.ico';
import GbgIcon from '../icons/gbg.png';
import LeiIcon from '../icons/lei.svg';
import MapIcon from '../icons/map.ico';
import BingIcon from '../icons/microsoft.png';
import TpbIcon from '../icons/tpb.ico';
import TruelocalIcon from '../icons/truelocal.png';
import AccziomIcon from '../icons/accziom.png';
import { useWeb3React } from '@web3-react/core';
import { ABNView } from './ABNView';
import { getNumber } from './common-tool';
import { ACNView } from './ACNView';
import { TPBView } from './TPBView';
import { TrueLocalView } from './TrueLocalView';
import { ASXView } from './ASXView';
import { LEIView } from './LEIView';
import { GBGView } from './GBGView';
import { BingView } from './BingView';
import { MapView } from './MapView';
import { ANZSICView } from './ANZSICView';
import SelectInput from '@mui/material/Select/SelectInput';
import { DBList } from './DBList';
import { RDFDetails } from './RDFDetails';
import { lineHeight } from '@mui/system';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import AddIcon from '@mui/icons-material/Add';
import { XERO } from './XERO';
import { QuickBook } from './quickBook';
import { CA } from './charteredAccount';
import { PA } from './publicAccountant';

const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
  position: 'absolute',
  '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
    top: theme.spacing(2),
    left: theme.spacing(2),
  },
}));

const actions = [
  // { icon: <img src={AccziomIcon} width={30} alt="Z" />, name: 'Accziom Database' },
  { icon: <img src={AbrIcon} width={30} alt="R" />, name: 'ABR' },
  { icon: <img src={AcnIcon} width={30} alt="C" />, name: 'ASIC' },
  { icon: <img src={AcnIcon} width={30} alt="G" />, name: 'ASIC Paid' },
  { icon: <img src={BingIcon} width={30} alt="B" />, name: 'Bing' },
  { icon: <img src={AnzsicIcon} width={30} alt="B" />, name: 'ANZSIC' },
  { icon: <img src={TpbIcon} width={30} alt="P" />, name: 'TPB' },
  //  { icon: <img src={TruelocalIcon} width={30} alt="L"/>, name: 'TrueLocal' },
  { icon: <img src={AsxIcon} width={30} alt="S" />, name: 'ASX' },
  { icon: <img src={LeiIcon} width={30} alt="L" />, name: 'LEI' },
  { icon: <img src={MapIcon} width={30} alt="M" />, name: 'MAP' },
  { icon: <img src={MapIcon} width={30} alt="M" />, name: 'XERO' },
  { icon: <img src={MapIcon} width={30} alt="M" />, name: 'QuickBook' },
  { icon: <img src={MapIcon} width={30} alt="M" />, name: 'CHARTERED-ACCOUNTANT' },
  { icon: <img src={MapIcon} width={30} alt="M" />, name: 'PUBLIC-ACCOUNTANT' },
];

function TabMark(name, selected) {
  return (
    <IconButton sx={{ width: 40, height: 25 }}>
      <Typography fontSize='0.8rem' color={selected ? 'secondary' : 'grey'}>
        {name}
      </Typography>
    </IconButton>
  )
}

function MenuListItem({ title, color, handleSelect, isSelected }) {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        borderBottom: "1px solid",
        borderColor: color,
        pb: "10px",
        "&:hover": {
          cursor: "pointer"
        },
      }}
      onClick={e => handleSelect(title)}
    >
      <Typography
        sx={{
          color: color,
          fontSize: "12px",
          fontWeight: "700",
        }}
      >
        {title === "ACCZIOM" ? "Accziom Database" : title}
      </Typography>
      {isSelected && <PlayArrowIcon sx={{ color: color, height: "18px", width: "18px" }} />}
    </Box>
  )
}

function SpeedDialTooltipOpen() {
  const [open, setOpen] = React.useState(false);
  const [state, dispatch] = useGlobalState();
  const [view, setView] = useState(state.curView);

  const itemHeight = 60;
  const itemWidth = 160;
  const topMargin = 70;
  const lineWidth = 2;
  const lineColor = '#d0d0d0';
  const viewIndex = actions.findIndex((action) => action.name === view);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(true);
  const handleSelect = (value) => {
    console.log(value)

    setView(value)
    dispatch({
      curView: value
    })
  };

  // console.log(view)

  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: "column",
          position: 'absolute',
          right: 20,
          top: topMargin,
          alignItems: 'center'
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          sx={{ gap: "12px", width: "172px" }}
        >
          <MenuListItem title="ACCZIOM" color={"ACCZIOM" === view ? "#146EB6" : "#2895EB"} isSelected={"ACCZIOM" === view ? true : false} handleSelect={handleSelect} />
          <Typography sx={{ fontSize: "9px", fontWeight: "700", color: "#333", mt: "30px" }}>SOURCES</Typography>
          {actions.map((action) => {
            return (
              <MenuListItem key={action.name} title={action.name} color={action.name === view ? "#146EB6" : "#2895EB"} isSelected={action.name === view ? true : false} handleSelect={handleSelect} />
            );
          })}
        </Box>
        <Button sx={{ borderRadius: "20px", padding: "5px 6px", bgcolor: "#2895EB", justifyContent: "flex-start", gap: "10px", mt: "18px", width: "100%", "&:hover": { bgcolor: "#2895EB" } }}>
          <AddIcon sx={{ width: "18px", height: "18px", bgcolor: "white", color: "black", borderRadius: "50%" }} />
          <Typography sx={{ fontSize: "8px", color: "white" }}>Add DATA SOURCE</Typography>
        </Button>
        <Typography sx={{ fontSize: "12px", color: "#656464", mt: "25px" }}>
          This is a probabilistic summary of the legal entity drawn from the datasources. The likelihood of correctness is displayed by the following colour codes.
        </Typography>
        <Box sx={{ mt: "14px", display: "flex", flexDirection: "column", gap: "11px", width: "100%" }}>
          <Box sx={{ display: "flex", gap: "6px" }}>
            <Box sx={{ width: "16px", height: "16px", bgcolor: "white", border: "1px solid #656464", opacity: "0.3" }} />
            <Typography sx={{ fontSize: "12px", color: "#656464" }}>High</Typography>
          </Box>
          {/* <Box sx={{ display: "flex", gap: "6px" }}>
            <Box sx={{ width: "16px", height: "16px", bgcolor: "#007BFF", border: "1px solid #656464", opacity: "0.3" }} />
            <Typography sx={{ fontSize: "12px", color: "#656464" }}>Sub High</Typography>
          </Box> */}
          <Box sx={{ display: "flex", gap: "6px" }}>
            <Box sx={{ width: "16px", height: "16px", bgcolor: "#FFA800", border: "1px solid #656464", opacity: "0.3" }} />
            <Typography sx={{ fontSize: "12px", color: "#656464" }}>Medium</Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "6px" }}>
            <Box sx={{ width: "16px", height: "16px", bgcolor: "#CA1414", border: "1px solid #656464", opacity: "0.3" }} />
            <Typography sx={{ fontSize: "12px", color: "#656464" }}>Low</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
    // <Box sx={{ top:90, transform: 'translateZ(0px)', flexGrow: 1 }}>
    //   <StyledSpeedDial
    //     ariaLabel="DB Selector"
    //     sx={{ position: 'absolute', top: 50, right: 0 }}
    //     icon={<SpeedDialIcon />}
    //     onClose={handleClose}
    //     onOpen={handleOpen}
    //     onChange={e=>{console.log(e)}}
    //     open={true}
    //     direction={'down'}
    //   >
    //     {actions.map((action) => (
    //       <SpeedDialAction
    //         key={action.name}
    //         icon={action.icon}
    //         title={action.name}
    //         tooltipTitle={TabMark(action.name, action.name===view)}
    //         tooltipOpen
    //         onClick={e=>handleSelect(action.name)}
    //       />
    //     ))}
    //   </StyledSpeedDial>
    // </Box>
  );
}

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://australia-corp.com/co/lodge-it-pty-ltd/">
        Lodge IT PTY LIMITED
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


function InitPane(dispatch) {

  dispatch({
    entityID: "",
    entityName: [],
    anzicQuery: [],
    abn: "",
    acn: "",
    lei: "",
    curView: "ACCZIOM",
  })
}

const db_list = [
  { name: 'all', title: 'ALL DB' },
  { name: 'accziom', title: 'ACCZIOM DB' },
  { name: 'abr', title: 'ABR DB' },
  { name: 'ansic', title: 'ANSIC DB' },
  { name: 'asx', title: 'ASX DB' },
  { name: 'tpb', title: 'TPB DB' },
  { name: 'lei', title: 'LEI DB' },
  { name: 'xero', title: 'Xero DB' },
  { name: 'quickbook', title: 'Quickbook DB' },
  { name: 'ca', title: 'Chartered Accountant' },
  { name: 'pa', title: 'Public Accountant' },
]

export function MainSearch(props) {
  const [queryText, setQueryText] = useState('');
  const [query, setQuery] = useState('');
  const [candidates, setCandidates] = useState([]);
  const [isWorking, setWorking] = useState(false);
  const [openCand, setOpenCand] = useState(false);
  const [openContent, setOpenContent] = useState(false);
  const [state, dispatch] = useGlobalState();
  const [view, setView] = useState();
  const [height, setHeight] = useState(window.innerHeight - 200)
  const [dbType, setDbType] = useState(0);
  const [openList, setOpenList] = useState(false);

  const searchCandidate = async (query, setCandidates, setWorking) => {
    try {
      setCandidates([]);
      setWorking(true);
      axios.post(BSEARCH_URI + "search/", {
        query: query,
        db: db_list[dbType].name
      }).then(async response => {
        if (response && response.data) {
          var input = eval('(' + JSON.stringify(response.data) + ')');
          setCandidates(input);
          setWorking(false);
        }
      })
    } catch (err) {
      console.log(err);
    }
  };

  /*const handleClickSelectDB = (event) => {
    if (openContent && dbType > 0) {
      event.stopPropagation();
      setOpenContent(false);
      setOpenCand(false);
      setOpenList(true);
      return false;
    }
  }*/
  const handleChangeDbType = (e) => {
    setDbType(e.target.value)
    if (e.target.value > 0) {
      if (query === '') {
        setOpenContent(false);
        setOpenCand(false);
        setOpenList(true);
      } else {
        setOpenList(false);
        setOpenContent(false);
        setOpenCand(true);
      }
    } else {
      setOpenList(false);
    }
  }

  useEffect(() => {
    function handleResize() {
      setHeight(window.innerHeight - 200)
    }
    window.addEventListener('resize', handleResize)
  })
  const handleClose = (e) => {
    setOpenCand(false);
    setOpenList(false);
    setOpenContent(true);
  }
  const handleChange = (e) => {
    setQueryText(e.target.value)
  };

  const completeQuery = (e) => {
    var q = queryText.trim();
    if (q !== "") {
      let id = getNumber(q);
      if (id !== '') {
        if (id.length === 11 || id.length === 9) {
          dispatch({
            entityID: id,
          })
          setOpenCand(false);
          setOpenList(false);
          setOpenContent(true);
          return;
        }
      }
      setOpenContent(false);
      setOpenList(false);
      setOpenCand(true);
    }
    if (q !== query) {
      setQuery(q)
    }
  }

  const showDetails = (index) => {
    var cand = candidates[index];
    const [score, name, id, type] = cand;
    InitPane(dispatch);
    dispatch({
      entityName: [name],
      entityID: id,
      openDetails: false,
    })
    setOpenCand(false);
    setOpenList(false);
    setOpenContent(true);
  }

  const showDetailsFromName = (name) => {
    InitPane(dispatch);
    dispatch({
      entityName: [name],
      entityID: "",
    })
    setOpenCand(false);
    setOpenList(false);
    setOpenContent(true);
  }

  useEffect(() => {
    if (query !== '') {
      searchCandidate(query, setCandidates, setWorking)
      //$.ajax({type: "GET", url: "https://" + URL + ":8007/namesearch/" + query, success: function(result){
    }
  }, [query, dbType])

  useEffect(() => {
    setView(state.curView)
  }, [state.curView])


  return (
    <Container sx={{ mt: 4, mb: 4, display: props.show ? 'block' : 'none' }}>
      <Grid container>
        <Grid item xs={4} sm={3} md={2} lg={2} xl={1}>
          {
            openContent ?
              <SpeedDialTooltipOpen />
              :
              null
          }
        </Grid>
        <Grid item xs={8} sm={9} md={10} lg={10} xl={11}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={10}>
                  <Paper
                    component="form"
                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', borderRadius: 10 }}
                  >
                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={completeQuery}>
                      <SearchIcon />
                    </IconButton>
                    <InputBase
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="Search Entity Name, ABN, or ACN"
                      onChange={handleChange}
                      onClick={completeQuery}
                      onKeyPress={e => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                          completeQuery(e);
                        }
                      }}
                      value={queryText}
                    />
                    {isWorking ? <CircularProgress size={20} sx={{ mr: 2 }} /> : <div></div>}
                  </Paper>
                </Grid>
                <Grid item xs={2}>
                  <FormControl component={Paper} sx={{ height: 50, minWidth: '150px' }} >
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={dbType}
                      label="Database"
                      variant='standard'
                      onChange={handleChangeDbType}
                      //onMouseDownCapture={handleClickSelectDB}
                      sx={{ height: 50 }}
                    >
                      {
                        db_list.map((e, index) => (
                          <MenuItem key={index} value={index}>{e.title}</MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Paper sx={{ p: 2, display: openCand ? 'flex' : 'none', flexDirection: 'column' }}>
                <EntityCandidate data={candidates} onClose={handleClose} onDetails={showDetails} />
              </Paper>
              <Paper sx={{ p: 2, display: openList ? 'flex' : 'none', flexDirection: 'column' }}>
                <DBList db={db_list[dbType].name} onDetails={showDetailsFromName} />
              </Paper>
              <Paper sx={{
                minHeight: height, display: openContent ? 'flex' : 'none', flexDirection: 'column', boxShadow: "none !important", height: "100%"
              }}>

                {view === 'ACCZIOM' && state.openDetails === false && <RDFView />}
                {view === 'ACCZIOM' && state.openDetails === true && <RDFDetails onClose={e => { dispatch({ openDetails: false }) }} />}
                {view === 'ABR' && <ABNView />}
                {view === 'ASIC' && <ACNView />}
                {view === 'TPB' && <TPBView />}
                {view === 'ASX' && <ASXView />} 
                {view === 'LEI' && <LEIView />}
                {view === 'ASIC Paid' && <GBGView />}
                {view === 'Bing' && <BingView />}
                {view === 'MAP' && <MapView {...props} />}
                {view === 'ANZSIC' && <ANZSICView {...props} />}
                {view === 'XERO' && <XERO  />}
                {view === 'QuickBook' && <QuickBook  />}
                {view === 'CHARTERED-ACCOUNTANT' && <CA  />}
                {view === 'PUBLIC-ACCOUNTANT' && <PA  />}
              </Paper>

            </Grid>
          </Grid>
        </Grid>

      </Grid>
    </Container>
  )
}
