import { Box, Button, CircularProgress, TextField } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useGlobalState } from "../common/globalState";
import { BSEARCH_URI } from "../common/config";
import { useWeb3React } from "@web3-react/core";
import { NFTCombo } from "../profile/NFTView";
import { request, setGlobalVal } from "./common-tool";
import { personal_sign } from "../common/metamask";

export function MintNFTASICView(props) {
    const [state, dispatch] = useGlobalState()
    const [NFTName, setNFTName] = useState('');
    const [isMinting, setMinting] = useState(false);
    const context = useWeb3React()

    const handleMint = async () => {  
  
        try {
            var params = {}
    var reason = `BING Query=ASIC mint`;
    params.query = "ASIC mint";
    params.address = `${context.account}`;
    let fee = 50;
    if (fee > 0) {
        var seed = await new Promise(resolve => request(resolve, params.address, fee, reason));

        if (seed === '') {
            return;
        }
        else {
            let vk = await personal_sign(seed, context.account);
            params.verification = vk;
            params.seed = seed;
            if(vk){
            setMinting(true);
            let data = await props.getRDFString()
            if (data === "") {
              setMinting(false);
              return;
            }
            const res = (await axios.post(`${BSEARCH_URI}insert_rdf`, {
              record: data.record,
              address: `${context.account}`,
              id: data.id,
              timestamp: data.time,
              nftname: NFTName,
              seed: seed,
              verification: vk,
            })).data;
            if (res.error) {
              alert(res.error);
            } else {
              alert(`${res.count} records inserted to NFT #${res.id}`);
              dispatch({
                rdfHistory: {query:"", fee:0, result:{}}
              })
            }
        }
        }
    }     
          } catch (err) {
          console.log(JSON.stringify(err));
          alert('Failed to mint.');
        } finally {
          setMinting(false);
          if (props.afterMint) props.afterMint()
        }
    };

    return (
        <Box
          {...props.style}
          display="flex"
          mb={1}
          justifyContent="center"
        >
          <NFTCombo setNFTName={setNFTName} />
          <Button
              variant="contained"
              startIcon={isMinting ? <CircularProgress size={16} /> : null}
              disabled={isMinting || !props.uri || props.uri=='' || NFTName === "" 
                || !context.account 
              }
              sx={{ ml: 1, width:'200px' }}
              onClick={handleMint}
          >
              Mint NFT
          </Button>
        </Box>
    
    )
}