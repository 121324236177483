import { Avatar, Backdrop, Button, Card, CardContent, CardHeader, CircularProgress, Fab, FormControl, Grid, IconButton, InputBase, List, ListItem, ListItemText, MenuItem, Paper, Select, TextField, Typography } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import { Box } from "@mui/system";
import ComputerIcon from '@mui/icons-material/Computer';
import PersonIcon from '@mui/icons-material/Person';
import { blue, green, orange, red } from "@mui/material/colors";
import { sanitize } from "dompurify";
import { useGlobalState } from "../common/globalState";
import axios from "axios";
import { ANZIC_INFO_URI, ANZIC_WS_URL, BSEARCH_URI } from "../common/config";
import React, { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { useWeb3React } from "@web3-react/core";
import { MintNFTView } from './MintNFTView'

const START_MESSAGE = "Pleasa enter the business name or keywords."

const createWebSocket = (url) => {
  if (window.WebSocket) return new WebSocket(url);
  return null;
};

export function ANZSICView() {
  const [state, dispatch] = useGlobalState();
  const [isAnzicLoading, setIsAnzicLoading] = useState(state.anzicCode === null);
  const [anzicCode, setAnzicCode] = useState(state.anzicCode);
  const [anzicCodeByCode, setAnzicCodeByCode] = useState(state.anzicCodeByCode);
  const [division, setDivision] = useState('');
  const [subDivision, setSubDivision] = useState('');
  const [subDivisions, setSubDivisions] = useState([]);
  const [group, setGroup] = useState('');
  const [groups, setGroups] = useState([]);
  const [classification, setClassification] = useState('');
  const [classifications, setClassifications] = useState([]);
  const [webSocket, setWebSocket] = useState(null);
  const [isListening, setIsListening] = useState(false);
  const [anzicMessages, setAnzicMessages] = useState([]);
  const [messageText, setMessageText] = useState('');
  const [NFTName, setNFTName] = useState('');
  const [isMinting, setMinting] = useState(false);
  const lastElement = useRef();
  const [initializing, setInitializing] = useState(state.anzicCode !== null);
  const mintButton = useRef();
  const [focusObj, setFocusObj] = useState(0);
  const context = useWeb3React()
  const [data, setData] = useState(null);

  const connect_web_service = () => {
    if (data === null) return;
    let ws = createWebSocket(ANZIC_WS_URL);
    ws.onopen = () => {
      // setAnzicMessages([]);
      ws.send("restart");
      const anzicInfo = [];
      Object.keys(data).forEach((key) => {
        const val = data[key];
        const p = val.indexOf('(');
        const cod = val.slice(0, p);
        const desc = val.slice(p + 1, val.length - 1);
        const cats = desc.split('>');
        anzicInfo.push({
          code: cod,
          division: cats[0],
          subdivision: cats[1],
          group: cats[2],
          class: cats[3]
        });
      });
      if (anzicInfo.length > 0) {
        const inputStr = `%${JSON.stringify(anzicInfo)}`;
        ws.send(inputStr);
      }
    };
    ws.onmessage = (evt) => {
      console.log(evt)
      console.log(evt.data,"wss response")
      setAnzicMessages((prevMsgs) => [...prevMsgs, { id: `${prevMsgs.length}`, type: 1, text: evt.data }]);
      setIsListening(false);
    };
  
    ws.onclose = () =>{
      alert ('closed')
    }
    ws.onerror = (err) =>{
      console.log(err)
      alert('error closing')
    }
  
    setWebSocket(ws);
  }
  //const classes = useStyles();

  const getRDFString = async () => {
    var id = uuidv4();
    const { uri } = state.rdfHistory.result;
    const timeStr = (new Date()).toISOString();
    id = id + timeStr;
    const record = `
      <${uri}> azp:anzicCategory [
        azp:value anzic:C${classification} ;
        azpr:public "N" ;
        azpr:resource "${id}" ;
      ].
      <${uri}> azp:personalTimestamp [
        azp:value "${timeStr}" ;
        azpr:resource "${id}" ;
        azpr:public "Y" ;
      ].
    `;
    return {
      id, time: timeStr, record
    }
  };

  const refineComma = (txt) => txt.split(',').join(', ');
  const refineEnter = (txt) => txt.split('\n').join('<br>');
  const isDigit = (str) => {
    if (str[0] < '0' || str[0] > '9') return false;
    if (str[1] < '0' || str[1] > '9') return false;
    if (str[2] < '0' || str[2] > '9') return false;
    if (str[3] < '0' || str[3] > '9') return false;
    return true;
  };
  const isStopWord = (word1) => {
    const word = word1.toLowerCase();

    if (word === 'and') return false;
    if (word === 'in') return false;
    if (word === 'n') return false;
    if (word === 'e') return false;
    if (word === 'c') return false;
    if (word === 'as') return false;
    if (word === 'the') return false;
    if (word === 'generally') return false;
    if (word === 'this') return false;
    if (word === 'class') return false;
    if (word === 'of') return false;
    if (word === 'also') return false;
    if (word === 'for') return false;
    if (word === 'or') return false;
    if (word === 'except') return false;
    if (word === 'n') return false;
    if (word === 'e') return false;
    if (word === 'c') return false;
    if (word === 'not') return false;
    if (word === 'elsewhere') return false;
    if (word === 'include') return false;
    if (word === 'either') return false;
    if (word === 'which') return false;
    if (word === 'are') return false;
    if (word === 'is') return false;
    if (word === 'included') return false;
    if (word === 'from') return false;
    if (word === 'other') return false;
    if (word === 'to') return false;
    if (isDigit(word) === true) return false;

    return true;
  };
  const splitByWords1 = (desc1) => {
    const desc = desc1.substring(47);
    const ret = [];
    let word = '';
    for (let i = 0; i < desc.length; i++) {
      const c = desc[i];
      if (c === ' ' || c === ',' || c === '(' || c === ')' || c === '.' || c === '\r' || c === '\n' || c === '|' || c === '\\' || c === '/') {
        if (word.length > 0 && isStopWord(word) && ret.indexOf(word) === -1) ret.push(word);
        word = '';
      } else word = `${word}${c}`;
    }
    return JSON.stringify(ret);
  };
  const splitByWords = (desc) => {
    const ret = { words: '', primary_words: '', exclusion_words: '' };
    const descLower = desc.toLowerCase();
    const idx = descLower.indexOf('primary activities');
    if (idx !== -1) {
      let tmp = desc.substring(0, idx);
      ret.words = splitByWords1(tmp);
      const idx1 = descLower.indexOf('exclusions/references');
      if (idx1 !== -1) {
        tmp = desc.substring(idx + 19, idx1);
        ret.primary_words = splitByWords1(tmp);
        tmp = desc.substring(idx1 + 21);
        ret.exclusion_words = splitByWords1(tmp);
      } else {
        tmp = desc.substring(idx + 19);
        ret.primary_words = splitByWords1(tmp);
      }
    } else {
      ret.words = splitByWords1(desc);
    }
    return ret;
  };

  const getAnzicDetails = () => {
    const codMeta = anzicCodeByCode[classification];
    const div = codMeta.division;
    const subdiv = codMeta.subdivision;
    const grp = codMeta.group;
    const cls = codMeta.class;
    const desc = codMeta.description;

    const curGroup = anzicCode[div][subdiv][grp];
    let codeInfo = curGroup[0];
    for (let i = 0; i < curGroup.length; i++) {
      if (curGroup[i].cls_name === cls) {
        codeInfo = curGroup[i];
        break;
      }
    }

    const tmp = [div, subdiv, cls, desc].join(',');
    const wordList = splitByWords(tmp);
    const wordsDesc = refineComma(wordList.words);
    const wordsActivity = refineComma(wordList.primary_words);
    const wordsExclustion = refineComma(wordList.exclusion_words);
    const phrasesDesc = refineComma(codeInfo.cls_phrases);
    const phrasesActivity = refineComma(codeInfo.cls_phrases_primary);
    const phrasesExclusion = refineComma(codeInfo.cls_phrases_exclusion);

    const resList = [];
    let msg = refineEnter(desc);
    resList.push(msg);

    const msgPhrases = [];
    msgPhrases.push(`<b>Phrases</b>: ${phrasesDesc}`);
    msgPhrases.push(`<b>Phrases of Activity</b>: ${phrasesActivity}`);
    msgPhrases.push(`<b>Phrases of Exclusion</b>: ${phrasesExclusion}`);
    msg = msgPhrases.join('<br>');
    resList.push(msg);

    const msgWords = [];
    msgWords.push(`<b>Words</b>: ${wordsDesc}`);
    msgWords.push(`<b>Words of Activity</b>: ${wordsActivity}`);
    msgWords.push(`<b>Words of Exclusion</b>: ${wordsExclustion}`);
    msg = msgWords.join('<br>');
    resList.push(msg);

    return resList.join('<br><br>');
  };

  const handleClassifications = (strDivision, strSubDivision, strGroup) => {
    if (anzicCode && strDivision && strSubDivision && strGroup) {
      const modelData = anzicCode[strDivision][strSubDivision][strGroup].map((item) => ({ key: item.cls_name, code: item.cls_code }));
      setClassifications(modelData);
      setClassification(modelData[0].code);
    }
  };

  const handleGroups = (strDivision, strSubDivision) => {
    if (anzicCode && strDivision && strSubDivision) {
      const modelData = Object.keys(anzicCode[strDivision][strSubDivision]);
      setGroups(modelData);
      setGroup(modelData[0]);
      handleClassifications(strDivision, strSubDivision, modelData[0]);
    }
  };

  const handleSubDivisions = (strDivision) => {
    if (anzicCode && strDivision) {
      const modelData = Object.keys(anzicCode[strDivision]);
      setSubDivisions(modelData);
      setSubDivision(modelData[0]);
      handleGroups(strDivision, modelData[0]);
    }
  };

  const getCode = (strCode) => {
    const codeMeta = anzicCodeByCode[strCode];
    const div = codeMeta.division;
    const subdiv = codeMeta.subdivision;
    const grp = codeMeta.group;
    const cls = codeMeta.code;

    setDivision(div);
    const subDivData = Object.keys(anzicCode[div]);
    setSubDivisions(subDivData);
    setSubDivision(subdiv);
    const grpData = Object.keys(anzicCode[div][subdiv]);
    setGroups(grpData);
    setGroup(grp);
    const clsData = anzicCode[div][subdiv][grp].map((item) => ({ key: item.cls_name, code: item.cls_code }));
    setClassifications(clsData);
    setClassification(cls);
  };

  const clickableResult = (dat) => {
    if (dat === START_MESSAGE) return dat;
    const contMsg = dat.split('<br><br>');
    const cont = contMsg[0];
    const msg = contMsg[1];
    const splitStr = '<span style="cursor:pointer;" onclick="';
    const cands = cont.split(splitStr);
    if (cands.length === 1) {
      return (<span
        // eslint-disable-next-line
        dangerouslySetInnerHTML={{
          __html: dat
        }}
      />);
    }

    return (
      <>
        {cands.map((cand, idx) => (
          <Box
            // eslint-disable-next-line
            key={idx}
            onClick={() => {
              const info = (splitStr + cand).split('</span>');
              const code = info[0].substring(
                info[0].lastIndexOf('>', info[0].length - '</u></b>'.length) + 1,
                info[0].length - '</u></b>'.length
              );
              const strDivision = info[1].substring(info[1].lastIndexOf('>') + 1);
              const strSubDivision = info[2].substring(info[2].lastIndexOf('>') + 1);
              const strGroup = info[3].substring(info[3].lastIndexOf('>') + 1);
              // const strClassification = info[4].substring(info[4].lastIndexOf('>') + 1);
              setDivision(strDivision);
              handleSubDivisions(strDivision);
              setSubDivision(strSubDivision);
              handleGroups(strDivision, strSubDivision);
              setGroup(strGroup)
              handleClassifications(strDivision, strSubDivision, strGroup);
              setClassification(code);
              setFocusObj(2);
            }}
          >
            <span
              // eslint-disable-next-line
              dangerouslySetInnerHTML={{
                __html: sanitize(splitStr + cand)
              }}
            />
          </Box>
        ))}
        <br />
        <span
          // eslint-disable-next-line
          dangerouslySetInnerHTML={{
            __html: sanitize(msg)
          }}
        />
      </>
    );
  };

  const ComputerMessage = (props) => {
    return (
      <ListItem key={props.index} ref={props.obj ? props.obj : null} style={{ padding: '0 0 0 0' }}>
        <Card sx={{ maxWidth: '80%' }} style={{ boxShadow: '0px 0px 0px 0px', padding: '0 0 0 0' }}>
          <CardContent style={{ padding: '10 0 0 0' }}>
            <Box>
              <Avatar size="small" sx={{ bgcolor: green[500] }} aria-label="recipe">
                <ComputerIcon />
              </Avatar>
              <Box sx={{ mt: -5, ml: 6 }}>
                {clickableResult(props.text)}
              </Box>
            </Box>
          </CardContent>
        </Card>
      </ListItem>
    )
  }
  const HumanMessage = (props) => {
    //console.log(props);
    return (
      <ListItem key={props.index} sx={{ justifyContent: 'right' }} ref={props.obj ? props.obj : null} style={{ padding: '0 0 0 0' }}>
        <Card sx={{ maxWidth: '80%' }} style={{ boxShadow: '0px 0px 0px 0px', padding: '0 0 0 0' }}>
          <CardContent style={{ padding: '0 20 0 0' }}>
            <Grid container spacing={1} flexDirection='row-reverse'>
              <Grid item>
                <Avatar sx={{ bgcolor: orange[500] }} aria-label="recipe">
                  <PersonIcon />
                </Avatar>
              </Grid>
              <Grid item>
                <Typography variant="body2" color="text.secondary">
                  {props.text}
                </Typography>
              </Grid>

            </Grid>

          </CardContent>
        </Card>
      </ListItem>
    )
  }

  useEffect(() => {
    if (initializing) {
      const strDivision = Object.keys(anzicCode)[0];
      setDivision(strDivision);
      handleSubDivisions(strDivision);
      setInitializing(true);
    }
  }, [initializing]);

  useEffect(() => {
    if (data === null) return;
    if (webSocket) {
      if (webSocket.readyState === 0) {
        return;
      }
      webSocket.close();
    }
    connect_web_service();
  }, [webSocket, data]);

  useEffect(() => {
    let query = state.anzicQuery.join(';')
    if (query === "") {
      query = state.entityName.join(';');
    }
    if (state.anzicHistory.query === query) {
      return;
    } else {
      if (query === "") {
        dispatch({
          anzicHistory: { query: query, result: {} }
        })
        setData({});
      } else {
        setIsListening(true);
        axios.post(BSEARCH_URI + "anzic_query", {
          query: state.anzicQuery.length > 0 ? state.anzicQuery : state.entityName
        })
          .then(response => {
            setData(response.data)
          })
      }
    }
  }, [state.anzicQuery, state.entityName])

  useEffect(() => {
    if (anzicCode === null) {
      setIsAnzicLoading(true);
      axios.get(ANZIC_INFO_URI + "anzics")
        .then(response => {
          let res = response.data;
          setAnzicCode(res.ac);
          setAnzicCodeByCode(res.acc);
          setIsAnzicLoading(false);
          setInitializing(true);
          dispatch({
            anzicCode: res.ac,
            anzicCodeByCode: res.acc,
          })
        })
        .catch((err) => {
          console.log(err);
          setIsAnzicLoading(false);
        });
    }
    if (focusObj === 1 && lastElement && lastElement.current) {
      lastElement.current.scrollIntoView(false);
    }
    if (focusObj === 2 && mintButton && mintButton.current) {
      mintButton.current.scrollIntoView(false);
    }
  });

  const SendMessage = () => {
    
    setAnzicMessages((prevMsgs) => [...prevMsgs, { id: `${prevMsgs.length}`, type: 2, text: messageText }]);
    webSocket.send(messageText);
    setFocusObj(1);
    setMessageText("");
  }

  if (isListening) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <CircularProgress size={50} />
      </Box>
    )
  } else {
    return (
      <Grid container spacing={2} >
        <Grid item xs={12}>
          <Typography variant='h5'>
            ANZSIC Classifier
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography sx={{ pl: 4, pr: 2, mb: 2, textAlign: 'left' }}>
            This pane displays the ANZIC classification of legal entity. It is classified by using ACCZIOM AI technique according to businesses or description of legal entity. You can insert new keyword and select the correct classification.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2} component={Paper}>
            <Grid item xs={12}>
              <Paper
                component="form"
                sx={{ ml: 2, mr: 3, display: 'flex', alignItems: 'center', borderRadius: 4, border: '1px solid #2e7d32a6' }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Please enter Keywords"
                  onKeyPress={e => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      SendMessage(e);
                    }
                  }}
                  value={messageText}
                  onChange={e => {
                    setMessageText(e.target.value)
                    setFocusObj(0)
                  }}
                />
                <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={SendMessage}>
                  <SendIcon />
                </IconButton>

              </Paper>


            </Grid>
            <Grid item xs={12}>
              <Box style={{ border: '1px solid #2e7d32a6', padding: '0 0 0 0' }} sx={{ ml: 2, mr: 3, mb: 3, height: '400px', overflowY: 'scroll' }}>
                <List sx={{ height: '400px', width: '100%' }} >
                  {
                    anzicMessages.map((e, index) => {
                      if (e.type === 1) return (<ComputerMessage key={index} index={index} text={e.text} obj={index === anzicMessages.length - 1 ? lastElement : null} />)
                      return (<HumanMessage key={index} index={index} text={e.text} obj={index === anzicMessages.length - 1 ? lastElement : null} />)
                    })
                  }
                </List>
                <Backdrop
                  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  open={isListening}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              </Box>


            </Grid>

          </Grid>
        </Grid>
        <Grid item xs={12}>
          {
            anzicCode && (
              <Grid container
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  pl: 4,
                  pr: 4,
                  pb: 4,
                  pt: 2
                }}
                component={Paper}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    mt: 2
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      width: '20%'
                    }}
                  >
                    <Typography
                      variant="body1"
                      color="textPrimary"
                    >
                      Division
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      width: '50%'
                    }}
                  >
                    <FormControl
                      sx={{
                        width: '100%'
                      }}
                      size="small"
                    >
                      <Select
                        value={division}
                        onChange={(event) => {
                          const { value } = event.target;
                          setDivision(value);
                          handleSubDivisions(value);
                          setFocusObj(0)
                        }}
                      >
                        {
                          Object.keys(anzicCode).map((key) => (
                            <MenuItem
                              value={key}
                              key={key}
                            >
                              <Typography
                                color="textPrimary"
                                variant="body2"
                              >
                                {key}
                              </Typography>
                            </MenuItem>
                          ))
                        }
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    mt: 2
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      width: '20%'
                    }}
                  >
                    <Typography
                      variant="body1"
                      color="textPrimary"
                    >
                      SubDivision
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      width: '50%'
                    }}
                  >
                    <FormControl
                      sx={{
                        width: '100%'
                      }}
                      size="small"
                    >
                      <Select
                        value={subDivision}
                        onChange={(event) => {
                          const { value } = event.target;
                          setSubDivision(value);
                          handleGroups(division, value);
                          setFocusObj(0)
                        }}
                      >
                        {
                          subDivisions.map((item) => (
                            <MenuItem
                              value={item}
                              key={item}
                            >
                              <Typography
                                color="textPrimary"
                                variant="body2"
                              >
                                {item}
                              </Typography>
                            </MenuItem>
                          ))
                        }
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    mt: 2
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      width: '20%'
                    }}
                  >
                    <Typography
                      variant="body1"
                      color="textPrimary"
                    >
                      Group
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      width: '50%'
                    }}
                  >
                    <FormControl
                      sx={{
                        width: '100%'
                      }}
                      size="small"
                    >
                      <Select
                        value={group}
                        onChange={(event) => {
                          const { value } = event.target;
                          setGroup(value);
                          handleClassifications(division, subDivision, value);
                          setFocusObj(0)
                        }}
                      >
                        {
                          groups.map((item) => (
                            <MenuItem
                              value={item}
                              key={item}
                            >
                              <Typography
                                color="textPrimary"
                                variant="body2"
                              >
                                {item}
                              </Typography>
                            </MenuItem>
                          ))
                        }
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    mt: 2
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      width: '20%'
                    }}
                  >
                    <Typography
                      variant="body1"
                      color="textPrimary"
                    >
                      Class
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      width: '50%'
                    }}
                  >
                    <FormControl
                      sx={{
                        width: '100%'
                      }}
                      size="small"
                    >
                      <Select
                        value={classification}
                        onChange={(event) => {
                          const { value } = event.target;
                          setClassification(value);
                          setFocusObj(0)
                        }}
                      >
                        {
                          classifications.map((item) => {
                            const { key, code } = item;
                            return (
                              <MenuItem
                                value={code}
                                key={code}
                              >
                                <Typography
                                  color="textPrimary"
                                  variant="body2"
                                >
                                  {key}
                                </Typography>
                              </MenuItem>
                            );
                          })
                        }
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    mt: 2
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      width: '20%',
                      mb: 2
                    }}
                  >
                    <Typography
                      variant="body1"
                      color="textPrimary"
                    >
                      Code
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      width: '50%',
                    }}
                  >
                    <Typography
                      variant="body2"
                      color="textPrimary"

                    >
                      {classification}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    mt: 2,
                  }}
                >
                  <Box sx={{ ml: 10, mr: 10, mb: 2 }}>
                    <MintNFTView uri={state.rdfHistory.result.uri} getRDFString={getRDFString} />
                  </Box>
                  {
                    anzicCodeByCode[classification] === undefined ? (
                      <Typography
                        variant="body2"
                        color="textSecondary"

                      >
                        {`Not founded the information of ${classification}.`}
                      </Typography>
                    ) : (
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          textAlign: 'left'
                        }}
                      >
                        <Typography
                          variant="body1"
                          color="textPrimary"
                          sx={{
                            fontWeight: 'bold'
                          }}
                          ref={mintButton}
                        >
                          Description:
                        </Typography>
                        <Box
                          sx={{
                            mt: 1,
                            ml: 2,
                            color: 'text.secondary'
                          }}
                        >
                          <span
                            // eslint-disable-next-line
                            dangerouslySetInnerHTML={{
                              __html: sanitize(getAnzicDetails())
                            }}
                          />
                        </Box>
                      </Box>
                    )
                  }
                </Box>
              </Grid>
            )
          }
        </Grid>

      </Grid>
    );
  }

}
