export const downloadJson = async (result) => {


    try {
        const result1 = JSON.parse(JSON.stringify(result)); // Create a deep copy of the object
       Object.keys(result1).forEach(key => {
            result1[key].forEach(item => 
                delete item.action 
            );
        });
        const jsonLDData = JSON.stringify(result1, null, 2);
        const blob = new Blob([jsonLDData], { type: 'application/ld+json' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'response.jsonld';
        link.click();
        console.log('Download initiated!');
    } catch (error) {
        alert('Error download file. Please try again later')
        console.error('Error:', error);
    }
}


export const waasAccount = "0x6694A84790cAC71b23E3Ed3AA81246792Aa59bc8"