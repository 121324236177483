import React from 'react';
import './App.css';
import { GlobalStateProvider } from './common/globalState';
import BSearchMain from './BSearchMain';
import Help from './routes/help';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';

function Home() {
  return (
    <div>
      <Box>
        <Typography variant='h3' textAlign='center' sx={{mt:5}}>
          Accziom Playgroud
        </Typography>
        
      </Box>

      <Help/>
    </div>
  )
}

export default Home;
