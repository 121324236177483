import React, { useState, useEffect } from "react";
import { useGlobalState } from "../common/globalState";
import {
  Button,
  InputAdornment,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import PersonIcon from "@mui/icons-material/Person";

import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogActions,
  DialogContent,
} from "@mui/material";

import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { token_server_url } from "./config";
import { Web3Provider } from "@ethersproject/providers";
import {
  estimate_gas_fee,
  secure_query,
  secure_transaction,
} from "./comm_token";

import alertIcon from "../icons/alert.svg";

const unit_style = {
  "& .MuiTypography-root": {
    color: "white", // Text color
    fontSize: "10px",
  },
  container: {
    alignItems: "baseline",
  },
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const columns = [
  {
    field: "time",
    headerName: "Time",
    type: "date",
    width: 200,
    editable: false,
  },
  {
    field: "event",
    headerName: "Transaction",
    width: 120,
    editable: false,
  },
  {
    field: "ledger",
    headerName: "Ledger",
    width: 120,
    editable: false,
  },
  {
    field: "dr",
    headerName: "Dr",
    width: 80,
    editable: false,
  },
  {
    field: "cr",
    headerName: "Cr",
    width: 80,
    editable: false,
  },
  {
    field: "balance",
    headerName: "Balance",
    width: 120,
    editable: false,
  },
  {
    field: "detail",
    headerName: "Description",
    width: 500,
    editable: false,
    hideable: false,
  },
];

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiPaper-root": {
    width: "100%",
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const button_style = {
  color: "floralwhite",
  borderColor: "floralwhite",
  borderRadius: 15,
  fontSize: "12px",
  width: "200px",
  height: "40px",
};

function ledger_to_string(type) {
  switch (type) {
    case 1:
      return "Layer1";
    case 2:
      return "Layer2";
    case 3:
      return "Satoshi";
    default:
      return "";
  }
}

function balance_from_ledger(bal, led) {
  switch (led) {
    case 1:
      return bal.layer1;
    case 2:
      return bal.layer2;
    case 3:
      return "";
    default:
      return "";
  }
}

function TransactionDetailContent(props) {
  const [value, setValue] = useState(0);
  const [state, dispatch] = useGlobalState();
  const [gasLimit, setGasLimit] = useState(1);

  const dataGridStyle = {
    borderRadius: "16px",
    color: "white",
    backgroundColor: "rgba(255, 255, 255, 0.06)",
    border: "1px solid rgba(255, 255, 255, 0.12)",
    ".MuiTablePagination-displayedRows": { color: "white" },
    ".Mui-disabled": { color: "rgba(255, 255, 255, 0.12)" },
    ".Mui-enabled": { color: "white" },
    ".MuiButtonBase-root": { color: "white" },
    ".MuiDataGrid-columnHeaders": {
      borderBottom: "1px solid rgba(255, 255, 255, 0.12)",
    },
    ".MuiDataGrid-footerContainer": {
      borderTop: "1px solid rgba(255, 255, 255, 0.12)",
    },
    ".MuiDataGrid-cell": {
      borderBottom: "none",
    },
  };

  const final_mint = async (record) => {
    var provider = new Web3Provider(window.ethereum);
    if (record.ledger === 1) {
      await state.mrc20_contract
        .connect(provider.getSigner())
        .finalMint(record.amount, record.btc_tx, record.approve_key);
    } else if (record.ledger === 2) {
      await state.mrc20_contract
        .connect(provider.getSigner())
        .finalDepositFromOther(
          record.amount,
          record.btc_tx,
          record.approve_key
        );
    }
  };

  const final_withdraw = async (record) => {
    var provider = new Web3Provider(window.ethereum);
    await state.mrc20_contract
      .connect(provider.getSigner())
      .finalWithdrawToMERC(record.amount, record.mrc2_tx, record.approve_key);
  };

  const final_burn = async (record) => {
    var provider = new Web3Provider(window.ethereum);
    if (record.ledger === 1) {
      await state.mrc20_contract
        .connect(provider.getSigner())
        .finalBurn(record.amount, record.mrc_tx, record.approve_key);
    } else {
      console.log(record.amount);
      console.log(record.mrc2_tx);
      console.log(record.approve_key);
      await state.mrc20_contract
        .connect(provider.getSigner())
        .finalWithdrawToOther(
          record.amount,
          record.mrc2_tx,
          record.approve_key
        );
    }
  };

  const continue_mint = async (record) => {
    let type = record.ledger === 1 ? "mint" : "depositFromOther";
    let msg = {
      address: record.address,
      type: type,
      btc_tx: record.btc_tx,
      gasLimit: gasLimit,
    };
    let res = await secure_query(msg);
    if (res.status != true) {
      if (res.error) {
        alert(res.error);
      } else {
        if (record.amount < gasLimit) {
          alert("The transaction amount is smaller than required gas fee.");
        } else {
          alert(
            "The requried gas fee exceeded the given gas limit. Please increase the gas limit."
          );
        }
      }
      return;
    }
  };

  const continue_withdraw = async (record) => {
    let msg = {
      address: record.address,
      type: "withdraw",
      mrc2_tx: record.mrc2_tx,
      gasLimit: gasLimit,
    };
    let res = await secure_query(msg);
    if (res.status != true) {
      if (res.error) {
        alert(res.error);
      } else {
        if (record.amount < gasLimit) {
          alert("The transaction amount is smaller than required gas fee.");
        } else {
          alert(
            "The requried gas fee exceeded the given gas limit. Please increase the gas limit."
          );
        }
      }
      return;
    }
  };

  const continue_burn = async (record) => {
    let type = record.ledger === 1 ? "burn" : "withdrawToOther";
    let msg = {
      address: record.address,
      type: type,
      mrc_tx: record.mrc_tx,
      mrc2_tx: record.mrc2_tx,
      gasLimit: gasLimit,
    };
    let res = await secure_query(msg);
    if (res.status != true) {
      if (res.error) {
        alert(res.error);
      } else {
        if (record.amount < gasLimit) {
          alert("The transaction amount is smaller than required gas fee.");
        } else {
          alert(
            "The requried gas fee exceeded the given gas limit. Please increase the gas limit."
          );
        }
      }
      return;
    }
  };

  const continue_send = async (record) => {
    let msg = {
      address: record.address,
      type: "sendBTC",
      mrc_tx: record.mrc_tx,
      gasLimit: gasLimit,
    };
    let res = await secure_query(msg);
    if (res.status != true) {
      if (res.error) {
        alert(res.error);
      } else {
        if (record.amount < gasLimit) {
          alert("The transaction amount is smaller than required gas fee.");
        } else {
          alert(
            "The requried gas fee exceeded the given gas limit. Please increase the gas limit."
          );
        }
      }
      return;
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const estimate = async (record) => {
    let gas = await estimate_gas_fee(record);
    setGasLimit(gas);
  };

  const runByUser = (record) => {
    switch (record.type) {
      case "minting":
        final_mint(record);
        break;
      case "withdrawing":
        final_withdraw(record);
        break;
      case "burning":
        final_burn(record);
        break;
      case "sending":
        continue_send(record);
        break;
      default:
        break;
    }
  };

  const runByBot = (record) => {
    switch (record.type) {
      case "minting":
        continue_mint(record);
        break;
      case "withdrawing":
        continue_withdraw(record);
        break;
      case "burning":
        continue_burn(record);
        break;
      case "sending":
        continue_send(record);
        break;
      default:
        break;
    }
  };

  const renderButton = (params) => {
    return (
      <Stack direction="row" spacing={1}>
        {params.row.type != "sending " && (
          <IconButton
            aria-label="delete"
            onClick={() => {
              runByUser(params.row);
            }}
          >
            <PersonIcon />
          </IconButton>
        )}
        <IconButton
          aria-label="delete"
          onClick={() => {
            runByBot(params.row);
          }}
        >
          <SupportAgentIcon />
        </IconButton>
      </Stack>
    );
  };

  const renderNoRows = () => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          flexDirection: "column",
        }}
      >
        <img src={alertIcon} alt="fireSpot" width="80px" />
        <p color="white">No Transactions</p>
      </div>
    );
  };

  const pending_columns = [
    {
      field: "timestamp",
      headerName: "Time",
      type: "date",
      width: 200,
      editable: false,
    },
    {
      field: "type",
      headerName: "Transaction",
      width: 120,
      editable: false,
    },
    {
      field: "ledger_str",
      headerName: "Ledger",
      width: 100,
      editable: false,
    },
    {
      field: "dr",
      headerName: "Dr",
      width: 80,
      editable: false,
    },
    {
      field: "cr",
      headerName: "Cr",
      width: 80,
      editable: false,
    },
    {
      field: "actions",
      headerName: "Run",
      width: 90,
      renderCell: renderButton,
      disableClickEventBubbling: true,
    },
  ];

  let flexContainerVertical = {
    display: "flex",
    alignItems: "center",
  };

  return (
    <Box
      sx={{
        height: 450,
        width: 730,
        marginTop: "40px",
        backgroundColor: "rgba(61, 61, 61, 0.28)",
        border: "1px solid rgba(255, 255, 255, 0.12)",
        borderRadius: "20px",
        position: "relative",
      }}
    >
      <Typography
        fontSize="20px"
        color="white"
        sx={{ position: "absolute", left: "16px", top: "10px" }}
      >
        Transactions
      </Typography>
      <Tabs
        variant="scrollable"
        value={value}
        onChange={handleChange}
        sx={{
          borderRight: 1,
          borderColor: "divider",
          ".MuiButtonBase-root": { textTransform: "none" },
          ".Mui-selected": { color: "white !important" },
          ".MuiTab-textColorPrimary": { color: "white" },
          ".MuiTabs-indicator": { backgroundColor: "white !important" },
          ".MuiTabs-flexContainer": { float: "right" },
        }}
      >
        <Tab label="Completed" {...a11yProps(0)} sx={{}} />
        <Tab label="Pending" {...a11yProps(1)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <Box sx={{ height: 350 }}>
          <DataGrid
            rows={props.completed}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            getRowId={(row) => row.time}
            initialState={{
              sorting: {
                sortModel: [{ field: "time", sort: "asc" }],
              },
            }}
            sx={dataGridStyle}
            components={{
              NoRowsOverlay: () => {
                return renderNoRows();
              },
            }}
            disableSelectionOnClick
          />
        </Box>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Stack
          direction="column"
          spacing={2}
          sx={{ height: 350, display: "flex" }}
        >
          <DataGrid
            rows={props.pending}
            columns={pending_columns}
            pageSize={4}
            rowsPerPageOptions={[4]}
            getRowId={(row) => row.timestamp}
            initialState={{
              sorting: {
                sortModel: [{ field: "timestamp", sort: "asc" }],
              },
            }}
            disableSelectionOnClick
            onRowClick={(params, event, details) => {
              estimate(params.row);
            }}
            sx={dataGridStyle}
            components={{
              NoRowsOverlay: () => {
                return renderNoRows();
              },
            }}
          />
          <TextField
            fullWidth
            label="Gas Limit"
            value={gasLimit}
            onChange={(event) => {
              setGasLimit(event.target.value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment sx={unit_style} position="end">
                  MERc
                </InputAdornment>
              ),
            }}
            sx={{
              ".MuiInputBase-root": {
                borderRadius: "16px",
                background: "rgba(255, 255, 255, 0.06)",
                color: "white",
              },
              ".MuiFormLabel-root": {
                color: "white",
              },
              ".MuiOutlinedInput-notchedOutline": {
                border: "1px solid rgba(255, 255, 255, 0.12)",
              },

              "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#535353",
                },
              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "white",
                },
            }}
          />
        </Stack>
      </TabPanel>
    </Box>
  );
}

async function get_pending_records(address) {
  let response = await axios.get(token_server_url + "/get_pending_record", {
    params: {
      address: address,
    },
  });
  let res = response.data;
  if (res && res.status === true) {
    console.log(res);
    return res.data;
  } else {
    return [];
  }
}

export function TransactionCard() {
  const [rows, setHistory] = useState([]);
  const [pending_rows, setPendingHistory] = useState([]);
  const [state, dispatch] = useGlobalState();

  useEffect(() => {
    const fetchPendingRecords = async () => {
      let pending_records = await get_pending_records(state.mrcAddress);
      console.log(pending_records);
      pending_records.forEach((e) => {
        if (e.type === "minting") {
          e.dr = e.amount;
          if (!e.ledger) {
            e.ledger = 2;
          }
        } else if (e.type === "sending") {
          e.ledger = 3;
          e.dr = e.amount;
        } else if (e.type === "burning") {
          e.cr = e.amount;
          if (e.ledger === 2) {
            e.ledger = 3;
            e.cr = "";
            e.dr = e.amount;
          }
        } else if (e.type === "withdrawing") {
          e.dr = e.amount;
          e.ledger = 1;
        }
        e.ledger_str = ledger_to_string(e.ledger);
      });
      setPendingHistory(pending_records);
    };

    var hist = state.transactionHistory.map((e) => ({
      time: e.time,
      event: e.event,
      ledger: ledger_to_string(e.ledger),
      dr:
        e.event !== "balance" && parseFloat(e.fee) > 0
          ? e.fee.toLocaleString("en-US")
          : "",
      cr:
        e.event !== "balance" && parseFloat(e.fee) < 0
          ? (-parseFloat(e.fee)).toLocaleString("en-US")
          : "",
      balance: balance_from_ledger(e.balance, e.ledger).toLocaleString("en-US"),
      detail: e.detail,
    }));
    setHistory(hist);

    fetchPendingRecords();
  }, []);

  return (
    <div>
      <TransactionDetailContent completed={rows} pending={pending_rows} />
    </div>
  );
}

export function TransactionButton() {
  const [open, setOpen] = useState(false);
  const [rows, setHistory] = useState([]);
  const [state, dispatch] = useGlobalState();
  const [pending_rows, setPendingHistory] = useState([]);

  const handleClickOpen = async () => {
    var hist = state.transactionHistory.map((e) => ({
      time: e.time,
      event: e.event,
      ledger: ledger_to_string(e.ledger),
      dr:
        e.event !== "balance" && parseFloat(e.fee) > 0
          ? e.fee.toLocaleString("en-US")
          : "",
      cr:
        e.event !== "balance" && parseFloat(e.fee) < 0
          ? (-parseFloat(e.fee)).toLocaleString("en-US")
          : "",
      balance: balance_from_ledger(e.balance, e.ledger).toLocaleString("en-US"),
      detail: e.detail,
    }));
    setHistory(hist);

    let pending_records = await get_pending_records(state.mrcAddress);
    console.log(pending_records);
    pending_records.forEach((e) => {
      if (e.type === "minting") {
        e.dr = e.amount;
        if (!e.ledger) {
          e.ledger = 2;
        }
      } else if (e.type === "sending") {
        e.ledger = 3;
        e.dr = e.amount;
      } else if (e.type === "burning") {
        e.cr = e.amount;
        if (e.ledger === 2) {
          e.ledger = 3;
          e.cr = "";
          e.dr = e.amount;
        }
      } else if (e.type === "withdrawing") {
        e.dr = e.amount;
        e.ledger = 1;
      }
      e.ledger_str = ledger_to_string(e.ledger);
    });
    setPendingHistory(pending_records);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" style={button_style} onClick={handleClickOpen}>
        Transactions
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth={"lg"}
        width="80%"
        height="500px"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Transaction History
        </BootstrapDialogTitle>

        <DialogContent dividers>
          <TransactionDetailContent completed={rows} pending={pending_rows} />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
