import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useGlobalState } from "../common/globalState";
import { BTCAccountTextField, Layer2Check } from "./hooks";
import { AmountTextField } from "./amountText";
import { estimate_gas_fee, get_btc_reserve_address } from "./comm_token";
import axios from "axios";
import { token_server_url } from "./config";
import { QRCodeCanvas } from "qrcode.react";
import button_style from "./style/button_style";
import dialog_button_style from "./style/dialog_button_style";
import { GasLimitOption } from "./components/GasLimitOption";
import { useWeb3React } from "@web3-react/core";
const {
  BootstrapDialog,
  BootstrapDialogTitle,
} = require("./components/bootstrap_dialog");

export function BuyMERCButton() {
  const context = useWeb3React();
  const [open, setOpen] = useState(false);
  const [view, setView] = useState(false);
  const [gasLimit, setGasLimit] = useState(1);
  const [state, dispatch] = useGlobalState();
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    if (state.inputAmountError) {
      setBtnDisabled(true);
      return;
    }
    setBtnDisabled(false);
  }, [state.inputAmountError]);

  const handleClickBuy = async () => {
    if (parseInt(state.amount) <= parseInt(gasLimit)) {
      setError("Amount is less than gas limit.");
      return;
    } else {
      setError("");
    }
    const desc = "Buying MERc";
    const btc = parseFloat(state.amount / 1e8);
    console.log(btc);
    if (btc > 0 && state.btcAddress !== "") {
      var btc_reserve_address = await get_btc_reserve_address(state.amount);
      if (btc_reserve_address === "") {
        alert("No BTC reserve account ready.");
        return;
      }
      var msg = `bitcoin:${btc_reserve_address}?amount=${btc}&message=${desc}`;
      dispatch({
        send_msg: msg,
        btc: btc,
      });
      axios.get(token_server_url + "/set_exchange_info", {
        params: {
          btc_address: state.btcAddress,
          mrc_address: state.mrcAddress,
          layer2: state.layer2,
          gasLimit: gasLimit,
        },
      });
      setView(true);
    }
  };

  const estimateGasFee = async (event) => {
    let res = await estimate_gas_fee({
      type: "minting",
      address: context.account,
      ledger: state.layer2 ? 2 : 1,
      amount: state.amount,
      btc_tx: "TEST_ESTIMATE",
    });
    setGasLimit(res);
  };

  const handleClickOpen = () => {
    dispatch({
      send_msg: "",
      amount: "",
      layer2: true,
      inputAmountError: true,
    });
    setView(false);
    setGasLimit(1);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {error !== "" ? (
        <Alert severity="error" sx={{ marginBottom: "10px" }}>
          <AlertTitle>Error</AlertTitle>
          {error}
        </Alert>
      ) : (
        <div></div>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack
            direction="column"
            spacing={{ xs: 1, sm: 1, md: 3 }}
            justifyContent="center"
            alignItems="center"
          >
            <div>Please enter Bitcoin address and amount.</div>
            <div>
              <BTCAccountTextField label="From" />
            </div>
            <div>
              <AmountTextField min="1000" />
            </div>
            <div>
              <Layer2Check label="Deposit to Layer2" />
            </div>
            <div>
              <GasLimitOption
                defaultValue={gasLimit}
                setValue={setGasLimit}
                min={1}
                estimate={estimateGasFee}
              />
            </div>

            <Button
              disabled={btnDisabled}
              variant="outlined"
              style={
                btnDisabled
                  ? dialog_button_style.disabledButton
                  : dialog_button_style.enabledButton
              }
              onClick={handleClickBuy}
            >
              Buy
            </Button>
          </Stack>
        </Grid>
        {view ? (
          <Grid item xs={12}>
            <Stack
              direction="column"
              spacing={{ xs: 1, sm: 2, md: 3 }}
              justifyContent="center"
              alignItems="center"
            >
              <div>
                Please scan below QR code and send <span>{state.btc}</span>{" "}
                BTCs.
              </div>
              <QRCodeCanvas
                value={state.send_msg}
                size={200}
                fgColor="#000000"
                open={view}
              />
            </Stack>
            <TextField
              id="pay-command"
              label="Bitcoin Transaction Code"
              variant="outlined"
              value={state.send_msg}
              sx={{
                mt: 2,
                width: "100%",
                ".MuiFormLabel-root": {
                  color: "rgba(255, 255, 255, 0.5)",
                },
                ".MuiInputBase-input": {
                  color: "white",
                },
                ".MuiOutlinedInput-notchedOutline": {
                  color: "white",
                  borderColor: "white !important",
                },
                "& input:valid + fieldset": {
                  borderColor: "rgba(255, 255, 255, 0.12)",
                  borderWidth: 1,
                },
                "& input:invalid + fieldset": {
                  borderColor: "rgba(255, 255, 255, 0.12)",
                  borderWidth: 1,
                },
                "& input:valid:focus + fieldset": {
                  borderLeftWidth: 6,
                  padding: "4px !important",
                },
              }}
            />
          </Grid>
        ) : null}
      </Grid>
    </div>
  );
}
