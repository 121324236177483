import { InjectedConnector } from "@web3-react/injected-connector";
export const injected = new InjectedConnector({
    supportedChainIds: [5, 11155111]
})

export async function personal_sign(msg, address) {
    try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });

        const ethResult = await window.ethereum.request({
            method: 'personal_sign',
            params: [msg, accounts[0]],
        });
        return ethResult;    
    }
    catch(err) {
        console.log(err);
        return '';
    }
}
