import React from 'react';
import { Button, Card, CardActionArea, CardActions, CardContent, CardHeader, CardMedia, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";
import ShortcutIcon from '@mui/icons-material/Shortcut';
import { YouTube } from "@mui/icons-material";

export default function Help(props) {
  return (
    <Box sx={{display: "flex"}}>
      <Grid container sx={{mt:1}} spacing={4}>
        <Grid item xs={12} sx={{ textAlign: "-webkit-center"}}>
          <Card sx={{ maxWidth: 600}}>
            <CardActionArea>
            <CardHeader title="BSearch"/>
            <CardMedia
              component="iframe"
              image="https://www.youtube.com/embed/videoseries?list=PLQCAXRFn5aoblurzSrXX7Ezw-IvLihySv"
              sx={{
                width: "560px",
                height: "315px"
              }}
              allowfullscreen="allowfullscreen"
              
            />
            <CardContent>
              <Typography variant="body2" color="text.secondary">
                You can search and browse the information of a legal entity.
              </Typography>
            </CardContent>
            <CardActions sx={{justifyContent: 'center'}}>
              <Button href="bsearch" variant="outlined" startIcon={<ShortcutIcon/>}>Go</Button>
            </CardActions>
            </CardActionArea>
          </Card>
        </Grid>
        {/* <Grid item xs={12} sx={{ textAlign: "-webkit-center"}}>
          <Card sx={{maxWidth: 600}}>
            <CardActionArea>
            <CardHeader title="Tax Genii"/>
            <CardMedia
              component="iframe"
              image="https://www.youtube.com/embed/2Pvsc3OOV5I"
              sx={{
                width: "560px",
                height: "315px"
              }}
              allowfullscreen="allowfullscreen"
              
            />
            <CardContent>
              <Typography variant="body2" color="text.secondary">
                You can chat with our chatbot, Tax Genii about the tax related topic. Tax Genii is a powerful and reliable assistant.
              </Typography>
            </CardContent>
            <CardActions sx={{justifyContent: 'center'}}>
              <Button href="tax-genii" variant="outlined" startIcon={<ShortcutIcon/>}>Go</Button>
            </CardActions>
            </CardActionArea>
            
          </Card>
        </Grid>
        <Grid item xs={12} sx={{ textAlign: "-webkit-center"}}>
          <Card sx={{maxWidth: 600}}>
            <CardActionArea>
            <CardHeader title="ATO Legal Database Search"/>
            <CardMedia
              component="iframe"
              image="https://www.youtube.com/embed/eNSDwzNg8-s"
              sx={{
                width: "560px",
                height: "315px"
              }}
              allowfullscreen="allowfullscreen"
              
            />
            <CardContent>
              <Typography variant="body2" color="text.secondary">
                You can search the link to data what you want in ATO Legislation database.
              </Typography>
            </CardContent>
            <CardActions sx={{justifyContent: 'center'}}>
              <Button href="legal-database" variant="outlined" startIcon={<ShortcutIcon/>}>Go</Button>
            </CardActions>
            </CardActionArea>
            
          </Card>
        </Grid> */}
        <Grid item xs={12} sx={{ textAlign: "-webkit-center"}}>
          <Card sx={{maxWidth: 600}}>
            <CardActionArea>
            <CardHeader title="Entity Recognition"/>
            <CardMedia
              component="iframe"
              image="https://www.youtube.com/embed/D981t8-iXu4"
              sx={{
                width: "560px",
                height: "315px"
              }}
              allowfullscreen="allowfullscreen"
              
            />
            <CardContent>
              <Typography variant="body2" color="text.secondary">
                You can play the entity recognition game. It is very simple. Once you input the text, you can get all the entity and its relationships.
              </Typography>
            </CardContent>
            <CardActions sx={{justifyContent: 'center'}}>
              <Button href="relation-recognition" variant="outlined" startIcon={<ShortcutIcon/>}>Go</Button>
            </CardActions>
            </CardActionArea>
            
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}