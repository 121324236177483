import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, IconButton, InputBase, MenuItem, Paper, Select, TextField, Typography } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { BSEARCH_URI, PROFILE_SERVER_URL } from "../common/config";
import { ComboBox } from "../utils/combobox";
import { MintNFTView } from "./MintNFTView";
import { URISearch } from "./URISearch";
import SearchIcon from '@mui/icons-material/Search';
import { v4 as uuidv4 } from 'uuid';

const columns = [
    {
        field: 'sbj',
        headerName: 'Subject',
        width: 200,
        editable: false,
    },
    {
        field: 'prp',
        headerName: 'Property',
        width: 200,
        editable: false,
    },
    {
        field: 'obj',
        headerName: 'Object',
        width: 200,
        editable: false,
    },
];

function format_sparql_format(val) {
    if (val.startsWith("<http") && val.endsWith(">")) {
        return val;
    } else {
        var nn = val.replace(/[\\]/g, '\\\\');
        nn = nn.replace(/["]/g, '\\"');
        return '"' + nn + '"';
    }
}

export function RDFEditView(props) {
    const [rows, setRows] = useState([]);
    const [prop, setProp] = useState("");
    const [propList, setPropList] = useState([]);
    const [val, setVal]= useState("");
    const [open, setOpen] = useState(false);

    const onChangeProp = (data) => {
        if (propList.length > 0) {
            setProp(data)
        }
    }

    const openSearchURI = (event)=> {
        setOpen(true);
    }

    const closeSearchURI = (event) => {
        setOpen(false);
    }

    const getRDFString = ()=>{
        if (rows.length === 0) return;
        var id = uuidv4();
        let time = new Date();
        let time_str = time.toISOString();
        id = id+time_str
        let sbj_group = {};
        for (var i = 0; i < rows.length; i++) {
            let {sbj, prp, obj} = rows[i];
            obj = format_sparql_format(obj);
            var q = `
                ${sbj} ${prp} [
                  azp:value ${obj} ;
                  azpr:public "N" ;
                  azpr:resource "${id}" ;
                ]. 
            `;
            if (!sbj_group[sbj]) {
                sbj_group[sbj] = [];
            }
            sbj_group[sbj].push(q);
        }

        let query_list = Object.keys(sbj_group).map(sbj=>{
            let record = sbj_group[sbj].join('\n');
            if (record !== "") {
                record += `
                    ${sbj} azp:personalTimestamp [
                        azp:value "${time_str}" ;
                        azpr:resource "${id}" ;
                        azpr:public "Y" ;
                    ] . 
                `
            }
            return record;
        })

        let query_str = query_list.join(' ');

        return {id, time: time_str, record: query_str};
    }

    const search_prop = async (uri) => {
        axios.post(BSEARCH_URI + "get_rdf_properties", {
            uri: uri
        })
        .then(response=>{
            let result = response.data.map(e=>{
                let l = '';
                let dir = 0;
                if (e.lb) {
                    l= e.lb.value;
                } else if (e.rlb) {
                    l= e.rlb.value;
                    dir = 1;
                } else {
                    l = e.p.value
                }

                return {
                    name: e.p.value,
                    label: l,
                    direct: dir
                }; 
            })
            result = result.sort((a,b)=>{
                return a.label.localeCompare(b.label)
            })
            setPropList(result);
        })
    }

    const handleSelectURI = (uri) => {
        setVal(uri);
        setOpen(false);
    }

    const handleAdd = (event) => {
        if (prop.name && val !== "") {
            let s = '';
            let p = '<'+prop.name+'>';
            let o = '';

            if (prop.direct === 0) {
                s = '<'+props.uri+'>'
                o = val
            } else {
                if (!(val.startsWith("<http") && val.endsWith(">"))) {
                    return;
                }
                s = val
                o = '<'+props.uri+'>'
            }
            let r = {
                id: rows.length,
                sbj: s,
                prp: p,
                obj: o
            };
            setRows([...rows, r])
        }
    }

    useEffect(()=>{
        search_prop(props.uri);
    }, [props.uri])

    return (
        <Dialog open={props.open} onClose={props.handleClose}>
            <DialogTitle>Add Ontology Records</DialogTitle>
            <DialogContent>
            <DialogContentText>
                You can add several new records to Accziom Ontology in this dialog.
            </DialogContentText>
                <Box sx={{mt:2}}>
                    <ComboBox label="Property" value={prop} list={propList} keyfield="label" onChange={onChangeProp}/>
                    <Paper
                      sx={{ mt:2, display: 'flex', alignItems: 'center', width: '100%' }}
                    >
                        <TextField
                          size="small"
                          variant="standard"
                          label="Object"
                          sx={{ flex:1 }}
                          value={val}
                          placeholder="Input a value or an uri"
                          inputProps={{ 'aria-label': 'input value' }}
                          onChange={event=>{setVal(event.target.value)}}
                        />
                        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                        <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions" onClick={openSearchURI}>
                            <SearchIcon />
                        </IconButton>
                    </Paper>
                    <URISearch open={open} handleClose={closeSearchURI} handleOk={handleSelectURI}/>

                    <Box sx={{ textAlign:"center"}}>
                        <Button sx={{width:"50%", mt:2}} variant="contained" onClick={handleAdd} size="medium">Add</Button>
                    </Box>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={3}
                        rowsPerPageOptions={[5]}
                        getRowId={(row) => row.id}
                        sx={{height:250, mt:2}}
                        disableSelectionOnClick
                    />
                </Box>
            <MintNFTView style={{sx:{mt:2}}} uri={props.uri} getRDFString={getRDFString}/> 
            </DialogContent>
            <DialogActions>
            <Button onClick={props.handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}