import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { useGlobalState } from "../../common/globalState";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import axios from "axios";
//import { encrypt } from '../rsa_encrypt';
import {
  httprequest,
  register_reserve,
  secure_transaction,
} from "../comm_token";
import { encrypt } from "../rsa_encrypt";
import { alpha, styled } from "@mui/material/styles";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

const full_columns = [
  {
    field: "btc_address",
    headerName: "BTC Address",
    width: 300,
    editable: false,
  },
  {
    field: "reserve",
    headerName: "Reserve Amount",
    width: 120,
    editable: false,
  },

  {
    field: "limit_reserve",
    headerName: "Limit of Reserve",
    width: 100,
    editable: false,
  },
  {
    field: "active",
    headerName: "BTC Reserve Active",
    width: 100,
    editable: false,
  },
  {
    field: "weight",
    headerName: "Reward Weight",
    width: 100,
    editable: false,
  },
  {
    field: "role",
    headerName: "Role",
    width: 100,
    editable: false,
  },
  {
    field: "email",
    headerName: "E-Mail",
    width: 100,
    editable: false,
  },
  {
    field: "status",
    headerName: "BTC API Status",
    width: 100,
    editable: false,
  },
  {
    field: "comment",
    headerName: "Comment",
    width: 100,
    editable: false,
  },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {value === index && (
        <Box
          sx={{
            pl: 3,
          }}
        >
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

async function change_user_data(
  src_address,
  btc_address,
  tar_address,
  requests,
  api_key
) {
  var output = {
    btc_address: encrypt(btc_address),
    mrc_address: encrypt(tar_address),
    api_requests: encrypt(requests),
    api_key: encrypt(api_key),
  };
  var reason = JSON.stringify(output);
  var res = await secure_transaction(
    src_address,
    0,
    "change_reserve",
    null,
    reason
  );
  return res;
}

async function change_reserve_data(
  src_address,
  btc_address,
  limit_reserve,
  active,
  weight,
  role
) {
  var output = {
    btc_address: encrypt(btc_address),
    weight: encrypt(weight),
    limit_reserve: encrypt(limit_reserve.toString()),
    active: encrypt(active.toString()),
    role: encrypt(role),
  };
  var reason = JSON.stringify(output);
  var res = await secure_transaction(
    src_address,
    0,
    "operate_reserve",
    null,
    reason
  );
  return res;
}

async function register_user_data(
  src_address,
  btc_address,
  mrc_address,
  requests,
  api_key,
  api_pin,
  email,
  comment
) {
  var output = {
    btc_address: encrypt(btc_address),
    mrc_address: encrypt(mrc_address),
    api_requests: encrypt(requests),
    api_key: encrypt(api_key),
    pin: encrypt(api_pin),
    email: encrypt(email),
    comment: comment,
  };
  var res = await register_reserve(output);
  return res;
}

const ValidationTextField = styled(TextField)({
  ".MuiFormLabel-root": {
    color: "rgba(255, 255, 255, 0.5)",
  },
  ".MuiInputBase-input": {
    color: "white",
  },
  ".MuiOutlinedInput-notchedOutline": {
    color: "white",
    borderColor: "white !important",
  },
  "& input:valid + fieldset": {
    borderColor: "rgba(255, 255, 255, 0.12)",
    borderWidth: 1,
  },
  "& input:invalid + fieldset": {
    borderColor: "rgba(255, 255, 255, 0.12)",
    borderWidth: 1,
  },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 6,
    padding: "4px !important", // override inline-style
  },
});

function MyReserve(props) {
  const [state, dispatch] = useGlobalState();
  const [mrc_address, setAddress] = React.useState(
    props.data ? props.data.mrc_address : state.mrcAddress
  );
  const [requests, setRequests] = React.useState(
    props.data ? props.data.api_requests : ""
  );
  const [btc_address, setBTCAddress] = React.useState("");
  const [api_key, setApiKey] = React.useState(
    props.data ? props.data.api_key : ""
  );
  const [api_pin, setApiPin] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [comment, setComment] = React.useState("");
  const [btnEnabled, setBtnEnable] = React.useState(false);

  React.useEffect(() => {
    setBtnEnable(
      btc_address !== "" &&
        mrc_address !== "" &&
        api_key !== "" &&
        api_pin !== "" &&
        requests !== "" &&
        email !== ""
    );
  }, [btc_address, mrc_address, requests, api_key, api_pin, email]);

  if (props.data == null) {
    return (
      <Box sx={{ height: 400, width: 535 }}>
        <ValidationTextField
          id="outlined-read-only-input"
          label="BTC Address"
          required
          value={btc_address}
          InputProps={{
            readOnly: false,
          }}
          onChange={(event) => {
            setBTCAddress(event.target.value);
          }}
          sx={{ width: "100%", mx: 0.5, mb: 2 }}
        />

        <ValidationTextField
          id="outlined-read-only-input"
          label="BTC API Key"
          required
          value={api_key}
          InputProps={{
            readOnly: false,
          }}
          onChange={(event) => {
            setApiKey(event.target.value);
          }}
          sx={{ width: "100%", mx: 0.5, my: 2 }}
        />
        <ValidationTextField
          id="outlined-read-only-input"
          label="BTC API Security Pin Code"
          value={api_pin}
          required
          InputProps={{
            readOnly: false,
          }}
          onChange={(event) => {
            setApiPin(event.target.value);
          }}
          sx={{ width: "100%", mx: 0.5, my: 2 }}
        />
        <ValidationTextField
          id="outlined-read-only-input"
          label="MERc Address"
          value={mrc_address}
          required
          InputProps={{
            readOnly: false,
          }}
          sx={{ width: "100%", mx: 0.5, my: 2 }}
          onChange={(event) => {
            setAddress(event.target.value);
          }}
        />
        <ValidationTextField
          id="outlined-read-only-input"
          label="Maximum API Requests per Day"
          value={requests}
          required
          InputProps={{
            readOnly: false,
          }}
          sx={{ width: "100%", mx: 0.5, my: 2 }}
          onChange={(event) => {
            setRequests(event.target.value);
          }}
        />
        <ValidationTextField
          id="outlined-read-only-input"
          label="E-mail"
          required
          value={email}
          InputProps={{
            readOnly: false,
          }}
          sx={{ width: "100%", mx: 0.5, my: 2 }}
          onChange={(event) => {
            setEmail(event.target.value);
          }}
        />
        <ValidationTextField
          id="outlined-read-only-input"
          label="Comment"
          value={comment}
          InputProps={{
            readOnly: false,
          }}
          sx={{ width: "100%", mx: 0.5, my: 2 }}
          onChange={(event) => {
            setComment(event.target.value);
          }}
        />
        {btnEnabled ? (
          <Button
            variant="contained"
            sx={{
              width: 250,
              mx: 13,
              my: 2,
              color: "white",
              backgroundColor: "rgba(255, 255, 255, 0.06)",
            }}
            onClick={async () => {
              register_user_data(
                state.mrcAddress,
                btc_address,
                mrc_address,
                requests,
                api_key,
                api_pin,
                email,
                comment
              );
            }}
          >
            Register Reserve
          </Button>
        ) : (
          <Button
            variant="contained"
            sx={{
              width: 250,
              mx: 13,
              my: 2,
              color: "white",
              backgroundColor: "rgba(255, 255, 255, 0.06)",
            }}
            disabled
          >
            Register Reserve
          </Button>
        )}
      </Box>
    );
  } else {
    return (
      <Box sx={{ height: 400, width: 535 }}>
        <TextField
          id="outlined-read-only-input"
          label="BTC Address"
          value={props.data.btc_address}
          InputProps={{
            readOnly: true,
          }}
          sx={{ width: "100%", mx: 0.5, mb: 2 }}
        />

        <TextField
          id="outlined-read-only-input"
          label="Current BTC Reserve"
          value={props.data.reserve.toLocaleString("en-US")}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">Satoshi</InputAdornment>
            ),
          }}
          sx={{ width: "100%", mx: 0.5, my: 2 }}
        />
        <TextField
          id="outlined-read-only-input"
          label="Limit Reserve"
          value={(props.data.limit_reserve * 1e8).toLocaleString("en-US")}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">Satoshi</InputAdornment>
            ),
          }}
          sx={{ width: "100%", mx: 0.5, my: 2 }}
        />
        <TextField
          id="outlined-read-only-input"
          label="BTC Reserve Active"
          value={props.data.active}
          InputProps={{
            readOnly: true,
          }}
          sx={{ width: "100%", mx: 0.5, my: 2 }}
        />
        <TextField
          id="outlined-read-only-input"
          label="API Status"
          value={props.data.status}
          InputProps={{
            readOnly: true,
          }}
          sx={{ width: "100%", mx: 0.5, my: 2 }}
        />
        <ValidationTextField
          id="outlined-read-only-input"
          label="BTC API Key"
          required
          value={api_key}
          InputProps={{
            readOnly: false,
          }}
          onChange={(event) => {
            setApiKey(event.target.value);
          }}
          sx={{ width: "100%", mx: 0.5, my: 2 }}
        />
        <ValidationTextField
          id="outlined-read-only-input"
          label="MERc Address"
          required
          value={mrc_address}
          InputProps={{
            readOnly: false,
          }}
          onChange={(event) => {
            setAddress(event.target.value);
          }}
          sx={{ width: "100%", mx: 0.5, my: 2 }}
        />
        <ValidationTextField
          id="outlined-read-only-input"
          label="Maximum API Requests per Day"
          required
          value={requests}
          InputProps={{
            readOnly: false,
          }}
          onChange={(event) => {
            setRequests(event.target.value);
          }}
          sx={{ width: "100%", mx: 0.5, my: 2 }}
        />

        <Button
          variant="contained"
          sx={{ width: 250, mx: 13, my: 2 }}
          onClick={async () => {
            var res = await change_user_data(
              state.mrcAddress,
              props.data.btc_address,
              mrc_address,
              requests,
              api_key
            );
            if (res.status === true) {
              props.data.mrc_address = mrc_address;
              props.data.api_requests = requests;
            }
          }}
        >
          Change
        </Button>
      </Box>
    );
  }
}

function EditRow(props) {
  const [limitReserve, setLimitReserve] = React.useState(
    props.data ? (props.data.limit_reserve * 1e8).toLocaleString("en-US") : 0
  );
  const [active, setActive] = React.useState(
    props.data ? props.data.active : false
  );
  const [weight, setWeight] = React.useState(
    props.data ? props.data.weight : 0
  );
  const [role, setRole] = React.useState(props.data ? props.data.role : "user");

  React.useEffect(() => {
    if (props.data) {
      setLimitReserve(props.data.limit_reserve);
      setActive(props.data.active);
      setWeight(props.data.weight);
      setRole(props.data.role);
    }
  }, [props.data]);

  if (props.data == null) {
    return <div></div>;
  }

  return (
    <Box sx={{ height: 400, width: 500 }}>
      <p>Details</p>
      <TextField
        id="outlined-read-only-input"
        label="BTC Address"
        value={props.data.btc_address}
        InputProps={{
          readOnly: true,
        }}
        sx={{ width: 450, mx: 0.5, my: 2 }}
      />
      <ValidationTextField
        id="outlined-read-only-input"
        label="Maximum Reserve Amount"
        required
        value={limitReserve}
        InputProps={{
          readOnly: false,
        }}
        onChange={(event) => {
          setLimitReserve(event.target.value);
        }}
        sx={{ width: 450, mx: 0.5, my: 2 }}
      />
      <FormControl sx={{ width: 450, mx: 0.5, my: 2 }}>
        <InputLabel id="demo-simple-select-label">
          BTC Reserve Active
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={active}
          label="BTC Reserve Active"
          onChange={(event) => {
            setActive(event.target.value);
          }}
        >
          <MenuItem value={true}>True</MenuItem>
          <MenuItem value={false}>False</MenuItem>
        </Select>
      </FormControl>

      <ValidationTextField
        id="outlined-read-only-input"
        label="Reward Weight"
        required
        value={weight}
        InputProps={{
          readOnly: false,
        }}
        onChange={(event) => {
          setWeight(event.target.value);
        }}
        sx={{ width: 450, mx: 0.5, my: 2 }}
      />

      <FormControl sx={{ width: 450, mx: 0.5, my: 2 }}>
        <InputLabel id="demo-simple-select-label">Role</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={role}
          label="Role"
          onChange={(event) => {
            setRole(event.target.value);
          }}
        >
          <MenuItem value={"user"}>User</MenuItem>
          <MenuItem value={"admin"}>Administrator</MenuItem>
        </Select>
      </FormControl>

      <Button
        variant="contained"
        sx={{ width: 250, mx: 13, my: 2 }}
        onClick={async () => {
          var res = await change_reserve_data(
            props.mrcAddress,
            props.data.btc_address,
            parseFloat(limitReserve) / 1e8,
            active,
            weight,
            role
          );
          if (res.status === true) {
            props.data.limit_reserve = parseFloat(limitReserve) / 1e8;
            props.data.active = active;
            props.data.weight = weight;
            props.data.role = role;
          }
        }}
      >
        Change
      </Button>
    </Box>
  );
}

function OtherReserve(props) {
  const [editRow, setEditRow] = React.useState(null);

  const editReserve = React.useCallback(
    (id) => () => {
      props.data.forEach((e) => {
        if (e.btc_address === id) {
          setEditRow(e);
        }
      });
    },
    []
  );

  const columns = React.useMemo(
    () => [
      {
        field: "actions",
        type: "actions",
        width: 80,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<EditOutlinedIcon />}
            label="Edit"
            onClick={editReserve(params.id)}
          />,
        ],
      },
      {
        field: "btc_address",
        headerName: "BTC Address",
        width: 340,
        editable: false,
      },
      {
        field: "reserve",
        headerName: "Reserve Amount",
        width: 120,
        editable: false,
      },

      {
        field: "limit_reserve",
        headerName: "Limit of Reserve",
        width: 100,
        editable: false,
      },
      {
        field: "active",
        headerName: "BTC Reserve Active",
        width: 100,
        editable: false,
      },
      {
        field: "weight",
        headerName: "Reward Weight",
        width: 100,
        editable: false,
      },
      {
        field: "role",
        headerName: "Role",
        width: 100,
        editable: false,
      },
      {
        field: "email",
        headerName: "E-Mail",
        width: 100,
        editable: false,
      },
      {
        field: "status",
        headerName: "BTC API Status",
        width: 100,
        editable: false,
      },
      {
        field: "comment",
        headerName: "Comment",
        width: 100,
        editable: false,
      },
    ],
    [editReserve]
  );

  if (props.data == null || props.data.length <= 1) {
    return (
      <div style={{ width: 535, color: "white", textAlign: "center" }}>
        You have no permission to manage other BTC reserves.
      </div>
    );
  }

  return (
    <Box sx={{ height: 300, width: 535 }}>
      <DataGrid
        rows={props.data}
        columns={columns}
        pageSize={3}
        rowsPerPageOptions={[3]}
        getRowId={(row) => row.btc_address}
      />

      <EditRow mrcAddress={props.mrcAddress} data={editRow} />
    </Box>
  );
}

const dataGridStyle = {
  borderRadius: "16px",
  color: "white",
  backgroundColor: "rgba(255, 255, 255, 0.06)",
  border: "1px solid rgba(255, 255, 255, 0.12)",
  ".MuiTablePagination-displayedRows": { color: "white" },
  ".Mui-disabled": { color: "rgba(255, 255, 255, 0.12)" },
  ".Mui-enabled": { color: "white" },
  ".MuiButtonBase-root": { color: "white" },
  ".MuiDataGrid-columnHeaders": {
    borderBottom: "1px solid rgba(255, 255, 255, 0.12)",
  },
  ".MuiDataGrid-footerContainer": {
    borderTop: "1px solid rgba(255, 255, 255, 0.12)",
  },
  ".MuiDataGrid-cell": {
    borderBottom: "none",
  },
};

export default function BTCDetailContent(props) {
  const [value, setValue] = React.useState(0);
  const [state, dispatch] = useGlobalState();
  const [myReserve, setMyReserve] = React.useState(null);
  const [otherReserve, setOtherReserve] = React.useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    setMyReserve(null);
    setOtherReserve(null);
    var admin = false;
    for (var i = 0; i < state.btc_reserve_list.length; i++) {
      var e = state.btc_reserve_list[i];
      if (e.mrc_address === state.mrcAddress) {
        setMyReserve(e);
        if (e.role === "admin") {
          admin = true;
        }
      }
    }
    if (admin) {
      setOtherReserve(state.btc_reserve_list);
    }
  }, [state.btc_reserve_list, state.mrcAddress]);

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: "transparent",
        display: "flex",
        height: 420,
        width: 720,
      }}
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{
          borderColor: "divider",
          ".MuiButtonBase-root": {
            textTransform: "none",
            minWidth: "unset",
          },
          ".Mui-selected": { color: "white !important" },
          ".MuiTab-textColorPrimary": { color: "rgba(255, 255, 255, 0.4)" },
          ".MuiTabs-indicator": {
            backgroundColor: "white !important",
          },
        }}
        width={200}
      >
        <Tab label="Reserve Address" {...a11yProps(0)} />
        <Tab label="Transaction History" {...a11yProps(1)} />
        <Tab label="My Setting" {...a11yProps(2)} />
        <Tab label="Manager Setting" {...a11yProps(3)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <Box sx={{ height: 400, width: 535 }}>
          <DataGrid
            rows={props.adrData.rows}
            columns={props.adrData.columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            getRowId={(row) => row.key}
            sx={dataGridStyle}
            disableSelectionOnClick
          />
        </Box>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Box sx={{ height: 400, width: 535 }}>
          <DataGrid
            rows={props.histData.rows}
            columns={props.histData.columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            getRowId={(row) => row.key}
            initialState={{
              sorting: {
                sortModel: [{ field: "timestamp", sort: "asc" }],
              },
            }}
            sx={dataGridStyle}
            disableSelectionOnClick
          />
        </Box>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <MyReserve data={myReserve} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <OtherReserve mrcAddress={state.mrcAddress} data={otherReserve} />
      </TabPanel>
    </Box>
  );
}
