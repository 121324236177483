import {
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  NativeSelect,
  Select,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { MERcTextField } from "../amountText";
import FlipCameraAndroidIcon from "@mui/icons-material/FlipCameraAndroid";
import { Spinner } from "../Spiner";

export function GasLimitOption(params) {
  const [payMode, setPayMode] = useState(1);
  const [gasLimit, setGasLimit] = useState(params.defaultValue);
  const [running, setRun] = useState(false);

  useEffect(() => {
    setGasLimit(params.defaultValue);
  }, [params.defaultValue]);

  const handleEstimateClick = async (event) => {
    setRun(true);
    await params.estimate(event);
    setRun(false);
  };

  const handleChangeMode = (event) => {
    setPayMode(event.target.value);
    if (event.target.value == 0) {
      params.setValue(0);
    } else {
      params.setValue(gasLimit);
    }
  };

  const setValue = (newVal) => {
    setGasLimit(newVal);
    params.setValue(newVal);
  };

  const dialog_text_style = {
    color: "white",
    "& .MuiInputBase-input": {
      color: "white",
    },
    "& .MuiInputLabel-root": {
      color: "white",
    },
    "&:before": {
      borderBottom: "1px solid white !important",
    },
    "& .MuiInput-underline:hover:before": {
      borderBottom: "1px solid white",
    },
    "& .MuiInput-underline:hover:after": {
      borderBottom: "1px solid white",
    },
    ".MuiSvgIcon-root": {
      color: "white",
    },
  };

  return (
    <Stack>
      <Grid container>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel
              variant="standard"
              htmlFor="uncontrolled-native"
              sx={{ color: "white" }}
            >
              Pay gas fee by Using
            </InputLabel>
            <NativeSelect
              defaultValue={1}
              inputProps={{
                name: "Pay Mode",
                id: "uncontrolled-native",
              }}
              sx={dialog_text_style}
              onChange={handleChangeMode}
            >
              <option style={{ color: "black" }} value={0}>
                Ether
              </option>
              <option style={{ color: "black" }} value={1}>
                MERc
              </option>
            </NativeSelect>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <Stack direction="row">
            <MERcTextField
              disabled={payMode != 1}
              label="Gas Limit"
              value={gasLimit}
              setValue={setValue}
              min={params.min}
              extra={{
                helperText: `The recommended value is ${gasLimit * 2}.`,
              }}
            />
            <div>
              {running ? (
                <Spinner
                  color={"red"}
                  style={{ height: "24px", marginTop: "5px" }}
                />
              ) : (
                <IconButton
                  onClick={handleEstimateClick}
                  sx={{ color: "white" }}
                >
                  <FlipCameraAndroidIcon />
                </IconButton>
              )}
            </div>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
}
