module.exports = {
  enabledButton: {
    color: "white",
    borderColor: "rgba(255, 255, 255, 0.12)",
    borderRadius: 15,
    fontSize: "12px",
    width: "200px",
    height: "40px",
    backgroundColor: "transparent",
    marginTop: "30px",
  },
  disabledButton: {
    borderColor: "rgba(255, 255, 255, 0.12)",
    borderRadius: 15,
    fontSize: "12px",
    width: "200px",
    height: "40px",
    color: "rgb(255, 255, 255, 0.26)",
    backgroundColor: "rgba(0, 0, 0, 0.12)",
    marginTop: "30px",
  },
};
