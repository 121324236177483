import React, { createContext, useContext, useReducer } from "react";

const defaultGlobalState = {
    pane: "Search",
    curView: "ACCZIOM",
    
    entityName: [],
    entityID: "",
    abn: "",
    acn: "",
    lei: "",

    rdf: {},
    predictedFee: 0,
    account: "",
    
    anzicCode: null,
    anzicCodeByCode: null,

    anzicQuery: [],

    mercBalance: {},
    mercTransaction: null,
    mercStatistics: {},
    nft: [],
    logged: false,

    subURI: '',
    subTitle: "",
    openDetails: false,
    
    rdfHistory: {query:"", fee:0, result:{}},
    subRdfHistory: {query:"", fee:0, result:{}},
    abnHistory: {query:"", result:{}},
    acnHistory: {query:"", result:{}},
    tpbHistory: {query:"", result:{}},
    truelocalHistory: {query:"", result:{}},
    asxHistory: {query:"", result:{}},
    leiHistory: {query:"", result:{}},
    gbgHistory: {query:"", result:{}},
    bingHistory: {query:"", result:{}},
    mapHistory: {query:"", result:[]},
    anzicHistory: {query:"", result:{}},

    commenter: '',
    chatHistoryReload: false,

    // copid from merc_front project
    btc_reserve_list: [],
    seed: "",
    mrcAddress: "",
    btcAddress: "",
    btcBalance: 0,
    mrcBalance: "",
    mrc2Balance: "",
    amount: 0,
    mrc20_contract: null,
    token_ws_server: null,
    send_msg: "",
    layer2:true,
    etherBalance: "",
    totalSupply: "",
    btcReserves: "",
    btcOperationFee: "",
    btcReserveBalance: "",
    layer2Total: 0,
    mercReserves: 0,
    transactionHistory: [],
    merc_contract_address: "",
    merc_owner: "",
    exchanger:"",
    exchangeBalance: "",
    inputAmountError: false,
};

const globalStateContext = createContext(defaultGlobalState);
const dispatchStateContext = createContext(undefined);

export function GlobalStateProvider ({ children }) {
    const [state, dispatch] = useReducer(
      (state, newValue) => ({ ...state, ...newValue }),
      defaultGlobalState
    );
    return (
      <globalStateContext.Provider value={state}>
        <dispatchStateContext.Provider value={dispatch}>
          {children}
        </dispatchStateContext.Provider>
      </globalStateContext.Provider>
    );
}
  
export function useGlobalState () {
    return [
        useContext(globalStateContext),
        useContext(dispatchStateContext)
    ];
}