import { Box } from "@mui/material";
import { ReactComponent as ProfileIcon } from "./icons/profile.svg";

const Profile = ({ sx, ...rest }) => {
  return (
    <Box display="flex" alignItems="center" ml="5px" sx={{ "&:hover": { cursor: "pointer" }, ...sx }} {...rest}>
      <ProfileIcon />
    </Box>
  );
};

export default Profile;
