import React, { useEffect, useState } from "react";
import { Budget } from "./components/budget";
import { useGlobalState } from "../common/globalState";
import btcLogo from "../icons/btc-logo.png";

export const BTCReserves = () => {
  const [state, _] = useGlobalState();
  const [val, setVal] = useState("...");
  const [subVal, setSubVal] = useState([]);

  useEffect(() => {
    setVal(state.btcReserveBalance.toLocaleString("en-US"));
    var reserve = state.btcReserves || 0;
    var si = [];

    /*
        si.push({
            title: "Satoshi Backing MERc",
            content: reserve.toLocaleString("en-US") + " Satoshi",
            tooltip: "This value is the amount of Bitcoin collateral for MERc. It should be equal to Total Supply of MERc."
        })
        */
    setSubVal(si);
  }, [state.btcReserves, state.btcOperationFee, state.btcReserveBalance]);

  return (
    <Budget
      title="Satoshi Reserve Balance"
      value={val}
      up="true"
      percent="1%"
      image={btcLogo}
      alt="btc-reserve"
      type="btc"
      sub_items={subVal}
    />
  );
};
