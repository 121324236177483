import React, { Fragment, useEffect, useRef, useState } from "react";
import { GoogleMap, InfoWindow, MarkerF } from "@react-google-maps/api";
import axios from "axios";
import { BSEARCH_URI } from "../common/config";
import { useGlobalState } from "../common/globalState";
import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import { useLoadScript } from '@react-google-maps/api';

async function search_map(query, callback_fn) {
  try {
    //alert(JSON.stringify(params));
    axios.get(
      BSEARCH_URI + "geolocation", { params: { "entity": query } }
    )
      .then(response => {
        if (response && response.data) {
          var result = response.data;
          console.log(`geolocation=${result}`)
          result = result.map(({ lat, lng }) => ({ lat: parseFloat(lat), lng: parseFloat(lng) }))
          callback_fn(result);
        }
        else {
          console.log('https request error')
          callback_fn([]);
        }

      })
  }
  catch (err) {
    console.log(err);
    var result = [];
    callback_fn(result);
  }
}


export function MapView(props) {
  const [state, dispatch] = useGlobalState();
  const [onSpin, setSpin] = useState(false);
  const [markers, setContent] = useState([]);
  const [query, setQuery] = useState("");

  const [activeMarker, setActiveMarker] = useState(null);
  const [height, setHeight] = useState(window.innerHeight - 250);
  const [gmap, setMap] = useState(null);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyA8eVticKgtNJmpl6n-HxPAF5WIz65eTqQ"
  })

  React.useEffect(() => {
    function handleResize() {
      setHeight(window.innerHeight - 250)
    }
    window.addEventListener('resize', handleResize)
  })

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const handleOnLoad = (map) => {
    if (map === null) return;
    setMap(map);
    const bounds = new window.google.maps.LatLngBounds();
    let mm = markers;
    if (markers.length === 0) {
      mm = [{ lat: -28.643387, lng: 153.612224 }]
    }
    console.log(`mm=${JSON.stringify(mm)}`)
    mm.forEach((position) => bounds.extend(position));
    map.fitBounds(bounds);
  };

  useEffect(() => {
    var q = state.rdfHistory.result.uri || "";

    if (q === "") {
      setContent([]);
      setSpin(false);
      return;
    }
    if (q === query) {
      setSpin(false);
      return;
    }

    setQuery(q);

  }, [state.rdfHistory.result.uri])

  useEffect(() => {
    handleOnLoad(gmap);
  }, [markers])

  useEffect(() => {
    if (query === "") {
      setContent([]);
      return;
    }

    if (query === state.mapHistory.query) {
      setContent(state.mapHistory.result);
      setSpin(false);
      return;
    }

    setSpin(true);
    search_map(query, result => {
      setContent(result);
      setSpin(false);
      dispatch({
        mapHistory: { query: query, result: result }
      })
    })
  }, [query])

  if (onSpin) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <CircularProgress size={50} />
      </Box>
    )
  } else {

    return (isLoaded ?
      <Fragment>
        <div style={{ height: '100%', width: '100%' }}>
          <GoogleMap
            onLoad={handleOnLoad}
            onClick={() => setActiveMarker(null)}
            mapContainerStyle={{ width: "100%", height: height }}
            zoom={5}
          >
            {markers.length > 0 && markers.map((position, id) => (
              <MarkerF
                key={id}
                position={position}
                onClick={() => handleActiveMarker(id)}
              /*icon={{
                path:
                  "M12.75 0l-2.25 2.25 2.25 2.25-5.25 6h-5.25l4.125 4.125-6.375 8.452v0.923h0.923l8.452-6.375 4.125 4.125v-5.25l6-5.25 2.25 2.25 2.25-2.25-11.25-11.25zM10.5 12.75l-1.5-1.5 5.25-5.25 1.5 1.5-5.25 5.25z",
                fillColor: "#0000ff",
                fillOpacity: 1.0,
                strokeWeight: 0,
                scale: 1.25
              }}*/
              >
                {activeMarker === id ? (
                  <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                    <div>{state.entityName[0]}</div>
                  </InfoWindow>
                ) : null}
              </MarkerF>
            ))}
          </GoogleMap>
        </div>
      </Fragment>
      :
      null
    );
  }
}
