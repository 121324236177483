import React from "react";
import { Dialog, DialogTitle, Stack, Button } from "@mui/material";
import PropTypes from "prop-types";

import metaLogo from "../../icons/metamask-icon.svg";

export const NoMetamaskDialog = (props) => {
  const { onClose, open } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      sx={{
        ".MuiPaper-root": {
          padding: 3,
          borderRadius: "20px",
        },
      }}
    >
      <DialogTitle>Set up MetaMask first!</DialogTitle>
      <Stack
        spacing={2}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <img src={metaLogo} width="100px" />
        <Button
          sx={{ color: "blue" }}
          href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn"
          target="_blank"
        >
          Go to Link!
        </Button>
      </Stack>
    </Dialog>
  );
};

NoMetamaskDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
