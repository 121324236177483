import { Button, Container,  Grid, Paper, TextField} from "@mui/material";

import axios from "axios";
import { BSEARCH_URI } from "../common/config";
import React, {  useState } from "react";


export function RelationRecognitionView(props) {
  const [text, setText] = useState("")
  const [result, setResult] = useState("")

  const recognition = () => {
    setResult("Requesting... It may take several minutes.")
    axios.post(BSEARCH_URI + "relation_recognition", {
      'text': text
    })
    .then(response=>{
      let data = response.data
      setResult(JSON.stringify(data.data, null, 3))
    })
  }

  return (
    <Container sx={{ mt: 4, mb: 4, display:props.show?'block':'none', textAlign: "center"}}>
      <Paper sx= {{m:2, p: 2, width: '90%'}}>
        <Grid container direction="column" spacing={1}>
        <Grid item>
          <TextField
            label="Text"
            sx={{width: '80%'}}
            multiline
            minRows={8}
            maxRows={8}
            value={text}
            onChange={evt=>setText(evt.target.value)}
          />
        </Grid>
        <Grid item>
          <Button onClick={()=>recognition()}>Recognition</Button>
        </Grid>
        <Grid item>
          <TextField
            label="Result"
            sx={{width: '80%'}}
            multiline
            minRows={10}
            maxRows={10}
            value={result}
          />
        </Grid>
        
      </Grid>
      </Paper>
    </Container>

    
  );

}
