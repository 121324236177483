import { Accordion, AccordionDetails, AccordionSummary, Avatar, Button, Divider, FilledInput, FormControl, Grid, IconButton, InputAdornment, InputBase, InputLabel, List, ListItem, ListItemAvatar, ListItemText, NativeSelect, Paper, Stack, TextField, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useEffect, useState } from "react";
import axios from "axios";
import { token_admin_server_url, token_server_url } from "../config";
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

function Preferences(props) {
  let content_data = props.content
  let cont = content_data.content
  console.log(content_data)
  content_data.text = {}
  if (content_data == undefined || cont == undefined) {
    return (<div></div>)
  }
  return (
    <Accordion defaultExpanded={true}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h6">{props.title}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ alignItems: "center", m: "10pt" }}>
        <Stack spacing={4}>
          {
            Object.keys(cont).map((e, index) => (
              content_data.range && content_data.range[e] ?
                <FormControl fullWidth key={index}>
                  <InputLabel variant="outlined" htmlFor="uncontrolled-native">
                    {e}
                  </InputLabel>
                  <NativeSelect
                    defaultValue={cont[e] || ""}
                    inputProps={{
                      name: 'age',
                      id: 'uncontrolled-native',
                    }}
                    variant="outlined"
                    onChange={evt => {
                      cont[e] = evt.target.value
                      props.onChange(e, evt.target.value)
                    }}
                  >
                    {
                      content_data.range[e].map((ee, ii) => (
                        <option key={ii} value={ee}>{ee}</option>
                      ))
                    }
                  </NativeSelect>
                </FormControl>
                :
                Array.isArray(cont[e]) ?
                  <Stack>
                    <Paper
                      component="form"
                      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
                    >
                      <FormControl fullWidth key={index}>
                        <InputLabel htmlFor="filled-adornment-amount">{e}</InputLabel>
                        <FilledInput
                          id={e}
                          variant="standard"
                        />
                      </FormControl>
                      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                      <IconButton
                        color="primary" sx={{ p: '10px' }} aria-label="directions"
                        onClick={(evt) => {
                          let v = document.getElementById(e).value
                          document.getElementById(e).value = ""
                          props.append(e, v)
                        }}
                      >
                        <AddIcon />
                      </IconButton>
                    </Paper>

                    <List>
                      {
                        cont[e].map((ee, ii) => (
                          <ListItem key={ii}
                            secondaryAction={
                              <IconButton edge="end" aria-label="delete"
                                onClick={evt => { props.delete(e, ii) }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            }>
                            <ListItemText primary={ee} />
                          </ListItem>
                        ))
                      }
                    </List>
                  </Stack>
                  :
                  <FormControl fullWidth variant="filled" key={index}>
                    <InputLabel htmlFor="filled-adornment-amount">{e}</InputLabel>
                    <FilledInput
                      id="filled-adornment-amount"
                      value={cont[e] || ""}
                      variant="outlined"
                      onChange={evt => {
                        cont[e] = evt.target.value
                        props.onChange(e, evt.target.value)
                      }}
                    />
                  </FormControl>
            ))
          }
        </Stack>

      </AccordionDetails>
    </Accordion>
  )
}

function PreferenceModel(props) {
  const [value, setValue] = useState({})

  useEffect(() => {
    console.log(token_server_url + props.request)
    axios.get(token_server_url + props.request)
      .then(async response => {
        if (response && response.data) {
          let res = response.data;
          if (res.status === true) {
            setValue(res.data)
            props.onChange(res.data)
          }
        }
      })
      .catch(err => {
        console.log(err);
        //alert("Can not connect to Admin Server.")
      })

  }, [])

  const onChange = (key, vv) => {
    value.content[key] = vv
    let newValue = {
      ...value,
      content: {
        ...value.content,
        [key]: vv
      }
    }
    setValue(newValue)
    props.onChange(newValue)
  }

  const Delete = (e, ind) => {
    value.content[e].splice(ind, 1)
    let newValue = {
      ...value,
      content: {
        ...value.content,
        [e]: value.content[e]
      }
    }
    console.log(value.content[e])
    setValue(newValue)
    props.onChange(newValue)
  }

  const Append = (e, v) => {
    v = v.trim()
    if (v == "") return;
    let newValue = {
      ...value,
      content: {
        ...value.content,
        [e]: [...value.content[e], v]
      }
    }
    setValue(newValue)
    props.onChange(newValue)
  }

  return (
    <Preferences
      title={props.title}
      content={value}
      onChange={onChange}
      delete={Delete}
      append={Append}
    />
  )
}

export function AdminMonitor(props) {
  const onRefresh = async evt => {
    window.location.reload(false);
  }

  const onCancel = evt => {
    window.location.href = '/mercoin/admin'
  }

  const onChange = val=>{
    
  }

  return (
    <Stack sx={{ alignItems: "center", m: 2 }}>
      <Paper elevation={24} sx={{ alignItems: "center", m: 2, width: "80%", minWidth: "800px", maxWidth: "1200px" }}>
        <PreferenceModel
          title="Parameters"
          request="/monitor"
          onChange={onChange}
        />
        <Stack sx={{ m: 2, alignItems: "center" }}>
          <Stack direction="row" spacing={3}>
            <Button variant="contained" onClick={onRefresh} sx={{ width: "200px", alignSelf: "center" }}>Refresh</Button>
            <Button variant="contained" onClick={onCancel} sx={{ width: "200px", alignSelf: "center" }}>Back</Button>
          </Stack>
        </Stack>
      </Paper>
    </Stack>
  )
}