import { Accordion, AccordionDetails, AccordionSummary, Alert, Avatar, Backdrop, Box, Button, Card, CardContent, CardHeader, CircularProgress, Collapse, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Fab, FormControl, Grid, IconButton, InputBase, Link, List, ListItem, ListItemText, MenuItem, Paper, Select, Stack, TextField, Typography } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import ComputerIcon from '@mui/icons-material/Computer';
import PersonIcon from '@mui/icons-material/Person';
import { blue, green, orange, red } from "@mui/material/colors";
import { useGlobalState } from "../common/globalState";
import axios from "axios";
import { BSEARCH_URI, TAX_GENII_URI } from "../common/config";
import React, { useEffect, useRef, useState } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import taxGeniiLogo from './Tax Genii.png';

const init_prompt = {
    id: 0,
    type: 1,
    content: "Hi, I am Tax Genii, an assistant to you, powered by a large language model trained by OpenAI.\nI have been designed to be able to assist with Tax Legislation.\nHow can I help you?"
}

function TaxGeniiLogo() {
    return (
        <Box
        component="img"
        sx={{
          height: 43,
          maxHeight: { xs: 233, md: 167 },
          maxWidth: { xs: 350, md: 250 },
        }}
        alt="Tax Genii"
        src={taxGeniiLogo}
      />
    );
}
  
function CommentButton(props) {
    const [comment, setComment] = useState(props.comment?props.comment:"")
    const [commenter, setCommenter] = useState(props.commenter)

    const back_cahin_v1 = (e, ei)=>{
        let t = e.thought.split('\n')[0]
        if (t.startsWith('Thought: ')) {
            t = t.slice(9, t.length)
        }
        return (
        <Box key={ei}>
            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography>Thought</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        {t}
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography>Decision</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Action: {e.action}
                    </Typography>
                    <Typography>            
                        Action Input: {e.action_input}
                    </Typography>        
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography>Observation</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {e.observation.split('\n').map((e, ei)=>{
                        if (e === '') return (<Divider key={ei}/>)
                        if (e.startsWith('[Reference] https://')) {
                            return (
                                <Box key={ei}>
                                    <Typography>Reference: </Typography>
                                    <Link target="_blank" href={e.slice(12, e.length)}>{e.slice(12, e.length)}</Link>
                                </Box>
                                
                            )
                        }
                        return (
                            <Typography key={ei}>
                                {e}
                            </Typography>
                        )
                    })}
                </AccordionDetails>
            </Accordion>
        </Box>
    )
    }

    const back_cahin_v2 = (element, element_index)=>{
        let role = element.role
        let content = element.content
        if (role == undefined) return null

        return (
        <Box key={element_index}>
            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography>{role}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {content.split('\n').map((e, ei)=>{
                        if (e === '') return (<Divider key={ei}/>)
                        if (e.startsWith('[Reference] https://')) {
                            return (
                                <Box key={ei}>
                                    <Typography>Reference: </Typography>
                                    <Link target="_blank" href={e.slice(12, e.length)}>{e.slice(12, e.length)}</Link>
                                </Box>
                                
                            )
                        }
                        return (
                            <Typography key={ei}>
                                {e}
                            </Typography>
                        )
                    })}
                </AccordionDetails>
            </Accordion>
        </Box>
    )
    }

    const handleChange = evt=>{
        setComment(evt.target.value)
        props.setSaveResult('')
    }

    const handleChangeCommenter = evt=> {
        let v = evt.target.value
        setCommenter(v)
        v = v.trim()
        props.setSaveResult('')
    }

    const handleClose = evt=> {
        let v = commenter.trim()
        if (v !== props.commenter) {
            props.changeCommenter(v)
        }
        props.OpenComment(false)
    }

    const handleSave = evt=> {
        let user = commenter.trim()
        if (user === '') {
            props.setWarning(true)
            return
        }
        props.setWarning(false)
        axios.post(TAX_GENII_URI + "comment", {
            id: props.chatid,
            user: user,
            comment: comment
        })
        .then(response=>{
            let msg = response.data
            props.setSaveResult(msg.msg)
            props.SaveComment(props.index, user, comment)            
        })
    }

    const handleCopy = evt=> {
        let res = []
        if (props.scenario !== '') {
            res.push(`Scenario:\n${props.scenario}`)
        }

        if (props.version != "2.0") {
            res.push(`Query:\n${props.query}`)
        }
        //let res = [`Scenario:\n${props.scenario}`, `Query:\n${props.query}`]
        props.backchain.forEach(e=>{
            if (props.version === "2.0") {
                if (e.role == undefined) return
                res.push(`${e['role']}: ${e['content']}`)
            }
            else {
                let t = e.thought.split('\n')[0]
                if (t.startsWith('Thought: ')) {
                    t = t.slice(9, t.length)
                }
                res.push(`Thought:\n${t}`)
                res.push(`Decision:\nAction:${e.action}\nAction Input:${e.action_input}`)
                res.push(`Observation:\n${e.observation}`)
            }
            
        })
        if (props.version != "2.0") {
            res.push(`Final Answer:\n${props.answer}`)
        }
        let final_str = res.join('\n\n')
        navigator.clipboard.writeText(final_str)
    }

    return (
        <Container>
            <Button
                onClick={e=>{props.OpenComment(true)}}
            >
                Details
            </Button>
            <Dialog open={props.openComment} onClose={handleClose}>
                <DialogTitle>Details</DialogTitle>
                <DialogContent>
                    {
                        props.warning && <Alert security="info">
                            "Commenter" field is required, please enter your name.
                        </Alert>
                    }
                    {
                        props.saveResult !== '' && <Alert security="info">
                            {props.saveResult}
                        </Alert>
                    }
                    <DialogContentText>
                        This pane displays the detailed progress of reasoning for given query.
                    </DialogContentText>
                    <Accordion>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        >
                        <Typography>Scenario</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                {props.scenario}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    {
                        props.version !== "2.0" && 
                        <Accordion>
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            >
                            <Typography>Query</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    {props.query}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    }
                    <Box sx={{mt:2}}>
                        {
                            props.backchain.map((e, ei)=>back_cahin_v2(e,ei))
                        }
                    </Box>
                    { false && 
                    <Accordion>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        >
                        <Typography>Final Answer</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            { props.answer.split('\n').map((e,ei)=>(
                                <Typography key={ei}>
                                    {e}
                                </Typography>
                            ))}
                            
                        </AccordionDetails>
                    </Accordion>
                    }
                    <Divider sx={{mt:1, mb:1}}/>
                    <Paper
                        component="form"
                        min-width="80%"
                        sx={{ flex:1, p: '2px 4px', display: 'flex', alignItems: 'center', borderRadius: 2 }}
                        >
                            <Stack direction="column" sx={{flex:1}}>
                                <Typography>
                                    Comment
                                </Typography>
                                <InputBase
                                    sx={{ ml: 1, flex: 1, display:'flex'}}
                                    placeholder="Please input comment for the above reasoning of Tax Genii."
                                    onChange={handleChange}
                                    multiline
                                    minRows={6}
                                    maxRows={6}
                                    value={comment}
                                />
                                <Typography>
                                    Commenter Name
                                </Typography>
                                <InputBase
                                    sx={{ ml: 1, flex: 1, display:'flex'}}
                                    placeholder="Please input your name."
                                    onChange={handleChangeCommenter}
                                    value={commenter}
                                />
                            </Stack>
                    </Paper>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCopy}>Copy to clipboard</Button>
                    <Button onClick={handleSave}>Save</Button>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}

export function ChatbotView(props) {
    const [state, dispatch] = useGlobalState();
    const [isListening, setIsListening] = useState(false);
    const [queryText, setQueryText] = useState("");
    const [content, setContent] = useState('');
    const [reference, setReference] = useState('');
    const [queryInput, setQueryInput] = useState('');
    const [chatContent, setChatContent] = useState([init_prompt]);
    const [id, setID] = useState('');
    const lastElement = useRef();
    const [isFinal, setFinal] = useState(false);
    const [scenario, setScenario] = useState("");
    const [scenarioEditable, setScenarioEditable] = useState(true)
    const [thinking, setThinking] = useState("");
    const [openNotice1, setOpenNotice1] = useState(true);
    const [openNotice2, setOpenNotice2] = useState(true);
    const [openComment, setOpenComment] = useState(false);
    const [warning, setWarning] = useState(false)
    const [saveResult, setSaveResult] = useState('')

    const handleChangeCommenter = (value) => {
        dispatch({
            commenter: value
        })
    }

    const handleSaveComment = (index, user, comment) => {
        setChatContent((prevMsgs) => {
            let pre = prevMsgs.slice(0, index)
            let nxt = prevMsgs.slice(index+1, prevMsgs.length)
            let pt = prevMsgs[index]
            pt.meta.comment = comment
            return [...pre, pt, ...nxt]
        });
        if (state.commenter !== user) {
            dispatch({
                commenter: user
            })
        } else {
            dispatch({
                chatHistoryReload: true
            })
        }
    }

    const getCandidates = (chatid, query) =>{
        setIsListening(true)
        try{
            axios.post(BSEARCH_URI + "chat_v2", {
                api_key: '10000a28-36fc-4eb3-babd-54ca609a0da3',
                id: chatid,
                msg:query,
                context: scenario
            })
            .then(response=>{
                let msg = response.data;
                
                if (msg.kind === "thinking") {
                    setThinking(msg.msg)
                    console.log('thinking=', msg.msg)
                    getCandidates(msg.id, query)
                    return
                }

                setThinking("")
                if (msg.final === true) {
                    setFinal(true)
                    setScenarioEditable(true)
                } else {
                    setFinal(false)
                }
                setID(msg.id);
                let data = msg.msg
                let meta = null
                if (msg.backchain && msg.backchain.length > 0) {
                    meta = {
                        backchain: msg.backchain,
                        query: msg.query,
                        scenario: msg.context,
                        version: "2.0"
                    }
                }
                setChatContent((prevMsgs) => [...prevMsgs, {id:prevMsgs.length, type:1, chatid: msg.id, content: data, meta: meta}]);
                setWarning(false)
                setSaveResult('')
                setIsListening(false)
            })
            .catch(err=>{
                alert(err)
                setFinal(true)
                setScenarioEditable(true)
                setIsListening(false)
            })
            .finally(()=>{
                
            })
        }
        catch(err) {
            alert(err)
            setIsListening(false)
            setFinal(true)
        }
    }

    const inputQuery = (chatid, query) => {
        setChatContent((prevMsgs) => [...prevMsgs, {id:prevMsgs.length, type:0, content: query}]);
        let newQueryText = query;
        setQueryText(newQueryText)
        getCandidates(chatid, newQueryText)
        setQueryInput('')
        setFinal(false)
        setScenarioEditable(false)
    }

    const handleChange = e=>{
        setQueryInput(e.target.value);
    }

    const completeQuery = e =>{
        if (queryInput === '') return;
        inputQuery(id, queryInput.trim())
    }

    const ComputerThinking = (props) => {
        return (
            <ListItem key="-1" ref={props.obj?props.obj:null} style={{padding:'0 0 0 0'}}>
                <Card sx={{ maxWidth: '80%' }} style={{boxShadow: '0px 0px 0px 0px', padding:'0 0 0 0'}}>
                    <CardContent  style={{padding:'10 0 0 0'}}>
                        <Box sx={{ alignItems: 'top', display: 'flex', flexDirection: 'row' }}>
                            <Box sx={{ mr:1, position: 'relative' }}>
                                <Fab
                                size="small"
                                aria-label="save"
                                color="primary"
                                >
                                    <ComputerIcon />
                                </Fab>
                                
                                <CircularProgress
                                    size={48}
                                    sx={{
                                    color: green[500],
                                    position: 'absolute',
                                    top: -4,
                                    left: -4,
                                    zIndex: 1,
                                    }}
                                />
                                
                            </Box>
                            <Box>
                                <Typography>
                                    {
                                        props.text
                                    }
                                </Typography>
                                
                            </Box>

                        </Box>
                    </CardContent>
                </Card>
            </ListItem>
            
        )
    }

    const ComputerMessage = (props) => {
        return (
            <ListItem key={props.index} ref={props.obj?props.obj:null} style={{padding:'0 0 0 0'}}>
                <Card sx={{ maxWidth: '80%' }} style={{boxShadow: '0px 0px 0px 0px', padding:'0 0 0 0'}}>
                    <CardContent  style={{padding:'10 0 0 0'}}>
                        <Box sx={{ alignItems: 'top', display: 'flex', flexDirection: 'row', overflowWrap: 'anywhere' }}>
                          <Box sx={{mr:1}}>
                            <Avatar size="small" sx={{ bgcolor: green[500] }} aria-label="recipe">
                                <ComputerIcon/>
                            </Avatar>
                          </Box>
                          <Box>
                            <Box>
                                {props.content.split('\n').map((line,line_index)=>{
                                    let regexp = new RegExp('https?://[-a-zA-Z0-9@:%.+~#=]{1,256}.[a-zA-Z0-9]{1,6}[-a-zA-Z0-9@:%+.~#?&//=_]*', 'g')
                                    const matches = line.matchAll(regexp);
                                    let conts = []
                                    let start = 0
                                    
                                    for (const match of matches) {
                                        let ss = match.index
                                        let ee = ss + match[0].length
                                        if (start !== ss) {
                                            conts.push([line.slice(start, ss), 0])
                                        }
                                        conts.push([match[0], 1])
                                        start = ee
                                    }
                                    if (start !== line.length) {
                                        conts.push([line.slice(start, line.length), 0])
                                    }
                                    
                                    return (
                                        <Box key={line_index}>
                                            { conts.map((ci, cindex)=>{
                                                let cont_str = ci[0]
                                                let cont_type = ci[1]
                                                if (cont_type == 0) {
                                                    return (
                                                        <Typography>
                                                            {
                                                                cont_str
                                                            }
                                                        </Typography>
                                                    )                                                            
                                                } else {
                                                    return (
                                                        <a key={cindex} href={cont_str} target="view_window">
                                                            {
                                                                cont_str
                                                            }
                                                        </a>
                                                    )
                                                }

                                            })}
                                            
                                
                                        </Box>
                                    )
                                })}
                            </Box>
                            <Box>
                                {
                                    props.meta &&
                                    <CommentButton
                                        openComment={props.openComment}
                                        OpenComment={props.OpenComment}
                                        warning={props.warning}
                                        setWarning={props.setWarning}
                                        saveResult={props.saveResult}
                                        setSaveResult={props.setSaveResult}
                                        index={props.index}
                                        chatid={props.chatid}
                                        answer={props.content}
                                        commenter={props.commenter}
                                        changeCommenter={props.changeCommenter}
                                        {...props.meta}
                                        SaveComment={props.SaveComment}
                                    />
                                }
                            </Box>
                          </Box>

                        </Box>
                    </CardContent>
                </Card>
            </ListItem>
        )
    }

    const HumanMessage = (props) => {
      //console.log(props);
      return (
          <ListItem key={props.index} sx={{justifyContent:'right'}} ref={props.obj?props.obj:null} style={{padding:'0 0 0 0'}}>
              <Card sx={{ maxWidth: '80%'}} style={{boxShadow: '0px 0px 0px 0px', padding:'0 0 0 0'}}>
                  <CardContent  style={{padding:'0 20 0 0'}} >
                        <Box sx={{ alignItems: 'top', display: 'flex', flexDirection: 'row', overflowWrap: 'anywhere' }}>
                            <Box sx={{mr:1}}>
                                {props.content.split('\n').map((line,line_index)=>(
                                    <Typography key={line_index} variant="body2" color="text.secondary">
                                        {line}
                                    </Typography>
                                ))}
                                
                            </Box>
                            <Box>
                                <Avatar sx={{ bgcolor: orange[500] }} aria-label="recipe">
                                    <PersonIcon/>
                                </Avatar>
                            </Box>
                        </Box>
                      
                  </CardContent>
              </Card>
          </ListItem>
      )
    }

    useEffect(() => {
        if (lastElement === null) {
            return;
        }
        if (lastElement && lastElement.current) {
            lastElement.current.scrollIntoView(false);
        }

    });

    const process_table = (text) => {
        let lines = text.split('\n')
        lines = lines.map(e=>e.split('\t'))
        let i = 0
        while (i < lines.length) {
            let ll = lines[i]
            
            if (ll.length === 1) {
                lines[i] = lines[i][0]
                i+=1
                continue
            }
            let j = i + 1
            while (j < lines.length) {
                if (lines[j].length !== ll.length) break
                j+=1
            }
            if (j > i+1) {
                let cnt = ll.length
                lines[i] = '| ' + lines[i].join(' | ') + ' |\n'
                lines[i] += '| ' + '--- | '.repeat(cnt)
                for (i=i+1; i < j ; i++) {
                    lines[i] = '| ' + lines[i].join(' | ') + ' |'
                }
                continue
            } else {
                lines[i] = lines[i].join('\t')
                i+=1
                continue
            }
        }
        return lines.join('\n')
    }

    const handleScenarioChange = evt=>{
        let text = evt.target.value
        text = process_table(text)
        setScenario(text)
    }

    const handleRestart = evt=>{
        setQueryText("");
        setChatContent([init_prompt]);
        setContent('');
        setReference('');
        setID('');
        setScenario("");
        setScenarioEditable(true);
    }

    return (
        <Container sx={{ mt: 4, mb: 4, display:props.show?'block':'none', textAlign: "center"}}>
            <Grid container spacing={1}>
            <Grid item xs={12}>
                <TaxGeniiLogo/>
                <Button href="tax-genii-api" target="_blank">API</Button>
                <Button href="tax-genii-history" target="_blank">History</Button>
                <Button href="https://www.youtube.com/embed/2Pvsc3OOV5I" target="_blank">Example</Button>
            </Grid>
            <Grid item xs={12}>
                <Collapse in={openNotice1}>
                    <Alert severity="info" onClose={() => {setOpenNotice1(false)}}>
                        NOTE: Please be patient and note that there could be a time-lag of up to a number of minutes to produce a result.
                    </Alert>
                </Collapse>
                { false && 
                    <Collapse in={openNotice2}>
                        <Alert severity="info" onClose={() => {setOpenNotice2(false)}}>
                            NOTE: The model could fail to produce a result if time exceeds 10 min.
                        </Alert>
                    </Collapse>
                }
                
            </Grid>
            <Grid item xs={12}>
                    <Stack direction="row" spacing={2} sx={{display:'flex'}}>
                        <Paper
                        component="form"
                        min-width="80%"
                        sx={{ flex:1, p: '2px 4px', display: 'flex', alignItems: 'center', borderRadius: 2 }}
                        >
                            <InputBase
                                sx={{ ml: 1, flex: 1 }}
                                placeholder="Input a scenario if you need."
                                onChange={handleScenarioChange}
                                multiline
                                maxRows={4}
                                minRows={4}
                                value={scenario}
                                disabled={!scenarioEditable}
                            />
                            <Divider orientation="vertical" flexItem/>
                            <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={handleRestart}>
                                <RestartAltIcon />
                            </IconButton>
                            
                        </Paper>
                    </Stack>
                    
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="row" spacing={2} sx={{display:'flex'}}>
                        <Paper
                        component="form"
                        min-width="80%"
                        sx={{ flex:1, p: '2px 4px', display: 'flex', alignItems: 'center', borderRadius: 2 }}
                        >
                            <InputBase
                                sx={{ ml: 1, flex: 1 }}
                                placeholder="Input a question or some keys. Press CTRL+Enter to send message."
                                onChange={handleChange}
                                multiline
                                maxRows={4}
                                onKeyDown={e=>{
                                    if (e.key === 'Enter' && e.ctrlKey) {
                                        e.preventDefault();
                                        completeQuery(e);
                                    }
                                }}
                                value={queryInput}
                                disabled={isListening}
                            />
                            {isListening?<CircularProgress size={20} sx={{mr:2}}/>:null}
                            <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={completeQuery}>
                                <SendIcon color="primary"/>
                            </IconButton>                            
                        </Paper>
                    </Stack>
                    
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        <Box style={{border: '1px solid #2e7d32a6', padding:'0 0 0 0'}} sx={{ mb: 3, height: '400px', overflowY:'scroll'}}>
                        <List sx={{width:'100%'}} >
                            {
                                chatContent.map((e, index)=>{
                                  if (e.type === 1) {
                                    let m = undefined
                                    if (chatContent.length - index < 3) {
                                        m = e.meta
                                    }
                                    return (
                                        <ComputerMessage
                                            openComment={openComment}
                                            OpenComment={setOpenComment}
                                            warning={warning}
                                            setWarning={setWarning}
                                            saveResult={saveResult}
                                            setSaveResult={setSaveResult}
                                            key={index} 
                                            index={index} 
                                            id={e.id}
                                            chatid={e.chatid}
                                            content={e.content} 
                                            meta={m}
                                            commenter={state.commenter}
                                            changeCommenter={handleChangeCommenter}
                                            obj={!isListening && index === chatContent.length-1?lastElement:null}
                                            SaveComment={handleSaveComment}/>
                                        )
                                  }
                                  return (
                                    <HumanMessage 
                                        key={index} 
                                        index={index} 
                                        content={e.content} 
                                        obj={!isListening && index === chatContent.length-1?lastElement:null}/>
                                    )
                                })
                            }
                            {
                                isListening && <ComputerThinking 
                                    text={thinking===""?"I am thinking, please wait for a few seconds.":thinking}
                                    obj={isListening?lastElement:null}
                                />
                            }
                        </List>
                        
                    </Box>
                    </Paper>
                    
                </Grid>
                
            </Grid>
            
        </Container>

    );

}
