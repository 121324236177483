// let token_host = 'bsearchau.accziom.com'
// let token_host = 'localhost'
let token_host = 'backend.bsearchau.accziom.com'

// testversion
/*
module.exports = {
    token_host: token_host,
    token_server_url: `https://${token_host}:8986`,
    token_wss_server_url: `wss://${token_host}:8987`,
}
*/

module.exports = {
    token_host: token_host,
    backend_url : "https://backend.bsearchau.accziom.com:8002/",
    token_server_url: `https://${token_host}:8885`,
    // token_server_url: `https://${token_host}:8885`,
    token_wss_server_url: `wss://${token_host}:8887`,
    token_admin_server_url: `https://${token_host}:8884`,

}
