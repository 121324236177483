import { Alert, AlertTitle, Button, CircularProgress, Grid, IconButton, Paper, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { BSEARCH_URI } from "../common/config";
import { useGlobalState } from "../common/globalState";
import { personal_sign } from "../common/metamask";

import { Box } from "@mui/system";
import { useWeb3React } from "@web3-react/core";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { CreateHeader, setGlobalVal } from "./common-tool";
import Title from "./Title";
import CloseIcon from '@mui/icons-material/Close';

async function request(resolve, address, fee, reason) {
    axios.post(
        BSEARCH_URI + "request",
        {
            address: address,
            fee: fee,
            reason: reason,
        }
    )
        .then(response => {
            resolve(response.data);
        })
}

export async function search_rdf_from_uri(query, address, fee, callback_fn) {
    var params = {}
    var reason = `RDF URI Query=${query}`;
    params.query = query;
    params.address = `${address}`;
    params.free = "true";
    if (fee > 0) {
        params.free = "false"
    }

    if (fee > 0) {
        var seed = await new Promise(resolve => request(resolve, params.address, fee, reason));

        if (seed === '') {
            params.free = "true";
        }
        else {
            let vk = await personal_sign(seed, address);
            params.verification = vk;
            params.seed = seed;
        }
    }

    try {
        axios.post(
            BSEARCH_URI + "uri_query", params
        )
            .then(response => {
                if (response && response.data) {
                    var result = response.data;

                    if (!result || Object.keys(result).length === 0) {
                        result = { 'error': "The data does not exist in ACCZIOM database." };
                    }

                    callback_fn(result);
                }

            })
    }
    catch (err) {
        var result = { 'error': err };
        callback_fn(result);
    }
}

export function RDFDetails(props) {
    const [state, dispatch] = useGlobalState();
    const [onSpin, setSpin] = useState(false);
    const [content, setContent] = useState({});
    const [query, setQuery] = useState("");
    const [fee, setFee] = useState(0);
    const [payFee, setPayFee] = useState(0);
    const [mounted, setMounted] = useState(false);
    //const [isDisabled, setDisabled] = useState(true);
    const context = useWeb3React()
    const { connector, chainId, activate, deactivate, error } = context
    let isDisabled = error || !chainId

    const handlePay = (e) => {
        setPayFee(state.predictedFee);
    }

    const clickHyperLink = (e) => {
        let attr = e.target.attributes;
        dispatch({
            subTitle: attr.metalabel.nodeValue,
            subURI: attr.metauri.nodeValue,
            openDetails: true,
        })
    };

    useEffect(() => {
        var q = "";
        if (state.subURI !== "") {
            q = state.subURI
        }

        if (q === "") {
            setContent([]);
            setSpin(false);
            return;
        }

        var f = payFee || 0;
        if (query !== q) setQuery(q);
        if (f !== fee) setFee(f);

    }, [state.subURI, payFee])

    useEffect(() => {
        if (query === "") return;
        if (query === state.subRdfHistory.query && state.subRdfHistory.fee >= fee) {
            setContent(state.subRdfHistory.result);
            setSpin(false);
            if (state.subRdfHistory.fee > 0) {
                setMounted(true);
            }
            return;
        }

        setSpin(true);

        var address = `${context.account}`
        search_rdf_from_uri(query, address, fee, result => {
            var f = result.predicted_fee || 0;
            dispatch({
                subRdfHistory: { query: query, fee: fee, result: result },
                predictedFee: f
            })
            delete result.predicted_fee
            var key_list = Object.keys(result)
            if (key_list.length === 0) {
                result.error = 'The data does not exist in Accziom Database.'
            }
            else {
                key_list.forEach(e => {
                    if (e !== "##order##") {
                        var props = result[e];
                        Object.keys(props).forEach(k => {
                            var val = props[k];
                            if (Array.isArray(val)) val = val[0];
                            setGlobalVal(k, val, state, dispatch);
                        })
                    }
                })
            }
            setContent(result);
            if (fee > 0) {
                setMounted(true);
            }
            setSpin(false);
        })

    }, [query, fee])

    if (onSpin) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <CircularProgress size={50} />
            </Box>
        )
    } else {

        var ord = content['##order##'];
        return (
            <div>

                <Grid container spacing={2}>
                    <Grid container>
                        <Grid item xs={11}>
                            <Title>
                                {state.subTitle}
                            </Title>
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton
                                aria-label="close"
                                onClick={props.onClose}
                                sx={{
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>


                    <Grid item xs={9}>
                        <Typography sx={{ pl: 4, pr: 2, textAlign: "left" }}>
                            This pane displays the information of a RDF URI of Accziom Ontology.
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Box textAlign={'right'}>

                            <Button disabled={isDisabled || mounted || state.predictedFee === 0} variant="contained" color="success" onClick={handlePay} sx={{ borderRadius: 10, height: 40 }} >
                                <LockOpenIcon sx={{ mr: 2 }} />
                                Pay {state.predictedFee.toLocaleString('en-US')} MERc
                            </Button>

                        </Box>

                    </Grid>

                    <Grid item xs={12}>
                        {
                            content.error ?
                                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                    <Alert severity="info">
                                        <AlertTitle>Info</AlertTitle>
                                        {content.error}
                                    </Alert>
                                </Paper>
                                :
                                CreateHeader(content, ord, 1, true, {
                                    hyperlink: clickHyperLink
                                })
                        }

                    </Grid>
                </Grid>
            </div>

        )
    }
}