import {
  createTheme,
  InputAdornment,
  TextField,
  ThemeProvider,
} from "@mui/material";
import { purple } from "@mui/material/colors";
import React, { useState } from "react";
import { useGlobalState } from "../common/globalState";

const theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: purple[500],
    },
    secondary: {
      // This is green.A700 as hex.
      main: "#11cb5f",
    },
  },
});

const dialog_text_style = {
  "& .MuiInputBase-input": {
    color: "white", // Text color
  },
  "& .MuiInput-input": {
    width: "260px", // Text color
  },
  "& .MuiInputLabel-root": {
    color: "white", // Text color
  },
  "& .MuiInput-underline:before": {
    borderBottomColor: "white", // Semi-transparent underline
  },
  "& .MuiInput-underline:hover:before": {
    borderBottomColor: "white", // Solid underline on hover
  },
  "& .MuiInput-underline:hover:after": {
    borderBottomColor: "white", // Solid underline on hover
  },
  ".Mui-error": {
    color: "white !important",
  },
  ".Mui-error:after, .Mui-error:before": {
    borderBottomColor: "white !important",
  },
};

const dialog_text1_style = {
  "& .MuiInputBase-input": {
    color: "white", // Text color
  },
  "& .MuiInputLabel-root": {
    color: "white", // Text color
  },
  "& .MuiInput-underline:before": {
    borderBottomColor: "white", // Semi-transparent underline
  },
  "& .MuiInput-underline:hover:before": {
    borderBottomColor: "white", // Solid underline on hover
  },
  "& .MuiInput-underline:hover:after": {
    borderBottomColor: "white", // Solid underline on hover
  },
  ".MuiFormHelperText-root": {
    color: "rgba(255, 255, 255, 0.4)",
  },
};

const dialog_text2_style = {};

const unit_style = {
  "& .MuiTypography-root": {
    color: "#d8aa0cb8", // Text color
    fontSize: "10px",
  },
  container: {
    alignItems: "baseline",
  },
};

export function AmountTextField(props) {
  const [state, dispatch] = useGlobalState();
  const [err, setError] = useState({ error: true, helperText: "Required" });

  return (
    <ThemeProvider theme={theme}>
      <TextField
        {...err}
        required
        sx={dialog_text_style}
        onChange={(newValue) => {
          var amount = newValue.target.value.trim();
          dispatch({ amount: amount });
          if (isNaN(amount) || amount === "") {
            dispatch({ inputAmountError: true });
            setError({ error: true, helperText: "Required Number" });
            return;
          }
          if (props.max && parseInt(amount) > parseInt(props.max)) {
            dispatch({ inputAmountError: true });
            setError({
              error: true,
              helperText: `Amount should be at most ${props.max}`,
            });
            return;
          }
          if (props.min && parseInt(amount) < parseInt(props.min)) {
            dispatch({ inputAmountError: true });
            setError({
              error: true,
              helperText: `Amount should be at least ${props.min}`,
            });
            return;
          }
          dispatch({ inputAmountError: false });
          setError({});
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment sx={unit_style} position="end">
              MERc
            </InputAdornment>
          ),
        }}
        variant="standard"
        id="merc-amount"
        label="Amount"
        value={state.amount}
      />
    </ThemeProvider>
  );
}

export function MERcTextField(props) {
  const [err, setError] = useState(
    props.disabled || props.value != ""
      ? {}
      : { error: true, helperText: "Required" }
  );

  return (
    <ThemeProvider theme={theme}>
      <TextField
        {...err}
        {...props.extra}
        required
        disabled={props.disabled}
        sx={props.disabled ? dialog_text2_style : dialog_text1_style}
        onChange={(newValue) => {
          var amount = newValue.target.value.trim();
          props.setValue(amount);
          if (isNaN(amount) || amount === "") {
            if (props.setError) props.setError(true);
            setError({ error: true, helperText: "Required Number" });
            return;
          }
          if (props.max && parseInt(amount) > parseInt(props.max)) {
            if (props.setError) props.setError(true);
            setError({
              error: true,
              helperText: `Amount should be at most ${props.max}`,
            });
            return;
          }
          if (props.min && parseInt(amount) < parseInt(props.min)) {
            if (props.setError) props.setError(true);
            setError({
              error: true,
              helperText: `Amount should be at least ${props.min}`,
            });
            return;
          }

          setError({});
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment sx={unit_style} position="end">
              {props.unit ? props.unit : "MERc"}
            </InputAdornment>
          ),
        }}
        variant="standard"
        id="merc-amount"
        label={props.label}
        value={props.value}
      />
    </ThemeProvider>
  );
}
