import { Alert, AlertTitle, Button, CircularProgress, Grid, Paper, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { BSEARCH_URI } from "../common/config";
import { useGlobalState } from "../common/globalState";
import { personal_sign } from "../common/metamask";

import { Box } from "@mui/system";
import { useWeb3React } from "@web3-react/core";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { CreateHeader, CreateProperty, setGlobalVal } from "./common-tool";


async function request(resolve, address, fee, reason) {
    axios.post(
        BSEARCH_URI + "request",
        {
            address: address,
            fee: fee,
            reason: reason,
        }
    )
        .then(response => {
            resolve(response.data);
        })
}

async function search_abn(query, callback_fn) {
    try {
        //alert(JSON.stringify(params));
        axios.post(
            BSEARCH_URI + "abn_query", { "query": query }
        )
            .then(response => {
                if (response && response.data) {
                    var result = response.data;

                    if (!result || Object.keys(result).length === 0) {
                        result = { 'error': "The data does not exist in ABR database that we collected." };
                    }


                    callback_fn(result);
                }
                else {
                    callback_fn({});
                }

            })
    }
    catch (err) {
        var result = { 'error': err };
        callback_fn(result);
    }
}


export function ABNView(props) {
    const [state, dispatch] = useGlobalState();
    const [onSpin, setSpin] = useState(false);
    const [content, setContent] = useState({});
    const [query, setQuery] = useState("");
    
    useEffect(() => {
        var q = "";
        if (state.entityID.length === 11) {
            q = state.entityID
        }
        if (state.abn !== "") {
            q = state.abn
        }

        if (q === "") {
            setContent([]);
            setSpin(false);
            return;
        }
        if (q === query) {
            setSpin(false);
            return;
        }

        setQuery(q);

    }, [state.entityID, state.abn, query, dispatch])

    useEffect(() => {
        if (query === "") return;
        if (query === state.abnHistory.query) {
            setContent(state.abnHistory.result)
            setSpin(false);
            return;
        }
        setSpin(true);

        search_abn(query, result => {
            let dispatch_value = {
                abn: query,
                abnHistory: { query: query, result: result }
            };
            console.log(state.rdfHistory.result);
            if (state.rdfHistory.result && state.rdfHistory.result.error) {
                console.log('rdf refresh')
                dispatch_value.rdfHistory = { query: "", result: {} }
            }
            dispatch(dispatch_value)
            var props = result;
            Object.keys(props).forEach(k => {
                var val = props[k];
                if (Array.isArray(val)) val = val[0];
                console.log(k, val)
                setGlobalVal(k, val, state, dispatch);
            })
            setContent(result);
            setSpin(false);
        })
    }, [query])

    if (onSpin) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <CircularProgress size={50} />
            </Box>
        )
    } else {
        return (
            <div>

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant='h5'>
                            ABR Search Result
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography sx={{ pl: 4, pr: 2, textAlign: 'left' }}>
                            This pane displays the information of legal entity from Australian Business Register(ABR) public data. It uses ABN lookup api.
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Paper sx={{ p: 4 }}>
                            {
                                content.error ?
                                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                        <Alert severity="info">
                                            <AlertTitle>Info</AlertTitle>
                                            {content.error}
                                        </Alert>
                                    </Paper>
                                    :
                                    CreateProperty('', content, null)
                            }

                        </Paper>
                    </Grid>
                </Grid>

            </div>

        )
    }
}