import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, CircularProgress, FormControlLabel, Grid, IconButton, InputBase, Paper, Stack, Typography } from "@mui/material";
import { Container } from "@mui/system";
import SearchIcon from '@mui/icons-material/Search';
import React, { useState } from "react";
import axios from "axios";
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TreeView from '@mui/lab/TreeView';
import TreeItem, { treeItemClasses } from '@mui/lab/TreeItem';
import MarkdownPreview from '@uiw/react-markdown-preview';

import MailIcon from '@mui/icons-material/Mail';
import DeleteIcon from '@mui/icons-material/Delete';
import Label from '@mui/icons-material/Label';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import InfoIcon from '@mui/icons-material/Info';
import ForumIcon from '@mui/icons-material/Forum';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { OpenInNew } from "@mui/icons-material";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { CurtainsClosedTwoTone, SelectAllRounded } from "@mui/icons-material";
import { parse_html_tag } from "../common/utility";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AZURE_SEARCH_URI, BSEARCH_URI } from "../common/config";
import { AccordionActions } from "@material-ui/core";
  
export function LegalSearch(props) {
    const [content, setContent] = useState(null);
    const [queryText, setQueryText] = useState("");
    const [isWorking, setWorking] = useState(false);
    const [selectedField, setSelectedField] = useState([]);
    const [searchResult, setSearchResult] = useState({});
    const [categoryList, setCategoryList] = useState([]);
    const [isMoreWorking, setMoreWorking] = useState({});
    const [categoryToTitle, setCategoryToTitle] = useState({})
    const [filterList, setFilterList] = useState({})
    const [keyword, setKeyword] = useState("");
    const [expandedStatus, setExpandedStatus] = useState([false, false, false, false, false, false, false, false, false, false, false, false]);

    const categories = [
      "ATO general website",
      "Legislation and supporting material",
      "Public rulings",
      "Practical compliance guidelines",
      "Cases",
      "Decision impact statements",
      "ATO interpretative decisions",
      "Law administration practice statements",
      "Taxpayer alerts",
      // "SMSF Regulator's Bulletins",
      "Other ATO documents",
      "ATO law aids"
    ]

    const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
        color: theme.palette.text.secondary,
        [`& .${treeItemClasses.content}`]: {
          color: theme.palette.text.secondary,
          borderTopRightRadius: theme.spacing(2),
          borderBottomRightRadius: theme.spacing(2),
          paddingRight: theme.spacing(1),
          fontWeight: theme.typography.fontWeightMedium,
          '&.Mui-expanded': {
            fontWeight: theme.typography.fontWeightRegular,
          },
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
          '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
            backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
            color: 'var(--tree-view-color)',
          },
          [`& .${treeItemClasses.label}`]: {
            fontWeight: 'inherit',
            color: 'inherit',
          },
        },
        [`& .${treeItemClasses.group}`]: {
          marginLeft: 10,
          [`& .${treeItemClasses.content}`]: {
            paddingLeft: theme.spacing(2),
          },
          borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
        },
    }));
      
    const selectNode = (evt, nodeId) => {
        if (evt.target.checked) {
            setSelectedField((oldSelected)=>[...oldSelected, nodeId]);
        } else {
            setSelectedField((oldSelected)=>{
                let newSelected = oldSelected.filter(v=>v!=nodeId);
                return newSelected
            })
        }
    }

    const StyledTreeItem = (props) => {
        const {
          nodeId,
          bgColor,
          color,
          labelInfo,
          labelText,
          ...other
        } = props;
      
        return (
          <StyledTreeItemRoot
            nodeId={nodeId}
            label={
              <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5, pr: 0 }}>
                <FormControlLabel control={<Checkbox checked={selectedField.includes(nodeId,0)} onChange={evt=>selectNode(evt, nodeId)}/>} label={labelText} />
                <Typography variant="caption" color="inherit">
                  {labelInfo}
                </Typography>
              </Box>
            }
            style={{
              '--tree-view-color': color,
              '--tree-view-bg-color': bgColor,
            }}
            {...other}
          />
        );
    };
    
    StyledTreeItem.propTypes = {
        nodeId: PropTypes.string,
        bgColor: PropTypes.string,
        color: PropTypes.string,
        labelInfo: PropTypes.string,
        labelText: PropTypes.string.isRequired,
    };
    
    const handleChange = e=>{
        setQueryText(e.target.value);
    }

    const clearFields = ct => {
      setSelectedField([]);
      setFilterList({})
      showAllResult()
    }

    const showAllResult = () => {
      let titleList = Object.keys(searchResult)
      titleList.map(t=>{
        let sr = searchResult[t]
        sr.show = true
        setSearchResult({
          ...searchResult,
          [t]: sr
        });
        return 0
      })
    }

    const isEqualSet = (a, b) => {
      if (!a || !b) return false;
      if (a.length !== b.length) return false;
      let sa = a.sort();
      let sb = b.sort();
      for (var i = 0 ; i < a.length; i++) {
        if (sa[i] != sb[i]) return false;
      }
      return true;
    }

    const hideAllResult = () => {
      let titleList = Object.keys(searchResult)
      titleList.map(t=>{
        let sr = searchResult[t]
        sr.show = false
        setSearchResult({
          ...searchResult,
          [t]: sr
        });
        return 0
      })
    }
    const filterFields = async ct => {
      let fl = {}
      selectedField.map(e=>{
        let [t, svalue] = categoryToTitle[e];
        if (t) {
          if (fl[t] === undefined) {
            fl[t] = []
          }
          fl[t].push(svalue);
        }
        return 0
      })
      
      let titleList = Object.keys(fl);
      hideAllResult()
      
      for (var i = 0 ; i < titleList.length; i++) {
        let t = titleList[i];
        if (isEqualSet(fl[t], filterList[t])) {
          let sr = searchResult[t]
          sr.show = true
          setSearchResult({
            ...searchResult,
            [t]: sr
          });
            
          continue;
        }
        setMoreWorking({
          [t]: true
        })
        let html = await getSearchResultPerDomain(t, fl[t], 1);
        var patt=/<ol [^<]* total="(.*)">/g;
        var match=patt.exec(html);
        let sr = {}
        sr.content = [html]
        sr.svalue = fl[t]
        sr.number = match?match[1]:0
        sr.show = true
        setSearchResult({
          ...searchResult,
          [t]: sr
        });
        setMoreWorking({});
      }

      setFilterList(fl)
    }

    const setCategoryMap = data => {
      let item_stack = data.map((e,index)=>[e, `${index+1}`, e.title, e.svalue])
      let item_stack_len = item_stack.length;
      let result = {}
      for (var i = 0 ; i < item_stack_len; i++) {
        let [item, nodeId, title, svalue] = item_stack[i];
        result[nodeId] = [title, svalue]
        if (item.child) {
          for (var j = 0 ; j < item.child.length; j++) {
            let child_item = item.child[j];
            item_stack.push([child_item, `${nodeId}.${j+1}`, title, child_item.svalue])
          }
          item_stack_len += item.child.length
        }
      }
      console.log(result);
      setCategoryToTitle(result);
    }

    const changeCategory = ct => {
      setSelectedField([]);
      setFilterList({})
      setCategoryToTitle({})

      let procdata = ct.slice(1, ct.length);
      let [data, _] = getHierachicalData(procdata, 0, 2);
      setCategoryMap(data);
      setCategoryList(data);
    }

    const getSearchResultPerDomain = async (title, svalue_list, start_index) => {
      let curDate = new Date();
      curDate = curDate.toLocaleDateString('en-au', {year: 'numeric', month: '2-digit', day: '2-digit'});
      let response = await axios.post(BSEARCH_URI+"legal_db", {
        tm_phrase: queryText,
        stype: 'find',
        pageSize: 10,
        start: start_index,
        src: 'rs',
        pit: curDate,
        df: svalue_list
      })
      let result_html = response.data.result;
      let result = parse_html_tag(result_html, '<div id="searchResult">', '<div id="filters2">', false)
      if (result === null) return null;
      
      let html_txt = result.content;
      html_txt = html_txt.replace('<ol start="1"', `<ol start="${start_index}"`)
      html_txt = html_txt.replace(/href="\/law/g, 'target="_blank" href="https://www.ato.gov.au/law')
      return '<div>' + html_txt;
    }

    const appendData = async (title, svalue_list, start_index) => {
      setMoreWorking({
        [title]: true
      })
      let html = await getSearchResultPerDomain(title, svalue_list, start_index);
      let sr = searchResult[title]
      sr.content = [...sr.content, html]
      setSearchResult({
        ...searchResult,
        [title]: sr
      });
      setMoreWorking({});
    }

    const resetQuery = async () => {
      for (var i = 0; i < categories.length; i++) {
        await completeQuery(categories[i]);
      }
    }

    const completeQuery = async (category) => {
      setWorking(true);
      var start = 0;
      if (searchResult[category] !== undefined) start = searchResult[category].length;

      axios({
        method: 'post',
        url: AZURE_SEARCH_URI + 'api/search-legal-database', 
        data: {
        tm_phrase: queryText,
        pageSize: 10,
        start: start,
        category: category
        }
      }).then(async response=>{
        let result = response.data;

        var sr = searchResult[category];

        if (sr == undefined) sr = [];
        for( var i = 0; i < result.length; i++) {
          sr.push(result[i]);
        }

        setSearchResult((prev) => {
          return {
            ...prev,
            [category]: sr
          }
        })
        setWorking(false);
        setMoreWorking({[category]: false});
        // var tmp = expandedStatus;
        // tmp[key] = true;
        // setExpandedStatus(tmp);
        // setWorking(false);
      })  
    }

    const getSummary = async (u, category, index) => {
      if (searchResult[category][index]['summarization'] != undefined) return;
      if (searchResult[category][index]['summarizing'] == true) return;
      var sr = searchResult[category];
      sr[index]["summarizing"] = true;

      setSearchResult((prev) => {
        return {
          ...prev,
          [category]: sr
        }
      })

      axios({
        method: 'post',
        url: AZURE_SEARCH_URI + 'api/get-le-summary', 
        data: {
          url: u
        }
      }).then(async response=>{
        let result = response.data['summarization'];

        
        sr[index]['summarization'] = result;
        sr[index]['summarizing'] = false;

        setSearchResult((prev) => {
          return {
            ...prev,
            [category]: sr
          }
        })
        // var tmp = expandedStatus;
        // tmp[key] = true;
        // setExpandedStatus(tmp);
        // setWorking(false);
      })  
    }

    const getHierachicalData = (data, start_index, cur_level) => {
      if (data.length === 0) return [null, 0];
      let res = [];
      let ind = start_index;
      let last_data = null;
      while ( ind < data.length) {
        let d = data[ind];
        let lv = 0;
        if (d.chapter) {
          lv = d.chapter.split('.').length;
        }
        //console.log(lv);
        if (lv === 0) {
          ind += 1;
          continue;
        }
        if (cur_level === lv) {
          res.push(d)
          last_data = d;
          ind += 1
          continue;
        }
        if (cur_level > lv) {
          //console.log(res);
          return [res, ind];
        }
        let [child, count] = getHierachicalData(data, ind, lv);
        if (child === null) {
          return [res, ind];
        }
        if (last_data !== null) {
          res[res.length-1].child = child;
        }
        ind = count;
      }
      //console.log(res);
      return [res, ind];
    }

    const CategoryTreeItem = (item, index) => {
      return (
        <StyledTreeItem key={index} nodeId={index} labelText={item.title} labelInfo={item.number}>
          {
            item.child?
            item.child.map((e,i)=>CategoryTreeItem(e, `${index}.${i+1}`)):
            null
          }
        </StyledTreeItem>
      )
                        
    }

    const CategoryTreeView = (cat_list) => {
      let data = cat_list;
      return null;
      if (data === null || data.length === 0) {
        return null;
      }
      let expandSet = [];
      // for (var i = 0 ; i < data.length; i++) {
      //   if (data[i].child) {
      //     expandSet.push(`${i+1}`)
      //   }
      // }

      return (
        <Grid container spacing={1} width="30%" sx={{mt:8, mr:6}}>
          <Grid item xs={12}>
            <Button onClick={filterFields}>Filter</Button>
            <Button onClick={clearFields}>Clear</Button>
          </Grid>
          <Grid item xs={12}>
            <TreeView
              aria-label="options"
              defaultExpanded={expandSet}
              defaultCollapseIcon={<ArrowDropDownIcon />}
              defaultExpandIcon={<ArrowRightIcon />}
              defaultEndIcon={<div style={{ width: 124 }} />}
              sx={{ textAlign:"left", flexGrow: 1, maxWidth: 500, overflowY: 'auto' }}
              selected={selectedField}
              multiSelect
            >
              {
                data.map((e,index)=>CategoryTreeItem(e, `${index+1}`))
              }
            </TreeView>
          </Grid>
          <Grid item xs={12}>
            <Button onClick={filterFields}>Filter</Button>
            <Button onClick={clearFields}>Clear</Button>
          </Grid>
      </Grid>
        
      )

    }

    return (
        <Container sx={{ mt: 4, mb: 4, display:props.show?'block':'none' }}>
            <Stack direction="row" spacing={4} sx={{display:'flex'}} justifyContent="flex-start"  alignItems="flex-start">
                {
                  CategoryTreeView(categoryList)
                }
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Paper
                        component="form"
                        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', borderRadius: 10 }}
                        >
                        <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={() => {
                          setSearchResult({});
                          resetQuery();
                        }}>
                            <SearchIcon />
                        </IconButton>
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="Please input keywords."
                            onChange={handleChange}
                            onKeyPress={e=>{
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                setSearchResult({});
                                resetQuery();
                            }
                            }}
                            value={queryText}
                        />
                        {isWorking?<CircularProgress size={20} sx={{mr:2}}/>:<div></div>}
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sx={{textAlign:'left'}}>
                        <div >
                            {
                              Object.keys(categories).map((key, index)=>{
                                return (
                                <Accordion key={index}>
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                  >
                                    <Typography variant="h6">{`${categories[key]} (${searchResult[categories[key]] === undefined ? 0 : searchResult[categories[key]].length})`}</Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    {
                                      searchResult[categories[key]] ? searchResult[categories[key]].map((cont, index)=>(
                                        <div style={{'paddingLeft': '10px', 'marginBottom': '10px'}} key={index}>
                                          <Typography variant="body1">{index+1}. <Typography variant="body1" color="blue" fontWeightMedium sx={{"textDecoration": 'underline', 'cursor': 'pointer'}} display="inline" onClick={()=>getSummary(cont["url"], categories[key], index)}>{cont["title"]}</Typography><a href={'https://ato.gov.au' + cont["url"]} target='_blank'>    
                             <OpenInNew size="15px" style={{'paddingTop': '10px'}} />
                            </a></Typography>
                                          <Typography sx={{'paddingLeft': '20px'}} variant="body2">{cont["hierrachy"]}</Typography>
                                          {cont["summarization"] != undefined ?
                                            <MarkdownPreview
                                            style={{
                                              'paddingTop': '15px',
                                              'paddingLeft': '30px'
                                            }}
                                            source={cont["summarization"]}
                                            >
                                            </MarkdownPreview> : cont["summarizing"] == true ?
                                            <Typography variant="body2" style={{
                                              'paddingTop': '5px',
                                              'paddingLeft': '30px'
                                            }}>Summarizing... <CircularProgress size={20} sx={{ml:2, mt: 3}}/> </Typography> : null
                                          }
                                        </div>
                                      )) : null
                                    }
                                    <Box onClick={() => { setMoreWorking({[categories[key]]: true});  completeQuery(categories[key]);}}>
                                    {
                                        isMoreWorking[categories[key]] ?
                                          <CircularProgress size={20} sx={{ml:4, mr:2}}/>
                                        :
                                        <Typography variant="button" color="blue" display="block" textAlign="right" gutterBottom sx={{pr:2, cursor: 'pointer'}}>
                                          More
                                        </Typography>
                                    }
                                    </Box>
                                  </AccordionDetails>
                                </Accordion>
                              )})
                            }
                        </div>
                    </Grid>
                </Grid>
            </Stack>
            
            
        </Container>
    )
}