import { useState } from "react"
import AccordionData from "./accordionData"
import { downloadJson } from "../service/functionsList"


export default function MintedRecordData(props) {


    const [headers, setHeaders] = useState('')

    const { data, setShowModal, showModal, headerText } = props
    const [preview, setPreview] = useState(true)
    const [selectedIndex, setSelectedIndex] = useState()

    const actionFunction = async (element, index, key, type) => {
        alert('Not allowed for actions')
        console.log(element, index, key, type)
    }
    return (
        <div class="modal fade show" id="mintedRecordModal" style={showModal ? { display: 'block' } : { display: 'none' }} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <strong>{headerText}</strong>
                        <button type="button" class="btn-close" onClick={() => { setShowModal(false) }} aria-label="Close"></button>
                    </div>
                    <div className="modal-body modal-scroll-vh">
                        <button onClick={() => { downloadJson(data?.result) }} className="btn btn-primary">Download </button>
                        {data && <AccordionData preview={preview} result={data?.result || {}} setSelectedIndex={setSelectedIndex} actionFunction={actionFunction} />}
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" onClick={() => { setShowModal(false) }}>Close</button>
                    </div>
                </div>
            </div>
        </div>
    )

}