import { Alert, AlertTitle, Button, CircularProgress, Grid, Paper, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { BING_API_COST, BSEARCH_URI } from "../common/config";
import { useGlobalState } from "../common/globalState";
import { Box } from "@mui/system";
import { CreateHeader, CreateProperty, request, setGlobalVal } from "./common-tool";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { useWeb3React } from "@web3-react/core";
import { personal_sign } from "../common/metamask";

async function search_bing(query, address, callback_fn) {
    var params = {}
    var reason = `BING Query=${query}`;
    params.query = query;
    params.address = `${address}`;
    let fee = BING_API_COST;

    if (fee > 0) {
        var seed = await new Promise(resolve => request(resolve, params.address, fee, reason));

        if (seed === '') {
            return;
        }
        else {
            let vk = await personal_sign(seed, address);
            params.verification = vk;
            params.seed = seed;
        }
    }

    try {
        //alert(JSON.stringify(params));
        axios.post(
            BSEARCH_URI + "bing_query", params
        )
            .then(response => {
                if (response && response.data) {
                    var result = response.data;

                    if (!result || Object.keys(result).length === 0) {
                        result = { 'error': "The data does not exist in ABR database that we collected." };
                    }

                    callback_fn(result);
                }
                else {
                    callback_fn({});
                }

            })
    }
    catch (err) {
        var result = { 'error': err };
        callback_fn(result);
    }
}

export function BingView(props) {
    const [state, dispatch] = useGlobalState();
    const [onSpin, setSpin] = useState(false);
    const [content, setContent] = useState({});
    const [query, setQuery] = useState([]);
    const [mounted, setMounted] = useState(false);
    const context = useWeb3React()
    const { connector, chainId, activate, deactivate, error } = context
    let isDisabled = error || !chainId

    const handlePay = (e) => {
        setSpin(true);
        let joinStr = query.join(';')
        search_bing(query, context.account, result => {
            Object.keys(result).forEach(e => {
                let ar = result[e].split(';')
                result[e] = ar;
            })
            setContent(result);
            setSpin(false);
            setMounted(true);
            dispatch({
                bingHistory: { query: joinStr, result: result }
            })
        })
    }

    useEffect(() => {
        setQuery(state.entityName);

    }, [state.entityName])

    useEffect(() => {
        if (query.length === 0) {
            setContent({ 'error': "No query" });
            return;
        }
        let joinStr = query.join(';')
        if (joinStr === state.bingHistory.query) {
            setContent(state.bingHistory.result);
            setSpin(false);
            setMounted(true);
            return;
        }
        setContent({});
    }, [query])

    if (onSpin) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <CircularProgress size={50} />
            </Box>
        )
    } else {

        return (
            <div>

                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <Typography variant='h5'>
                            Bing Search Result
                        </Typography>
                    </Grid>

                    <Grid item xs={4}>
                        <Box textAlign={'right'}>
                            <Button disabled={isDisabled || query.length === 0 || mounted} variant="contained" color="success" onClick={handlePay} sx={{ borderRadius: 10, height: 40 }} >
                                <LockOpenIcon sx={{ mr: 2 }} />
                                Pay {BING_API_COST} MERc
                            </Button>

                        </Box>

                    </Grid>

                    <Grid item xs={12}>
                        <Typography sx={{ pl: 4, pr: 2, textAlign: "left" }}>
                            This pane displays the information of legal entity from Bing search result. It uses Bing search api.
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Paper sx={{ p: 4 }}>
                            {
                                content.error ?
                                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                        <Alert severity="info">
                                            <AlertTitle>Info</AlertTitle>
                                            {content.error}
                                        </Alert>
                                    </Paper>
                                    :
                                    CreateProperty('', content, null)
                            }

                        </Paper>
                    </Grid>
                </Grid>

            </div>

        )
    }
}