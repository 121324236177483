import { useWeb3React } from "@web3-react/core";
import {
  useEagerConnect,
  useInactiveListener,
  ConnectToTokenServer,
  MainInterface,
} from "./hooks";
import { Box } from "@mui/system";

import React from "react";
import { Spinner } from "./Spiner";
import { injected } from "./connector";

import "./style.css";
import mainLogo from "../icons/merc1.png";

import { NoMetamaskDialog } from "./components/noMetamask";

function MainLogo() {
  return (
    <div className="container">
      <img src={mainLogo} alt="fireSpot" />
      <h2 style={{ marginTop: 0 }}>MERchant Coin</h2>
    </div>
  );
}

function MERchantCoinView(props) {
  const triedEager = useEagerConnect();
  const context = useWeb3React();
  const { chainId, error } = context;
  let isDisconnect = !error && chainId;

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  return (
    <Box sx={{ pt: 8, display: props.show ? "block" : "none" }}>
      <ConnectToTokenServer />
      <MainInterface />
    </Box>
  );
  // if (isDisconnect) {
  // } else {
  //   return (
  //     <Box
  //       className="App"
  //       sx={{ pt: 8, display: props.show ? "block" : "none" }}
  //     >
  //       <header className="coinview-header">
  //         <div>
  //           <MainLogo />
  //         </div>
  //         <div>
  //           <ConnectChain triedEager={triedEager} onOpen={handleClickOpen} />
  //         </div>
  //       </header>
  //       <NoMetamaskDialog open={open} onClose={handleClose} />
  //     </Box>
  //   );
  // }
}

function ConnectChain(props) {
  const context = useWeb3React();
  const { connector, chainId, activate, deactivate, error } = context;

  const [activatingConnector, setActivatingConnector] = React.useState();
  React.useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  const activating = injected === activatingConnector;
  const connected = injected === connector;
  const disabled = !props.triedEager || !!activatingConnector || !!error;

  useInactiveListener(!props.triedEager || !!activatingConnector);

  let isDisconnect = !error && chainId;
  const buttonText = isDisconnect
    ? "Disconnect"
    : activating
      ? "Connecting"
      : "Connect to Wallet";

  return (
    <button
      style={{
        // borderColor: activating ? 'orange' : connected ? 'green' : 'unset',
        cursor: disabled ? "unset" : "pointer",
        position: "relative",
      }}
      className="ConnectButton"
      disabled={disabled}
      onClick={() => {
        if (window.ethereum == undefined) {
          props.onOpen();
          return;
        }

        if (!isDisconnect) {
          setActivatingConnector(injected);
          activate(injected);
        } else {
          deactivate();
        }
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          height: "100%",
          display: "flex",
          alignItems: "center",
          color: "black",
          margin: "0 0 0 1rem",
        }}
      >
        {activating && (
          <Spinner
            color={"red"}
            style={{ height: "50%", marginLeft: "-1rem" }}
          />
        )}
      </div>
      {buttonText}
    </button>
  );
}

export default MERchantCoinView;
