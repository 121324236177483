import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import { Avatar, Grid, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}

export default function EntityCandidate(props) {
  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={11}>
          <Title>
            Search Results
          </Title>
        </Grid>
        <Grid item xs={1}>
          <IconButton
            aria-label="close"
            onClick={props.onClose}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon/>
          </IconButton>
        </Grid>
      </Grid>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Type</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>ID</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data.map((row, index) => {
          
            return (
            <TableRow key={index}>
              <TableCell>
                <Avatar sx={{width:24, height:24}} {...stringAvatar(row[3])}/>
              </TableCell>
              <TableCell
                onClick={(e)=>props.onDetails(index)}
              >
                <Link color="primary" href="#">
                  {row[1]}
                </Link>
                
              </TableCell>
              <TableCell>{row[2]}</TableCell>
            </TableRow>
            )
}
          )
          }
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
