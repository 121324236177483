import { Web3Provider } from "@ethersproject/providers";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Tab, Tabs } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { purple } from "@mui/material/colors";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import { useWeb3React } from "@web3-react/core";
import axios from "axios";
import { BigNumber } from "ethers";
import PropTypes from "prop-types";
import React, {
  createContext,
  useEffect,
  useState
} from "react";
import { useGlobalState } from "../common/globalState";
import { BTCReserves } from "./btcReserves";
import { personal_sign } from "./comm_token";
import { request } from "../search/common-tool";
import { BalanceDisplay } from "./components/balance";
import { token_server_url, token_wss_server_url } from "./config";
import { injected } from "./connector";
import { SendButton } from "./sendButton";
import { TotalSupply } from "./totalSupply";
import { TransactionCard } from "./transactionButton";
import mainLogo from "../icons/merc1.png";
import { Spinner } from "./Spiner";
import { BuyMERCButton } from "./buyButton";
import { DepositButton } from "./depositButton";
import { SellMERCButton } from "./sellButton";
import { WithdrawButton } from "./withdrawButton";
import config from "../config";
import { SatoshiTotalSupply } from "./satoshitotal";

function MainLogo() {
  return (
    <div className="container">
      <img src={mainLogo} alt="fireSpot" />
      <h2 style={{ marginTop: 0 }}>MERchant Coin</h2>
    </div>
  );
}

const btcNetwork = "BTCTEST";
const text_style = {
  "& .MuiInputBase-input": {
    color: "orange", // Text color
    textAlign: "center",
  },
  "& .MuiInputAdornment-root": {
    alignItems: "baseline",
  },
  "& .MuiInputLabel-root": {
    color: "green", // Text color
  },
  "& .MuiInput-underline:before": {
    borderBottomColor: "green", // Semi-transparent underline
  },
  "& .MuiInput-underline:hover:before": {
    borderBottomColor: "green", // Solid underline on hover
  },
  "& .MuiInput-underline:hover:after": {
    borderBottomColor: "purple", // Solid underline on hover
  },
};

const dialog_text_style = {
  "& .MuiInputBase-input": {
    color: "black", // Text color
  },
  "& .MuiInput-input": {
    width: "260px", // Text color
  },
  "& .MuiInputLabel-root": {
    color: "black", // Text color
  },
  "& .MuiInput-underline:before": {
    borderBottomColor: "green", // Semi-transparent underline
  },
  "& .MuiInput-underline:hover:before": {
    borderBottomColor: "green", // Solid underline on hover
  },
  "& .MuiInput-underline:hover:after": {
    borderBottomColor: "purple", // Solid underline on hover
  },
};

const dialog_text_style1 = {
  "& .MuiInputBase-input": {
    color: "white", // Text color
  },
  "& .MuiInput-input": {
    width: "300px", // Text color
  },
  "& .MuiInputLabel-root": {
    color: "white !important", // Text color
  },
  "& .MuiInput-underline:before": {
    borderBottomColor: "white", // Semi-transparent underline
  },
  "& .MuiInput-underline:hover:before": {
    borderBottomColor: "white", // Solid underline on hover
  },
  "& .MuiInput-underline:hover:after": {
    borderBottomColor: "white", // Solid underline on hover
  },
  ".Mui-error": {
    color: "white !important",
  },
  ".Mui-error:after, .Mui-error:before": {
    borderBottomColor: "white !important",
  },
};

const unit_style = {
  "& .MuiTypography-root": {
    color: "#d8aa0cb8", // Text color
    fontSize: "10px",
  },
  container: {
    alignItems: "baseline",
  },
};

const defaultGlobalState = {
  btc_reserve_list: [],
  seed: "",
  mrcAddress: "",
  btcAddress: "",
  btcBalance: 0,
  mrcBalance: "",
  mrc2Balance: "",
  amount: 0,
  mrc20_contract: null,
  token_ws_server: null,
  send_msg: "",
  layer2: true,
  etherBalance: "",
  totalSupply: "",
  btcReserves: "",
  btcOperationFee: "",
  btcReserveBalance: "",
  layer2Total: 0,
  mercReserves: 0,
  transactionHistory: [],
  merc_contract_address: "",
  merc_owner: "",
  exchanger: "",
  exchangeBalance: "",
  inputAmountError: false,
};

const globalStateContext = createContext(defaultGlobalState);
const dispatchStateContext = createContext(undefined);

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const button_style = {
  color: "floralwhite",
  borderColor: "floralwhite",
  borderRadius: 15,
  fontSize: "12px",
  width: "200px",
  height: "40px",
};

const dialog_button_style = {
  enabledButton: {
    color: "black",
    borderColor: "black",
    borderRadius: 15,
    fontSize: "12px",
    width: "200px",
    height: "40px",
    backgroundColor: "transparent",
  },
  disabledButton: {
    borderColor: "rgb(0, 0, 0, 0.26)",
    borderRadius: 15,
    fontSize: "12px",
    width: "200px",
    height: "40px",
    color: "rgb(0, 0, 0, 0.26)",
    backgroundColor: "rgba(0, 0, 0, 0.12)",
  },
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export function MainInterface() {
  const context = useWeb3React();
  // const [user, setUser] = useState('');
  const [state, dispatch] = useGlobalState();
  const [activating, setActivating] = useState(false);
  const [value, setValue] = useState(0);


  var curUser = config.static_account_address;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const signInWass = async (isWass) => {

     async function signMetamask(msg, address) {
      try {
          const ethResult = await window.ethereum.request({
              method: 'personal_sign',
              params: [msg, address],
          });
          return ethResult;    
      
      }
      catch(err) {
          return '';
      }
  }

    if (!isWass) {
      if (!context.account) {
        alert('Please login with metamask');
        return null
      }
      else {
          try {
            let vk = await signMetamask(state.seed, context.account);
            var output = {
              type: "register",
              seed: state.seed,
              address: context.account,
              sign_code: vk,
            };
            state.token_ws_server.send(JSON.stringify(output));
          } catch (error) {
            console.log(error)
          }
      }
    }

    else {
      var userAddress = config.static_account_address;
      setActivating(true);

      if (state.token_ws_server) {
        const sign_code = await personal_sign(state.seed, userAddress);
       
        if (sign_code !== "") {
          var output = {
            type: "register",
            seed: state.seed,
            address: userAddress,
            sign_code: sign_code,
          };
      
          try {
            state.token_ws_server.send(JSON.stringify(output));

          } catch (error) {

            console.log(error);
          }
          // setUser(curUser)
        }
      }
      setActivating(false);

    }
  }

  // const init = async () => {
  //   if (state.token_ws_server) {
  //     const sign_code = await personal_sign(state.seed, curUser);
  //     console.log(sign_code);
  //     if (sign_code !== "") {
  //       var output = {
  //         type: "register",
  //         seed: state.seed,
  //         address: curUser,
  //         sign_code: sign_code,
  //       };
  //       try {
  //         state.token_ws_server.send(JSON.stringify(output));
  //       } catch (error) {
  //         console.log(error);
  //       }
  //       // setUser(curUser)
  //     }
  //   }
  // }

  // useEffect(() => {
  //   init();
  // }, []);

  // init();

  // if (curUser !== user) {
  if (!state.logged) {
    return (
      <div>
        <div className="App">
          <header className="coinview-header">
            <div>
              <MainLogo />
            </div>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
                "& .MuiTextField-root": {
                  m: 5,
                  b: 10,
                  width: "48ch",
                  align: "center",
                },
              }}
            >
              <MercAccount />
            </Box>
            <div>
              <button
                className="ConnectButton"
                onClick={() => { signInWass(true) }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    color: "black",
                    margin: "0 0 0 1rem",
                  }}
                >
                  {activating && (
                    <Spinner
                      color={"red"}
                      style={{ height: "50%", marginLeft: "-1rem" }}
                    />
                  )}
                </div>
                Sign in
              </button>
              <button
                className="ConnectButton"
                onClick={() => { signInWass(false) }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    color: "black",
                    margin: "0 0 0 1rem",
                  }}
                >
                  {activating && (
                    <Spinner
                      color={"red"}
                      style={{ height: "50%", marginLeft: "-1rem" }}
                    />
                  )}
                </div>
                sign in with metaMask
              </button>
            </div>
          </header>
        </div>
      </div>
    );
  }
  return (
    <div className="App">
      <header className="coinview-header">
        <Typography>{`Your address: ${config.static_account_address}`}</Typography>
        <Stack spacing={4} direction="row">
          <Stack>
            <Stack spacing={2} direction="row">
              <SatoshiTotalSupply />
              <TotalSupply />
              <BTCReserves />
            </Stack>
            <Stack>
              <TransactionCard />
            </Stack>
          </Stack>
          <Stack
            sx={{
              width: "350px",
              height: "725px",
              borderRadius: "20px",
              backgroundColor: "rgba(61, 61, 61, 0.28)",
              border: "1px solid rgba(255, 255, 255, 0.12)",
            }}
          >
            <Box
              sx={{
                height: "100%",
                margin: "8px 0 8px 8px",
                overflowY: "auto",
                "::-webkit-scrollbar-thumb": {
                  background: "transparent",
                },
              }}
            >
              <Stack spacing={1}>
                <Typography color="white" fontSize="20px">
                  Wallet
                </Typography>
                <ETHERBalanceTextxField />
                <MERCBalanceTextField />
                <MERC2BalanceTextField />
              </Stack>
              <Stack mt={2}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  sx={{
                    borderColor: "divider",
                    ".MuiButtonBase-root": {
                      textTransform: "none",
                      minWidth: "unset",
                    },
                    ".Mui-selected": { color: "white !important" },
                    ".MuiTab-textColorPrimary": {
                      color: "rgba(255, 255, 255, 0.4)",
                    },
                    ".MuiTabs-indicator": {
                      backgroundColor: "white !important",
                    },
                  }}
                >
                  <Tab label="Send" {...a11yProps(0)} wrapped />
                  <Tab label="Buy" {...a11yProps(1)} wrapped />
                  <Tab label="Sell" {...a11yProps(2)} wrapped />
                  <Tab label="Deposit" {...a11yProps(3)} wrapped />
                  <Tab label="Withdraw" {...a11yProps(4)} wrapped />
                </Tabs>
                <TabPanel value={value} index={0}>
                  <SendButton />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <BuyMERCButton />
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <SellMERCButton />
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <DepositButton />
                </TabPanel>
                <TabPanel value={value} index={4}>
                  <WithdrawButton />
                </TabPanel>
              </Stack>
            </Box>
          </Stack>
        </Stack>
      </header>
    </div>
  );
}

export function Layer2Check(props) {
  const [state, dispatch] = useGlobalState();

  const handleChange = (event) => {
    dispatch({
      layer2: event.target.checked,
    });
  };

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={state.layer2}
          onChange={handleChange}
          sx={{ ".MuiSvgIcon-root": { color: "white" } }}
        />
      }
      label={props.label ? props.label : "Deposit to Layer 2"}
    />
  );
}

var token_ws_server = null;
let mrc20_contract = null;

var only_one = true;
export function ConnectToTokenServer() {
  const context = useWeb3React();
  const [state, dispatch] = useGlobalState();

  useEffect(() => {
    if (only_one) {
      only_one = false;
      const ws = new WebSocket(token_wss_server_url);
      ws.onmessage = async (e) => {
        let data = JSON.parse(e.data);
        var address;
        if (data.seed) {
          dispatch({
            seed: data.seed,
          });
          return;
        }
        address = data.address;
        console.log(data);
        let bal = data.balance.layer2;
        let total_btc = data.btc_reserves.total_balance;
        let new_val = {
          mrc2Balance: bal,
          mrcBalance: data.balance.layer1,
          btcReserveBalance: total_btc,
          mercReserves: data.mrc_reserves,
          exchangeBalance: data.exchanger_balance,
          totalSupply: data.mrc_total_supply,
          layer2Total: data.layer2_total,
          transactionHistory: data.transaction,
          btc_reserve_list: data.btc_reserve_info,
          mercBalance: data.balance,
          mercTransaction: data.transaction,
          nft: data.nft,
          mercStatistics: data.statistics,
          etherBalance: data.balance.eth,
          logged: true,
        };

        dispatch(new_val);

        //setMercBalance(address, state, dispatch);
        // setEtherBalance(address, state, dispatch);
      };
      ws.onopen = (e) => {
        //ws.send(`${context.account}`)
        token_ws_server = ws;
        dispatch({
          token_ws_server: ws,
        });
      };
      ws.onclose = (e) => {
        console.log("closed ws server.");
      };

      setContract(
        state,
        async (contract, merc_address, merc_owner, exchanger) => {
          mrc20_contract = contract;
          try {
            var ts = (await contract.totalSupply()).toNumber();
            dispatch({
              mrc20_contract: contract,
              totalSupply: ts,
              merc_contract_address: merc_address,
              merc_owner: merc_owner,
              exchanger: exchanger,
            });
          } catch (error) {
            console.log(error)
          }
        }
      );
    }
  });

  return <div></div>;
}

// export function GlobalStateProvider ({ children }) {
//   const [state, dispatch] = useReducer(
//     (state, newValue) => ({ ...state, ...newValue }),
//     defaultGlobalState
//   );
//   return (
//     <globalStateContext.Provider value={state}>
//       <dispatchStateContext.Provider value={dispatch}>
//         {children}
//       </dispatchStateContext.Provider>
//     </globalStateContext.Provider>
//   );
// }

// export function useGlobalState () {
//     return [
//         useContext(globalStateContext),
//         useContext(dispatchStateContext)
//     ];
// }

async function setContract(state, callback_fn) {
  if (state.mrc20_contract != null) {
    return;
  }
  const { Contract } = require("ethers");

  try {
    axios.get(token_server_url + "/get_merc_token").then(async (response) => {
      if (response && response.data) {
        let res = response.data;
        if (res.status === true) {
          let { merc_address, merc_abi, merc_owner, exchanger } = res.data;
          const contract = await new Contract(
            merc_address,
            merc_abi,
            new Web3Provider(window.ethereum)
          );
          callback_fn(contract, merc_address, merc_owner, exchanger);
        }
      }
    });
  } catch (err) {
    console.log(err);
  }
}

const theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: purple[500],
    },
    secondary: {
      // This is green.A700 as hex.
      main: "#11cb5f",
    },
  },
});

export function useEagerConnect() {
  const { activate, active } = useWeb3React();
  const [tried, setTried] = useState(false);

  useEffect(() => {
    injected.isAuthorized().then((isAuthorized) => {
      if (isAuthorized) {
        activate(injected, undefined, true).catch(() => {
          setTried(true);
        });
      } else {
        setTried(true);
      }
    });
  }, []);

  useEffect(() => {
    if (!tried && active) {
      setTried(true);
    }
  }, [tried, active]);

  return tried;
}

export function MercAccount() {
  const context = useWeb3React();
  const [state, dispatch] = useGlobalState();

  useEffect(() => {
    changedAccount(context.account, state, dispatch);
  }, [context.account]);

  if (context.active && !context.error) {
    return (
      <ThemeProvider theme={theme}>
        <TextField
          sx={{
            "& .MuiInputBase-input": {
              color: "white", // Text color
              backgroundColor: "rgba(61, 61, 61, 0.28)",
              textAlign: "center",
            },
            "& .MuiInputLabel-root": {
              color: "green", // Text color
            },
            "& .MuiInput-underline:before": {
              borderBottomColor: "green", // Semi-transparent underline
            },
            "& .MuiInput-underline:hover:before": {
              borderBottomColor: "green", // Solid underline on hover
            },
            "& .MuiInput-underline:hover:after": {
              borderBottomColor: "purple", // Solid underline on hover
            },
          }}
          id="merc-account"
          label="Account"
          variant="filled"
          value={context.account}
        />
      </ThemeProvider>
    );
  } else {
    return <div className="BalanceWrapper"></div>;
  }
}

function getBtcBalance(adr, dispatch) {
  adr = adr.trim();
  axios
    .get(`https://chain.so/api/v2/get_address_balance/${btcNetwork}/${adr}/500`)
    .then((response) => {
      let bal = response.data.data.confirmed_balance;
      bal = bal * 1e8;
      dispatch({ btcBalance: bal });
    });
}

async function setMercBalance(address, state, dispatch) {
  if (mrc20_contract != null) {
    console.log("getting balance of layer1");
    let bal = await mrc20_contract.balanceOf(address);
    bal = BigNumber.from(bal).toNumber();
    console.log(`changing merc value=${bal}`);
    dispatch({
      mrcBalance: bal,
    });
    return bal;
  } else {
  }
}

async function setEtherBalance(address, state, dispatch) {
  window.ethereum
    .request({
      method: "eth_getBalance",
      params: [address, "latest"],
    })
    .then((balance) => {
      var bal = parseFloat(balance / 1e18);
      dispatch({
        etherBalance: bal,
      });
    });
  /*var provider = new Web3Provider(window.ethereum);
    console.log(provider);
    if (provider) {
        let bal = await provider.getBalance(state.mrcAddress);
        bal = BigNumber.from(bal).toNumber()
        dispatch({
            etherBalance: bal
        });
        return bal;
    }*/
}

function setMerc2Balance(context, state, dispatch) {
  return 0;
}

export function BTCAccountTextField(props) {
  const [btcAddress, setBTCAddress] = useState("");
  const [state, dispatch] = useGlobalState();

  return (
    <ThemeProvider theme={theme}>
      <TextField
        error={btcAddress === ""}
        helperText={btcAddress === "" ? "Required" : ""}
        required
        sx={dialog_text_style1}
        onChange={(newValue) => {
          let adr = newValue.target.value.trim();
          setBTCAddress(adr);
          dispatch({ btcAddress: adr });
          getBtcBalance(adr, dispatch);
        }}
        id="btc-account"
        label={props.label}
        variant="standard"
        value={btcAddress}
      />
    </ThemeProvider>
  );
}

export function BTCBalanceTextField() {
  const [val, setVal] = useState(0);
  const [state, _] = useGlobalState();

  useEffect(() => {
    setVal(state.btcBalance);
  }, [state.btcBalance]);

  return (
    <ThemeProvider theme={theme}>
      <TextField
        sx={text_style}
        InputProps={{
          endAdornment: (
            <InputAdornment sx={unit_style} position="end">
              Satoshi
            </InputAdornment>
          ),
        }}
        id="btc-balance"
        label="Balance"
        variant="standard"
        value={val}
      />
    </ThemeProvider>
  );
}

/*
export function MERCBalanceTextField() {
    const [val, setVal] = useState(0);
    const [state, _] = useGlobalState();
    useEffect(()=>{
        setVal(state.mrcBalance)
    }, [state.mrcBalance])

    return (
        <ThemeProvider theme={theme}>
            <TextField 
            sx={text_style}
            
            InputProps={{
                endAdornment: <InputAdornment sx={unit_style} position="end">MERc</InputAdornment>,
            }}

            id="mrc-balance1" label="Layer1 Balance" variant="filled" value={val} size="small"  />
        </ThemeProvider>
    );
}
*/

export function MERCBalanceTextField() {
  const [val, setVal] = useState(0);
  const [change, setChange] = useState(0);
  const [state, _] = useGlobalState();

  useEffect(() => {
    if (state.mrcBalance !== "" && val !== "") {
      setChange(state.mrcBalance - val);

      const timer = setTimeout(() => {
        setChange(0);
      }, 30000);
    }
    setVal(state.mrcBalance);
  }, [state.mrcBalance]);

  return (
    <BalanceDisplay
      title="Layer1 Balance"
      value={val}
      unit="MERc"
      change={change}
    />
  );
}

/*
export function MERC2BalanceTextField() {
    const [val, setVal] = useState(0);
    const [state, _] = useGlobalState();
    useEffect(()=>{
        setVal(state.mrc2Balance)
    }, [state.mrc2Balance])

    return (
        <ThemeProvider theme={theme}>
            <TextField 
            sx={text_style}
            
            InputProps={{
                endAdornment: <InputAdornment sx={unit_style} position="end">MERc</InputAdornment>,
            }}

            id="mrc-balance2" label="Layer2 Balance" variant="filled" value={val} size="small"  />
        </ThemeProvider>
    );
}
*/
export function MERC2BalanceTextField() {
  const [val, setVal] = useState(0);
  const [change, setChange] = useState(0);
  const [state, _] = useGlobalState();

  useEffect(() => {
    if (state.mrc2Balance !== "" && val !== "") {
      setChange(parseInt(state.mrc2Balance) - val);

      const timer = setTimeout(() => {
        setChange(0);
      }, 30000);
    }
    console.log(state.mrc2Balance);
    setVal(state.mrc2Balance === "" ? "" : parseInt(state.mrc2Balance));
  }, [state.mrc2Balance]);

  return (
    <BalanceDisplay
      title="Layer2 Balance"
      value={val}
      unit="MERc"
      change={change}
    />
  );
}

const ether_text_style = text_style;
ether_text_style.height = "2ch";
ether_text_style.fontSize = "24px";
/*
export function ETHERBalanceTextxField() {
    const [val, setVal] = useState(0);
    const [state, _] = useGlobalState();
    
    useEffect(()=>{
        setVal(state.etherBalance)
    }, [state.etherBalance])
    return (
        <ThemeProvider theme={theme}>
            <TextField 
            hiddenLabel
            sx={text_style}
            
            InputProps={{
                endAdornment: <InputAdornment sx={unit_style} position="end">ETHER</InputAdornment>,
            }}

            id="ether-balance" label="" variant="filled" value={val} size="medium" />
        </ThemeProvider>
    );
}
*/
export function ETHERBalanceTextxField() {
  const [val, setVal] = useState(0);
  const [change, setChange] = useState(0);
  const [state, _] = useGlobalState();

  console.log(state.etherBalance);

  useEffect(() => {
    if (state.etherBalance !== "" && val !== "") {
      setChange(Number(state.etherBalance) - val);

      const timer = setTimeout(() => {
        setChange(0);
      }, 30000);
    }
    setVal(Number(state.etherBalance));
  }, [state.etherBalance]);

  console.log(val);

  return (
    <BalanceDisplay
      title="Ethereum Balance"
      value={val !== "" ? val.toFixed(4) : val}
      unit="ETHER"
      change={change.toFixed(6)}
    />
  );
}

async function changedAccount(account, state, dispatch) {
  window.focus();
  var address = `${account}`;

  dispatch({
    mrcAddress: address,
    mrcBalance: "",
    mrc2Balance: "",
    etherBalance: "",
    transactionHistory: [],
  });
  setMercBalance(address, state, dispatch);
  setEtherBalance(address, state, dispatch);
  setMerc2Balance(address, state, dispatch);
}
export function useInactiveListener(suppress = false) {
  const { active, error, activate } = useWeb3React();

  useEffect(() => {
    const { ethereum } = window;
    if (ethereum && ethereum.on && !active && !error && !suppress) {
      const handleConnect = () => {
        console.log("Handling 'connect' event");
        activate(injected);
      };
      const handleChainChanged = (chainId) => {
        console.log("Handling 'chainChanged' event with payload", chainId);
        activate(injected);
      };
      const handleAccountsChanged = (accounts) => {
        console.log("Handling 'accountsChanged' event with payload", accounts);
        activate(injected);
      };
      const handleNetworkChanged = (networkId) => {
        console.log("Handling 'networkChanged' event with payload", networkId);
        activate(injected);
      };

      ethereum.on("connect", handleConnect);
      ethereum.on("chainChanged", handleChainChanged);
      ethereum.on("accountsChanged", handleAccountsChanged);
      ethereum.on("networkChanged", handleNetworkChanged);

      return () => {
        if (ethereum.removeListener) {
          ethereum.removeListener("connect", handleConnect);
          ethereum.removeListener("chainChanged", handleChainChanged);
          ethereum.removeListener("accountsChanged", handleAccountsChanged);
          ethereum.removeListener("networkChanged", handleNetworkChanged);
        }
      };
    }
  }, [active, error, suppress, activate]);
}
