import axios from 'axios';
import { token_server_url } from './config'

async function httprequest(url, requestData, method = "GET") {
    return new Promise(resolve => {

        axios(url, {
            method: method,
            params: requestData,
        })
            .then(res => {
                resolve(res.data);
            });
    });

};

export async function get_btc_reserve_address(amount) {
    var res = await httprequest(token_server_url + '/get_reserve_single_address', { amount: amount });
    if (res.status === true) {
        return res.data.address;
    } else {
        return '';
    }
}

export async function register_reserve(data) {
    var res = await httprequest(token_server_url + "/reserve_register", data);
    return res.status;
}

export async function secure_transaction(address, fee, type, target, reason = null) {
    let res = await httprequest(token_server_url + "/request", {
        address: address,
        fee: fee,
        type: type,
        target: target,
        reason: reason
    });
    if (res.status !== true) {
        alert('Failed to connect Server.');
        return null;
    } else {
        let seed = res.data.seed;
        let vk = await personal_sign(seed, address)
        console.log(`vk=${vk}`)
        let result = await httprequest(token_server_url + "/verify", {
            address: address,
            seed: seed,
            verification: vk,
            type: type
        });
        return result;
    }
}

export async function secure_query(query) {
    let res = await httprequest(token_server_url + "/request", query);
    if (res.status !== true) {
        alert('Failed to connect Server.');
        return null;
    } else {
        let seed = res.data.seed.toString();
        let vk = await personal_sign(seed, query.address)
        //console.log(`vk2=${vk2}`)
        let result = await httprequest(token_server_url + "/verify", {
            address: query.address,
            seed: seed,
            verification: vk,
            type: query.type
        });
        return result;
    }
}

export async function secure_query_with_type_data(query, exchanger_address, contract) {
    let res = await httprequest(token_server_url + "/request", query);
    if (res.status !== true) {
        alert('Failed to connect Server.');
        return null;
    } else {
        let seed = res.data.seed;
        let vk = await eip712_sign(query.address, exchanger_address, query.amount, seed, query.kind, contract)
        console.log(`vk=${vk}`)
        let result = await httprequest(token_server_url + "/verify", {
            address: query.address,
            seed: seed,
            verification: vk,
            type: query.type,
            typedata: true
        });
        return result;
    }
}

export async function personal_sign(msg, address) {
    try {
        const result = await httprequest(token_server_url + '/personal_sign', {
            msg,
            address
        });
       
        return result.data;
        // const ethResult = await window.ethereum.request({
        //     method: 'personal_sign',
        //     params: [msg, address],
        // });
        // return ethResult;    

    }
    catch (err) {
        return '';
    }
}

export async function estimate_gas_fee(record) {
    let res = await httprequest(token_server_url + "/estimate_gas_fee", record);
    if (res.status !== true) {
        return 0;
    } else {
        return res.data.gasLimit;
    }
}

export async function eip712_sign(signer, address, amount, seed, type, contract) {
    const domain = [
        { name: "name", type: "string" },
        { name: "version", type: "string" },
        { name: "chainId", type: "uint256" },
        { name: "verifyingContract", type: "address" },
    ];

    const Message = [
        { name: "agent", type: "address" },
        { name: "amount", type: "uint256" },
        { name: "seed", type: "string" },
        { name: "kind", type: "string" },
    ];

    const domainData = {
        name: "Accziom Exchanger",
        version: "1",
        chainId: 5,
        verifyingContract: contract
    };

    var message = {
        agent: address,
        amount: amount,
        seed: seed,
        kind: type
    };

    const data = JSON.stringify({
        types: {
            EIP712Domain: domain,
            Message: Message,
        },
        domain: domainData,
        primaryType: "Message",
        message: message
    });

    let result = await window.ethereum.request(
        {
            method: "eth_signTypedData_v3",
            params: [signer, data],
            from: signer
        });

    return result;
}