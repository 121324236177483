
//generator();

import JSEncrypt from 'jsencrypt';
/*
    Encryptor
*/
var encryptor = null;

var pubkey= `-----BEGIN PUBLIC KEY-----
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCtKK63iNorWx63yMJ8zUTAAV20
97VV2d6kYzrKeNwEq0o7dIGu1eNtgaSQtbozddZ+elsH/w9uPgNO3/qSIOnhj+3V
FBK2Ku67jJb2vOEaLzQO6Ye3QFlJ/CDRY4fFvD608sRT7hRY0HNLFAjNL+ljqweZ
tqqnIC5tSqXsfnpDNQIDAQAB
-----END PUBLIC KEY-----`

function init() {
    encryptor = new JSEncrypt();
    encryptor.setPublicKey(pubkey);
};
init();

export function encrypt(txt) {
    return encryptor.encrypt(txt);
}
