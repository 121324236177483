import React from "react";
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import {
  purple,
  green,
  indigo,
  deepPurple,
  lime,
  lightGreen,
} from "@mui/material/colors";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

const theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: purple[500],
    },
    secondary: {
      // This is green.A700 as hex.
      main: "#11cb5f",
    },
  },
  typography: {
    overline: {
      color: green[500],
      fontSize: 10,
    },
    h5: {
      color: "orange",
      fontSize: 18,
    },
    h6: {
      color: lime[500],
      fontSize: 12,
      marginTop: "5px",
    },
    caption: {
      color: lightGreen[200],
      fontSize: 10,
    },
  },
});

var card_style = {
  width: "100%",
  backgroundColor: "rgba(255, 255, 255, 0.06)",
  border: "1px solid rgba(255, 255, 255, 0.12)",
  borderRadius: "16px",
  lastChild: {
    paddingsBotton: 0,
  },
  padding: 1,
};

export const BalanceDisplay = (props) => (
  <ThemeProvider theme={theme}>
    <Box sx={card_style} {...props}>
      <Grid container>
        <Grid item xs={16}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Typography variant="h5" color="white">
                {props.value.toLocaleString("en-US")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6">
                {props.unit}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container alignItems="center">
        <Grid item xs={6}>
          <Typography gutterBottom variant="overline">
            {props.title}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          {props.change < 0 ? (
            <ArrowDownwardIcon fontSize="small" color="warning" />
          ) : props.change > 0 ? (
            <ArrowUpwardIcon fontSize="small" color="info" />
          ) : (
            <div></div>
          )}
          <Typography
            sx={{
              mr: 1,
            }}
            variant="caption"
          >
            {parseFloat(props.change) !== 0
              ? Math.abs(props.change).toLocaleString("en-US", {
                  maximumFractionDigits: 8,
                }) +
                " " +
                props.unit
              : ""}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  </ThemeProvider>
);
