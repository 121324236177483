import { Backdrop, CircularProgress, Link, List, ListItem, Pagination, Paper, TablePagination } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { BSEARCH_URI } from "../common/config";

export function DBList(props) {
    const [isLoading, setLoding] = useState(false)
    const [list, setList] = useState([])
    const [curStart, setCurStart] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [totalCount, setTotalCount] = useState(0);
    const [page, setPage] = useState(0);
    const [loaded, setLoaded] = useState(false);

    const get_db_list = (start, count) => {
        setLoaded(false);
        axios.get(BSEARCH_URI+"db_list", {params: {
            type: props.db,
            start: start,
            count: count
        }})
        .then(response=>{
            setList(response.data)
            setLoaded(true);
        })
    }    
    
    const handleChangePage = (event, newPage) => {
        if (newPage === page) return;
        setPage(newPage);
        let p = newPage * rowsPerPage;
        get_db_list(p, rowsPerPage);
    };

    const handleChangeRowsPerPage = (event) => {
        let p = page * rowsPerPage;
        let newVal = parseInt(event.target.value, 10)
        p = p / newVal;
        setRowsPerPage(newVal);
        setPage(parseInt(p,10));
        get_db_list(parseInt(p,10), newVal);
    };

    const get_db_list_count = ()=>{
        setLoding(true);
        axios.get(BSEARCH_URI+"db_list_count", {params:{
            type: props.db,
        }})
        .then(response=>{
            setTotalCount(parseInt(response.data))
            get_db_list(0, rowsPerPage)
        })
    }

    useEffect(()=>{
        get_db_list_count()
        setLoaded(true);
    }, [props.db])

    return (
        <Box>
            <TablePagination
            component="div"
            count={totalCount}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <List>
                {
                    list.map((e, index)=>{
                        return (
                            <ListItem key={index}>
                                <Link href="#" onClick={event=>props.onDetails(e)}>
                                    {e}
                                </Link>
                            </ListItem>
                        )
                    })
                }
            </List>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={!loaded}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    )
}