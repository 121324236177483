
export default function AccordionData(props) {
    const { preview, result, setSelectedIndex, actionFunction } = props
    const toolTipsText =

    {   
         "Legal Name": "The official registered name of a business or entity as listed in legal documents and with government agencies.",
    
        "Entity Name": "The registered name of a company or organization as it appears on the Australian Business Register (ABR).",
        
        "Business Name": "A name under which a business operates in Australia, registered with ASIC (Australian Securities and Investments Commission).",
        
        "Trading Name": "The name a business uses in the market, which may differ from its legal or business name.",
        
         "Name": "The general designation used to identify a person, business, or entity.",
        
        "ABR Entity Type": "The classification of a business entity according to the Australian Business Register (e.g., sole trader, company, trust).",
        
         "ABN": "A unique 11-digit number assigned to businesses by the Australian Business Register for taxation and government dealings.",
        
         "Postal Code": "A numerical code used to identify a specific geographic area for mail delivery within Australia.",
        
         "State": "A primary political division of Australia, such as New South Wales, Victoria, or Queensland.",
        
         "Suburb": "A residential area or district within a city or town in Australia.",
        
         "Active Status": "Indicates whether a business is currently active or operational as per the Australian Business Register.",
        
         "GST Effective Date": "The date from which a business is registered for the Goods and Services Tax (GST) in Australia.",
        
         "ABR Last Confirmed Date":" The last date when the business details were confirmed or updated with the Australian Business Register.",
        
         "ACN": "A unique nine-digit number issued by ASIC to every company registered in Australia, used to identify the company for legal and regulatory purposes."
        }
        console.log(result,"resu;lt")
    return (
        <div className="card-body">
            {preview && <div className="accordion" id="accordionExample">
                {Object.keys(result)?.map((key, index) => {
                        
                    if (result[key] !== -1) {
                        return (
                            <div className="accordion-item" key={index}>
                                <h2 className="accordion-header">
                                    <button
                                        className="accordion-button"
                                        type="button"
                                        onClick={() => { setSelectedIndex(index) }}
                                        data-bs-toggle="collapse"
                                        data-bs-target={`#collapseOne${index}`}
                                        aria-expanded={true}
                                        aria-controls={`collapseOne${index}`}>
                                        {key}
                                        {/* <a href="#" data-bs-toggle="tooltip" data-bs-title="Default tooltip" className="ms-2">
                                            <i class="bi bi-info-circle-fill text-primary"></i>
                                        </a> */}
                                        <button type="button" class="btn btn-transparent p-0 ms-2"
                                            data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Tooltip on top" title={`${toolTipsText?.[key] ? toolTipsText?.[key] : "Not available"}`}>
                                            <i class="bi bi-info-circle-fill text-primary"></i>
                                        </button>
                                    </button>
                                </h2>
                                <div
                                    id={`collapseOne${index}`}
                                    className={`accordion-collapse collapse show`}
                                    data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <div className="table-responsive">
                                            <table className="table  table-hover text-center align-middle">
                                                <thead className="table-primary">
                                                    {result[key]?.map((elements, indexss) => {
                                                        if (indexss == 0) {
                                                            return (
                                                                <tr key={indexss}>
                                                                    {Object.keys(elements)?.map((keyss, index) => {
                                                                        return (
                                                                            <th>
                                                                                {keyss}
                                                                            </th>
                                                                        )

                                                                    })
                                                                    }
                                                                </tr>
                                                            )
                                                        }

                                                    })}

                                                </thead>
                                                <tbody>
                                                    {
                                                        result[key]?.map((element, indexs) => {


                                                            return (
                                                                <tr key={indexs} className={`${element?.score == "High" ? "table-rows-success" : element?.score == "Medium" ? "table-rows-warning" : "table-rows-danger"
                                                                    }`} >
                                                                    {Object.keys(element)?.map((keyz, index) => {
                                                                        if (keyz == "action") {
                                                                            return (
                                                                                <td style={{ width: "30%" }}>
                                                                                    <div className='d-flex justify-content-center align-items-center'>
                                                                                        {element[keyz]?.map((actionss, indexxx) => {
                                                                                            if (actionss == "DELETE") {
                                                                                                return (<div className='circle-icons me-2' onClick={() => {
                                                                                                    actionFunction(element, indexs, key, actionss)
                                                                                                }}>
                                                                                                    <i class="bi bi-trash3 text-white"></i>
                                                                                                </div>)

                                                                                            }
                                                                                            else if (actionss == "ADD") {
                                                                                                return (
                                                                                                    <div className='circle-icons me-2' onClick={() => {
                                                                                                        actionFunction(element, indexs, key, actionss)
                                                                                                    }}>
                                                                                                        <i class="bi bi-plus-lg text-white"></i>
                                                                                                    </div>
                                                                                                )

                                                                                            }
                                                                                            else {
                                                                                                return (
                                                                                                    <div className='circle-icons me-2' onClick={() => {
                                                                                                        actionFunction(element, indexs, key, actionss)
                                                                                                    }}>
                                                                                                        <i class="bi bi-pencil text-white"></i>
                                                                                                    </div>
                                                                                                )

                                                                                            }

                                                                                        })}


                                                                                    </div>
                                                                                </td>
                                                                            )
                                                                        }
                                                                        else {
                                                                            if(key == "Url"){
                                                                                if(keyz == "value"){
                                                                                    return(
                                                                                        <td >
                                                                                      {  element?.[keyz]?.map((entry,index)=>{
                                                                                            return( <><a target="_blank" href={entry}>{entry}</a><br /></>)
                                                                                         })}
                                                                                         </td>
                                                                                    )
                                                                                 
                                                                                }
                                                                                else{
                                                                                    return(
                                                                                        <td>{element[keyz]}</td>
                                                                                      )
                                                                                }
                                                                            
                                                                                     
                                                                            }
                                                                            else{
                                                                                return (
                                                                                    <td  dangerouslySetInnerHTML={{ __html: element[keyz] }}></td>
                                                                                )

                                                                            }
                                                                        }

                                                                    })}
                                                                </tr>
                                                            )
                                                        })
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }

                })}
            </div>}
        </div>
    )
}