import * as React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { MainListItems, ServiceListItems, ProfileListItems } from "./listItems";
import PersonIcon from "@mui/icons-material/Person";
import { MainSearch } from "./search/MainSearch";
import { useGlobalState } from "./common/globalState";
import { Web3Provider } from "@ethersproject/providers";
import { injected, personal_sign } from "./common/metamask";
import { Web3ReactProvider, useWeb3React } from "@web3-react/core";
import { Button, CircularProgress, Paper } from "@mui/material";
import { MERC_TOKEN_WS_URL } from "./common/config";
import { MERCView } from "./profile/MERCView";
import { NFTView } from "./profile/NFTView";
import { ProfileView } from "./profile/ProfileView";
import { OrganizationView } from "./profile/OrganizationView";
import { RDFDetails } from "./search/RDFDetails";
import { ChatbotView } from "./legislation/ChatbotView";
import { LegalSearch } from "./legislation/LegalSearch";
import { ChatbotHistoryView } from "./profile/ChatHistory";
import { RelationRecognitionView } from "./service/RelationRecognition";

function getLibrary(provider) {
  const library = new Web3Provider(provider);
  library.pollingInterval = 5000;
  return library;
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const mdTheme = createTheme();

const createWebSocket = (url) => {
  if (window.WebSocket) return new WebSocket(url);
  return null;
};

function AccountInfo() {
  const [activatingConnector, setActivatingConnector] = React.useState();
  const context = useWeb3React();
  const { connector, chainId, activate, deactivate, error } = context;
  const [state, dispatch] = useGlobalState();
  const [logged, setLogged] = React.useState(false);
  const [address, setAddress] = React.useState("");
  const [webSocket, setWebSocket] = React.useState(null);

  const activating = injected === activatingConnector;

  const buttonText = activating ? "Connectting" : "Connect to Wallet";
  let connected = !error && chainId;

  const connectToTokenServer = () => {
    setLogged(false);
    const ws = new WebSocket(MERC_TOKEN_WS_URL);
    ws.onmessage = async (e) => {
      let data = JSON.parse(e.data);
      let address = context.account;
      if (data.seed) {
        var sign_code = await personal_sign(data.seed, address);
        var output = {
          type: "register",
          seed: data.seed,
          address: address,
          sign_code: sign_code,
        };
        ws.send(JSON.stringify(output));
        return;
      }
      console.log(data);
      setLogged(true);
      let bal = data.balance;
      let transaction = data.transaction;
      let nft = data.nft;
      let stat = data.statistics;
      dispatch({
        mercBalance: bal,
        mercTransaction: transaction,
        nft: nft,
        mercStatistics: stat,
        logged: true,
      });
    };
    ws.onopen = (e) => {
      console.log(`mrcaddress=${context.account}`);
      //ws.send(`${context.account}`)
      setWebSocket(ws);
    };
    ws.onerror = (e) => {
      console.log(e);
    };
    ws.onclose = (e) => {
      console.log("closed ws server.");
      setLogged(false);
      dispatch({
        logged: false,
      });
    };
  };

  React.useEffect(() => {
    if (address !== context.account) {
      setAddress(context.address);
      if (logged) {
        webSocket.close();
        setWebSocket(null);
      }
      setLogged(false);

      dispatch({
        mercBalance: {},
        mercTransaction: [],
        nft: null,
        mercStatistics: {},
        logged: false,
      });
    }
  }, [context.account]);

  const handleSignIn = async () => {
    connectToTokenServer();
  };

  React.useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  if (connected) {
    return (
      <Box color="inherit">
        <Badge variant="dot" color="secondary" sx={{ mr: 2 }}>
          <PersonIcon />
        </Badge>
        {!logged && (
          <Button variant="contained" color="secondary" onClick={handleSignIn}>
            Sign In
          </Button>
        )}
        <Typography>{context.account}</Typography>
      </Box>
    );
  } else {
    if (activating) {
      return (
        <Box color="inherit">
          <PersonIcon sx={{ mr: 2 }} />
          <CircularProgress color="secondary" size={20} sx={{ mr: 2 }} />
        </Box>
      );
    } else {
      return (
        <Box color="inherit">
          <PersonIcon sx={{ mr: 2 }} />
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              if (!connected) {
                setActivatingConnector(injected);
                activate(injected);
              }
            }}
          >
            {buttonText}
          </Button>
        </Box>
      );
    }
  }
}

function DashboardContent(props) {
  const [open, setOpen] = React.useState(true);
  const [state, dispatch] = useGlobalState();

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1, textAlign: "center" }}
            >
              {state.entityName.length === 0
                ? "bSearch"
                : state.entityName[0]}
            </Typography>
            <AccountInfo />
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            <ServiceListItems />
            <Divider sx={{ my: 1 }} />
            <ProfileListItems />
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <MainSearch show={state.pane === "Search"} {...props} />
          <LegalSearch show={state.pane === "Legal Database"} {...props} />
          <ProfileView show={state.pane === "Profile"} {...props} />
          <OrganizationView show={state.pane === "Organizations"} {...props} />
          <MERCView show={state.pane === "Wallet"} />
          <ChatbotView show={state.pane === "Tax Chat"} {...props} />
          <ChatbotHistoryView show={state.pane === "ChatHistory"} {...props} />
          <RelationRecognitionView
            show={state.pane === "RelationRecognition"}
            {...props}
          />

          <NFTView show={state.pane === "NFT" && state.openDetails === false} />
          {state.pane === "NFT" && state.openDetails === true && (
            <Paper
              sx={{
                p: 4,
                display: "flex",
                flexDirection: "column",
                backgroundColor: (theme) =>
                  theme.palette.mode === "light"
                    ? theme.palette.grey[200]
                    : theme.palette.grey[800],
              }}
            >
              <RDFDetails
                onClose={(e) => {
                  dispatch({ openDetails: false });
                }}
              />
            </Paper>
          )}
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default function BSearchMain(props) {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <DashboardContent {...props} />
    </Web3ReactProvider>
  );
}
