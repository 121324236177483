import { Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, IconButton, Paper, Select, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchIcon from '@mui/icons-material/Search';
import axios from "axios";
import { BSEARCH_URI } from "../common/config";
import { ComboBox } from "../utils/combobox";
import { search_rdf_from_uri } from "./RDFDetails";
import { useWeb3React } from "@web3-react/core";
import { CreateHeader } from "./common-tool";

export function URISearch(props) {
    const [query, setQuery] = useState("");
    const [candidates, setCandidates] = useState([]);
    const context = useWeb3React()
    const [cost, setCost] = useState(0);
    const [content, setContent] = useState(null);
    const [uri, setURI] = useState("");
    const [selectedItem, selectItem] = useState("");
    const [working, setWorking] = useState(false);

    const searchURI = async (event) => {
        let keys = query.split(' ');
        setWorking(true);
        let result = await axios.post(BSEARCH_URI + "search_rdf_uri_list", {
            query: keys
        });
        let cand = result.data;
        setCandidates(cand);
        setWorking(false);
        if (cand.length > 0) {
            selectItem(cand[0])
            onChangeURI(cand[0])
        }
        
    }

    const onChangeURI = (value)=>{
        selectItem(value);
        if (value.uri) {
            setWorking(true);
            setContent(null);
            search_rdf_from_uri(value.uri, context.account, 0, result=>{
                var c = result.predicted_fee || 0;
                setCost(c);
                delete result.predicted_fee
                setContent(result);
            })
            setURI(value.uri)
            setWorking(false);
        } else {
            setURI("");
        }
    }

    const handleOK = () => {
        if (uri == '') {
            props.handleOk('');
            return;
        }
        props.handleOk('<'+uri+'>')
    }

    const clickHyperLink = (e) => {

    }

    var ord = content?content['##order##']:null;

    return (
        <Container>
            <Dialog open={props.open} onClose={props.handleClose}>
                <DialogTitle>Search URI</DialogTitle>
                <DialogContent sx={{minHeight:'500px', maxHeight:'500px', minWidth:'500px'}}>
                <DialogContentText>
                </DialogContentText>
                <Paper
                  sx={{ mt:2, mb:2, display: 'flex', alignItems: 'center', width: '100%' }}
                >
                    <TextField
                      size="small"
                      variant="standard"
                      label="Keyword"
                      sx={{ flex:1 }}
                      value={query}
                      placeholder="Please input keywords to search URI"
                      inputProps={{ 'aria-label': 'query' }}
                      onChange={event=>{setQuery(event.target.value)}}
                      onKeyPress={e=>{
                        if (e.key === 'Enter') {
                          e.preventDefault();
                          searchURI(e);
                        }
                      }}
                    />
                    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                    {
                        working?
                        <CircularProgress size={30}/>:
                        <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions" onClick={searchURI}>
                            <SearchIcon />
                        </IconButton>
                    }
                    
                </Paper>
                <ComboBox value={selectedItem} list={candidates} keyfield="match" label="Search Result" onChange={onChangeURI}/>
                {
                    content &&
                    CreateHeader(content, ord, 1, true, clickHyperLink)
                }
                </DialogContent>
                <DialogActions>
                <Button onClick={props.handleClose}>Cancel</Button>
                <Button onClick={handleOK}>OK</Button>
                </DialogActions>
            </Dialog>

        </Container>
    )
}
