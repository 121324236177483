import { GlobalStateProvider } from '../../common/globalState';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { NavLink } from "react-router-dom";
import { Grid } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    minWidth: 345
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function ReviewCard(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            {props.avatar || "T"}
          </Avatar>
        }
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title={props.title || ""}
        subheader={props.subheader || ""}
        align="left"
      />
      <CardMedia
        className={classes.media}
        image={props.image || ""}
        title=""
      />
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p" align="left">
          {props.content || ""}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>

        <IconButton aria-label="go">
          <NavigateNextIcon />
          <NavLink
            to={props.target_url}
            style={({ isActive, isPending }) => {
              return {
                fontWeight: isActive ? "bold" : "",
                color: isPending ? "red" : "black",
                fontSize: 14,
              };
            }}
          >
            Go
          </NavLink>
        </IconButton>

        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          {
            props.details || ""
          }
        </CardContent>
      </Collapse>
    </Card>
  );
}

export function Admin(props) {
  return (
    <GlobalStateProvider>
      <div className="App">
        <header className="App-header">
          <Grid container spacing={3} style={{ display: "flex", justifyContent: "center" }}>
            <Grid item style={{ display: "flex", justifyContent: "center" }}>
              <ReviewCard
                title="Reserves"
                subheader=""
                avatar="R"
                content="Sets BTC/ETHER reserves."
                image="/asset/preferences.jfif"
                target_url="/mercoin/admin/preferences"
              />
            </Grid>
            <Grid item style={{ display: "flex", justifyContent: "center" }}>
              <ReviewCard
                title="MERc Serer"
                subheader=""
                avatar="S"
                content="starts or stops MERc server."
                image="/asset/server.jpg"
                target_url="/mercoin/admin/server"
              />
            </Grid>
            <Grid item style={{ display: "flex", justifyContent: "center" }}>
              <ReviewCard
                title="Monitoring"
                subheader=""
                avatar="M"
                content="monitors the status of MERc sever."
                image="/asset/monitoring.jfif"
                target_url="/mercoin/admin/monitor"
              />
            </Grid>
          </Grid>

        </header>
      </div>
    </GlobalStateProvider>
  )
}