import { Alert, AlertTitle, Button, CircularProgress, Grid, Paper, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { BSEARCH_URI } from "../common/config";
import { useGlobalState } from "../common/globalState";
import { personal_sign } from "../common/metamask";

import { Box } from "@mui/system";
import { useWeb3React } from "@web3-react/core";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { CreateHeader, request, setGlobalVal } from "./common-tool";
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { RDFEditView } from "./RDFEdit";
import AddIcon from '@mui/icons-material/Add';

async function search_rdf(query, address, fee, callback_fn) {
    var params = {}
    var reason = `Bsearch Query=${query}`;
    params.query = query;
    params.address = `${address}`;
    params.free = "false";
    if (fee > 0) {
        params.free = "false"
    }
        var seed = await new Promise(resolve => request(resolve, params.address, fee, reason));

        if (seed === '') {
            params.free = "true";
        }
        else {
            try {
            console.log(seed, address, "inside here")
            let vk = await personal_sign(seed, address);
            
            if(vk ){
                params.verification = vk;
                params.seed = seed;
              
                    //alert(JSON.stringify(params));
                  
                    axios.post(
                        // BSEARCH_URI + "rdf_query", params
                        BSEARCH_URI +  "composite_record" , {
                            "query" : query
                        }
                    )
                        .then(response => {
                            if (response && response.data) {
                                var result = response.data;
            
                                if (!result || Object.keys(result).length === 0) {
                                    result = { 'error': "The data does not exist in ACCZIOM database." };
                                }
            
                                callback_fn(result);
                            }
            
                        })
                
               
            }
            else{
                alert ('Trasaction denied by the user')
             
                var result = { 'error': "Trasaction denied by the user" };
                     callback_fn(result);
             }
        }
            catch (err) {
                
                var result = { 'error': err };
                callback_fn(result);
            }
           
           

        }



}

export function RDFViewJSON(props) {
    const [state, dispatch] = useGlobalState();
    const [onSpin, setSpin] = useState(false);
    const [content, setContent] = useState({});
    const [query, setQuery] = useState("");
    const [fee, setFee] = useState(200);
    const [payFee, setPayFee] = useState(0);
    const [mounted, setMounted] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [deletedData, setDeletedData] = useState([]);
    //const [isDisabled, setDisabled] = useState(true);
    const context = useWeb3React()
    const { connector, chainId, error } = context
    let isDisabled = error || !chainId

    const handlePay = (e) => {
        setPayFee(state.predictedFee);
    }

    const handleAdd = (e) => {
        setOpenEditDialog(true);
    }

    const onEditDialogClose = (e) => {
        setOpenEditDialog(false);
    }

    const clickHyperLink = (e) => {
        let attr = e.target.attributes;
        dispatch({
            subTitle: attr.metalabel.nodeValue,
            subURI: attr.metauri.nodeValue,
            openDetails: true,
        })
    };

    const refreshPage = () => {
        let newContent = {};
        Object.keys(content).map(e => {
            newContent[e] = content[e];
        })
        setContent(newContent);
    }
    const clickAgree = async (id, record) => {
        if (!state.logged) {
            alert("Please sign in to use this function.")
            return;
        }
        let res = await axios.post(BSEARCH_URI + "approve_record", {
            address: context.account,
            record: id,
            yes_no: "yes"
        });
        console.log(`agree request=${res}`)
        if (res.data == true) {
            let y = record['__yes'] || 0;
            record['__yes'] = y + 1;
            refreshPage();
        }
    }

    
    const clickDisagree = async (id, record) => {
        if (!state.logged) {
            alert("Please sign in to use this function.")
            return;
        }
        let res = await axios.post(BSEARCH_URI + "approve_record", {
            address: context.account,
            record: id,
            yes_no: "no"
        });
        if (res.data == true) {
            let n = record['__no'] || 0;
            record['__no'] = n + 1;
            refreshPage();
        }
    }

    const clickDelete = async (id, record) => {
        if (!state.logged) {
            alert("Please sign in to use this function.")
            return;
        }
        let res = await axios.post(BSEARCH_URI + "delete_record", {
            address: context.account,
            record: id,
        });
        if (res.data == true) {
            setDeletedData([...deletedData, record]);
        }
        else {
            alert(res.data);
            return;
        }
    }

    useEffect(() => {
        var q = "";
        if (state.entityID !== "") {
            q = state.entityID
        }
        else if (state.entityName.length > 0) {
            q = state.entityName[0]
        }

        if (q === "") {
            setContent([]);
            setSpin(false);
            return;
        }

        var f = payFee || 0;
        if (query !== q) { setQuery(q); setFee(200); }
        // if (f !== fee) setFee(f);

    }, [state.entityName, state.entityID, payFee, fee, query])

    useEffect(() => {
        if (query === "") return;
        let ff = fee
        if (query === state.rdfHistory.query && state.rdfHistory.fee >= fee) {
            setContent(state.rdfHistory.result);
            setSpin(false);
            if (state.rdfHistory.fee > 0) {
                setMounted(true);
            }
            return;
        }

        if (query !== state.rdfHistory.query) {
            ff = 0;
        }

        setSpin(true);

        // var address = `${context.account}`
        

        search_rdf(query, context.account,300, result => {
            if(result?.error){
                setSpin(false)
                setContent({
                    Message: "Need to pay 200 merc for data to be shown"
                })
            }
            else{

                var f = result.predicted_fee || 0;
                dispatch({
                    rdfHistory: { query: query, fee: ff, result: result },
                    predictedFee: f
                })
                delete result.predicted_fee
                var key_list = Object.keys(result)
                if (key_list.length === 0) {
                    result.error = 'The data does not exist in Accziom Database.'
                }
                else {
                    key_list.forEach(e => {
                        if (e !== "##order##") {
                            var props = result[e];
                            Object.keys(props).forEach(k => {
                                var val = props[k];
                                if (Array?.isArray(val)) val = val[0];
                                if (val?.value) val = val?.value
                                if (Array.isArray(val)) val = val[0];
                                setGlobalVal(k, val, state, dispatch);
                            })
                        }
                    })
                }
                setContent(result);
                setDeletedData([]);
                if (ff > 0) {
                    setMounted(true);
                }
                setSpin(false);
            }
        })

    }, [query, context.account])
    function jsonToCSV(jsonObject) {
        // Extract keys and values
        const keys = Object.keys(jsonObject);
        const values = keys.map(key => jsonObject[key]);
        const flattenedObject = flattenObject(jsonObject);

  // Create CSV content
  const headers = Object.keys(flattenedObject).join(",") + "\n";
  const valuesRow = Object.values(flattenedObject).map(value => `"${value}"`).join(",") + "\n";
  return headers + valuesRow;
}
        // Flatten nested objects
        const flattenObject = (obj, parent = '', res = {}) => {
          for (let key in obj) {
            let propName = parent ? parent + '.' + key : key;
            if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
              flattenObject(obj[key], propName, res);
            } else {
              res[propName] = obj[key];
            }
          }
          return res;
        }
    

    if (onSpin) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <CircularProgress size={50} />
            </Box>
        )
    } else {

        var ord = content['##order##'];
        return (
            <Box>
            
                <Grid container spacing={2}>
                    { <><Grid item xs={12}>
                        <Typography variant='h5'>
                            Composite record Data 
                        </Typography>
                    </Grid>

                    <Grid item xs={9}>
                        <Typography sx={{ pl: 4, pr: 2, textAlign: "left" }}>
                        </Typography>
                    </Grid> </>}

                 

                    <Grid item xs={12}>
                        {
                            content.error ?
                                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                    <Alert severity="info">
                                        <AlertTitle>Info</AlertTitle>
                                        {content.error}
                                    </Alert>
                                </Paper>
                                :
                                <Box sx={{ bgcolor: "#FAFAFA", padding: "26px" }}>
                                    <Box sx={{ bgcolor: "#FFF", py: "28px" }}>
                                        {CreateHeader(
                                            content,
                                            ord,
                                            1,
                                            true,
                                            {
                                                hyperlink: clickHyperLink,
                                                agree: clickAgree,
                                                disagree: clickDisagree,
                                                delete: clickDelete
                                            },
                                            deletedData
                                        )}
                                    </Box>
                                </Box>
                        }

                    </Grid>
                </Grid>
                <RDFEditView open={openEditDialog} handleClose={onEditDialogClose} uri={state.rdfHistory.result.uri} />
              {!content?.Message &&   <Button onClick={
                    ()=>{
                        
  const csvContent  = jsonToCSV(content)
                      let  CSVFile = new Blob([JSON.stringify(content)], {
                            type: "text/csv"
                        });
                    
                        let downloadLink = document.createElement("a");
                        downloadLink.href = URL.createObjectURL(CSVFile);
                        downloadLink.download = "data.jsonld";
                        downloadLink.click();
                        
                    }
                   
                }>
                    Download
                </Button>}
            </Box>

        )
    }
}