import React, { useState } from "react";
import { useGlobalState } from "../hooks";
import Link from "@mui/material/Link";
import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogActions,
  DialogContent,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { token_host, token_server_url } from "../config";
import BTCDetailContent from "./btcdetail";

const adr_columns = [
  {
    field: "btc_address",
    headerName: "BTC Address",
    width: 340,
    editable: false,
  },
  {
    field: "balance",
    headerName: "Total Balance",
    width: 120,
    editable: false,
  },
  {
    field: "current_requests",
    headerName: "Current Daily Requests",
    width: 120,
    editable: false,
  },
  {
    field: "maximum_requests",
    headerName: "Maximum Daily Requests",
    width: 120,
    editable: false,
  },
];

const hist_columns = [
  {
    field: "timestamp",
    headerName: "Time",
    type: "date",
    width: 200,
    editable: false,
  },
  {
    field: "type",
    headerName: "Transaction",
    width: 100,
    editable: false,
  },
  {
    field: "ledger",
    headerName: "Ledger",
    width: 150,
    editable: false,
  },
  {
    field: "dr",
    headerName: "Dr",
    width: 120,
    editable: false,
  },
  {
    field: "cr",
    headerName: "Cr",
    width: 120,
    editable: false,
  },
  {
    field: "amount",
    headerName: "Amount",
    width: 120,
    editable: false,
  },
  {
    field: "tx",
    headerName: "TXID",
    width: 600,
    editable: false,
  },
];

let btc_data = [
  { rows: [], columns: adr_columns },
  { rows: [], columns: hist_columns },
];

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiPaper-root": {
    width: "770px",
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export function BtcDetailInfo() {
  const [open, setOpen] = useState(false);
  const [adressData, setAddressData] = useState({ rows: [], columns: [] });
  const [histData, setHistData] = useState({ rows: [], columns: [] });
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Link
        component="button"
        variant="body2"
        onClick={async () => {
          setOpen(true);

          try {
            axios
              .get(token_server_url + "/get_exchange_records")
              .then(async (response) => {
                if (response && response.data) {
                  let res = response.data;
                  let exchange_records = [];
                  if (res.status) {
                    exchange_records = res.data;
                  }

                  var rr = [];
                  var nonce = 0;
                  var burn_mrc = {};
                  for (var i = 0; i < exchange_records.length; i++) {
                    var e = exchange_records[i];
                    var btc_item = {
                      timestamp: e.timestamp,
                      ledger: "BTC Reserves",
                      dr:
                        e.type === "mint" && e.btc_tx
                          ? e.btc_value.toLocaleString("en-US")
                          : "",
                      cr:
                        e.type === "burn" && e.btc_tx
                          ? e.btc_value.toLocaleString("en-US")
                          : "",
                      type: e.type,
                      amount: e.btc_tx
                        ? e.btc_reserves.toLocaleString("en-US")
                        : "",
                      tx: e.btc_tx ? e.btc_tx : "(Pending)",
                    };
                    var mrc_item = {
                      timestamp: e.timestamp,
                      ledger: "MERc Total Supply",
                      dr:
                        e.type === "mint" && e.mrc_tx
                          ? e.mrc_value.toLocaleString("en-US")
                          : "",
                      cr:
                        e.type === "burn" && e.mrc_tx
                          ? e.mrc_value.toLocaleString("en-US")
                          : "",
                      type: e.type,
                      amount: e.mrc_tx
                        ? e?.merc_totalsupply?.toLocaleString("en-US")
                        : "",
                      tx: e.mrc_tx ? e.mrc_tx : "(Pending)",
                    };
                    if (e.type === "mint") {
                      btc_item.key = nonce;
                      nonce += 1;
                      mrc_item.key = nonce;
                      nonce += 1;
                      rr.push(btc_item);
                      rr.push(mrc_item);
                    }
                    if (e.type === "burn") {
                      if (burn_mrc[e.mrc_tx] === undefined) {
                        mrc_item.key = nonce;
                        nonce += 1;
                        rr.push(mrc_item);
                        burn_mrc[e.mrc_tx] = 1;
                      }
                      btc_item.key = nonce;
                      nonce += 1;
                      rr.push(btc_item);
                    }
                  }
                  setHistData({ rows: rr, columns: hist_columns });
                }
              });
          } catch (err) {
            console.log(err);
          }

          try {
            axios
              .get(token_server_url + "/get_reserve_address_set")
              .then(async (response) => {
                if (response && response.data) {
                  var reserve_list = [];
                  let res = response.data;
                  if (res.status === true) {
                    reserve_list = res.data;
                  }
                  var rows = reserve_list.map((e, index) => ({
                    btc_address: e.address,
                    balance: e.balance.toLocaleString("en-US"),
                    current_requests:
                      e.current_requests.toLocaleString("en-US"),
                    maximum_requests: e.api_requests.toLocaleString("en-US"),
                    key: index,
                  }));

                  const { tb, tc, tm } = reserve_list.reduce(
                    (acc, item) => {
                      acc.tb += item.balance;
                      acc.tc += item.current_requests;
                      acc.tm += item.api_requests;
                      return acc;
                    },
                    { tb: 0, tc: 0, tm: 0 }
                  );
                  rows.push({
                    btc_address: "Total",
                    balance: tb.toLocaleString("en-US"),
                    current_requests: tc.toLocaleString("en-US"),
                    maximum_requests: tm.toLocaleString("en-US"),
                    key: -1,
                  });
                  setAddressData({ rows: rows, columns: adr_columns });
                }
              });
          } catch (err) {
            console.log(err);
          }
        }}
      >
        More Details
      </Link>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth={"100%"}
        sx={{
          ".MuiDialog-paper": {
            borderRadius: "20px",
            backgroundColor: "#333333",
          },
        }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          sx={{ color: "white" }}
        >
          BTC Reserve Details
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <BTCDetailContent adrData={adressData} histData={histData} />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} sx={{ color: "white" }}>
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
