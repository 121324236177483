import { useState } from "react";
import MintedRecordData from "./mintedRecordModal";

export default function MintedRecordsTable(props) {
    const { data, setSelectedRow, setShowModal, dataFilter, selectedDB } = props
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)

    const [selectedData, setSelectedData] = useState()
    const [showData, setShowData] = useState(false)
    return (
        <div className="mt-5">
            {selectedData && <MintedRecordData data={selectedData || {}} setShowModal={setShowData} showModal={showData} headerText={"Minted Record detailed data"} />}

            <h3>Minting records</h3>
            {
                (data &&
                    data?.length !== 0) ?
                    <>
                        <div className="table-responsive">
                            <table className="table table-striped table-hover text-center align-middle">
                                <thead className="table-primary">
                                    <tr>
                                        {data &&
                                            data?.length !== 0 &&
                                            dataFilter[selectedDB?.name].map((key, index) => (
                                                <th scope="col" key={index}>
                                                    {key.name}
                                                </th>
                                            ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data?.map((mintedOne, indx) => {
                                            if ((indx >= (page -1) * limit && indx < (((page-1) * limit) + limit)) || data?.length < 9) {
                          
                                            return (
                                                <tr
                                                    className="table"
                                                    key={indx}
                                                    onClick={() => {
                                                        setSelectedData(mintedOne);
                                                        setShowData(true);
                                                    }}>
                                                    <td>
                                                        {mintedOne.result?.['Legal Name'] ? mintedOne['result']['Legal Name'][0]['value'] : mintedOne['result']['Company Name'] ? mintedOne['result']['Company Name'][0]['value'] : mintedOne['result']['Entity Name'] ? mintedOne['result']['Entity Name'][0]['value'] : ""}
                                                    </td>
                                                    <td>{mintedOne.result?.ABN?.[0]['value'] || ''}</td>
                                                    <td>{mintedOne?.result?.['Postal code']?.[0]?.value || ''}</td>
                                                    <td>{mintedOne?.result?.State?.[0]?.value || ''}</td>
                                                </tr>
                                            );
                                        }
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="flex">
                            <button
                                style={{ background: "transparent", border: "none" }}
                                onClick={() => {

                                    page !== 1 && setPage(page - 1)
                                }}
                            >
                                &lt;
                            </button>
                            {page}
                            <button
                                style={{ background: "transparent", border: "none" }}
                                onClick={() => {
                                    (data?.length / limit > page && setPage(page + 1));
                                }}
                            >
                                &gt;
                            </button>
                        </div>
                        <div>
                            <b>total pages : </b>
                            {data?.length ? Math.ceil(data?.length / limit) : 0}
                        </div>
            
                        <div>
                            <b>total records : </b>
                            {data?.length ? data?.length : 0}
                        </div>
                    </>
                    :
                    <div>No data found
                    </div>
            }
        </div>
    )
}