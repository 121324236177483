import { Alert, AlertTitle, Button, CircularProgress, Grid, IconButton, Paper, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { BSEARCH_URI, GBG_API_COST } from "../common/config";
import { useGlobalState } from "../common/globalState";
import { Box } from "@mui/system";
import { CreateHeader, CreateProperty, request, setGlobalVal } from "./common-tool";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { useWeb3React } from "@web3-react/core";
// import { personal_sign } from "../common/metamask";
import { jsPDF } from 'jspdf';
import html2canvas from "html2canvas";
import PrintIcon from '@mui/icons-material/Print';
import config from "../config";
import { personal_sign } from "../merc/comm_token";

var gbgHeadOrd = { 'abstract': 0, 'details': 1, 'report': 1 }

async function search_gbg(query, address, callback_fn) {
    var params = {}
    var reason = `ANSIC Paid Query=${query}`;
    params.query = query;
    params.address = `${address}`;
    params.test = false;
    let fee = 5;

    if (fee > 0) {
        try {
            var signedMessage = await new Promise(resolve => request(resolve, params.address, fee, reason));

            if (signedMessage !== '') {
                let vk = await personal_sign(signedMessage, address);
                params.seed = signedMessage;
                params.verification = vk;
            }
            else {
                return null

            }
        }
        catch (err) {
            console.log(err)
            return null
        }

    }

    try {
        //alert(JSON.stringify(params));
        axios.post(
            BSEARCH_URI + "verify", params
        )
            .then(response => {
                if (response && response.data) {
                    var result = response.data;

                    if (!result || Object.keys(result).length === 0 || result.msg) {
                        result = { 'error': "The data does not exist in ASIC Paid database that we collected." };
                        callback_fn(result);
                        return;
                    }

                    console.log(JSON.stringify(result))
                    /*let keys = Object.keys(result);
                    result.abstract = {};
    
                    keys.forEach(key=>{
                        if (gbgHeadOrd[key]===undefined) {
                            let cont = result[key];
                            delete result[key]
                            result.abstract[key] = cont;
                        }
                    })*/
                    if (result.error) {
                        callback_fn(result);
                    } else {
                        callback_fn(result.report);
                    }
                    return;
                }
                else {
                    callback_fn({});
                }

            })
    }
    catch (err) {
        var result = { 'error': err };
        callback_fn(result);
    }
}

async function print_div(div_id, btn_id) {
    let title = 'content'
    var btn = document.getElementById(btn_id);
    btn.value = "Printing...";
    html2canvas(document.getElementById(div_id), {
        allowTaint: true,
        scale: 2
    })
        .then(function (canvas) {
            let contentWidth = canvas.width
            let contentHeight = canvas.height
            let pageHeight = contentWidth / 592.28 * 841.89
            let leftHeight = contentHeight
            let position = 0
            let imgWidth = 595.28
            let imgHeight = 592.28 / contentWidth * contentHeight
            let pageData = canvas.toDataURL('image/jpeg', 1.0)
            let PDF = new jsPDF('', 'pt', 'a4')
            if (leftHeight < pageHeight) {
                PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
            } else {
                while (leftHeight > 0) {
                    PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
                    leftHeight -= pageHeight
                    position -= 841.89
                    if (leftHeight > 0) {
                        PDF.addPage()
                    }
                }
            }
            PDF.save(title + '.pdf');
            btn.value = "Print";
            //console.log(PDF)
        })

    return;
    /*
    var btn = document.getElementById(btn_id);
    btn.value = "printing...";
    var target = document.getElementById(div_id);
    var pdf = new jsPDF('p', 'px', 'a4');
    var currentPosition = target.scrollTop;
    var w = 595;
    var h = 841.89;
    //document.getElementById("content").style.height="auto";	
    var originWidth = target.style.width;
    var originHeight = target.style.height;
    //target.style.width = "595px";
    target.style.height = "auto";
    var ww = target.offsetWidth;
    var hh = target.offsetHeight;
    var r = w / ww;
    h = 841.89 / r;
    
    pdf.setFont('Verdana', 'normal');
    pdf.setFont('sans-serif', 'normal');
    pdf.setFont('Lucida Sans', 'normal');

    pdf.html(target, {
        html2canvas: {
            scale: r/1.5,
            //windowHeight: div.scrollHeight+20,
            //600 is the width of a4 page. 'a4': [595.28, 841.89]
        },
        callback: function () {
            let data = pdf.output();

            var saveByteArray = (function () {
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                //window.URL.createObjectURL = jest.fn();
                return function (data, name) {
                    var blob = new Blob(data, {type: "octet/stream"});
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        // compatible to IE/Edge
                        window.navigator.msSaveOrOpenBlob(blob, "content.pdf")
                    } else {
                        var tmpobj = window.URL && window.URL.createObjectURL ? window.URL : "";
                        var url = tmpobj.createObjectURL(blob)
                        var a = document.createElement('a');
                        a.href = url;
                        a.style.display = 'download';
                        a.setAttribute('download', "content.pdf")
                        document.body.appendChild(a)
                        a.click()
                        document.body.removeChild(a)
                        tmpobj.revokeObjectURL(url) 
                    }
                };
            }());

            saveByteArray([data], "content.pdf");
            btn.value= "Print";
            target.style.height = originHeight;
            //alert(data);
            //window.open(data);
        }
    });
    */
}


export function GBGView(props) {
    const [state, dispatch] = useGlobalState();
    const [onSpin, setSpin] = useState(false);
    const [content, setContent] = useState({});
    const [query, setQuery] = useState("");
    const [mounted, setMounted] = useState(false);
    const context = useWeb3React()
    // const { connector, chainId, activate, deactivate, error } = context
    // let isDisabled = error || !chainId
    let isDisabled = false;

    const address = config.static_account_address;
    const handlePay = () => {
        setSpin(true);
        search_gbg(query, address, result => {
            setContent(result);
            setSpin(false);
            if (result.error) {
                dispatch({
                    gbgHistory: { query: "", result: {} }
                })
            } else {
                dispatch({
                    gbgHistory: { query: query, result: result }
                })
                setMounted(true);
            }

        })
    }
    useEffect(() => {
        if (query === "") {
            setContent({ 'error': "No Found ACN Number: This information required ACN number." });
            return;
        }
        if (query === state.gbgHistory.query) {
            setContent(state.gbgHistory.result);
            setMounted(true);
            setSpin(false);
            return;
        }
        setContent([]);

    }, [query])

    const handlePrint = () => {
        print_div("gbgview_id", "gbgview_print")
    }

    useEffect(() => {
        var q = "";
        if (state.entityID.length === 9) {
            q = state.entityID
        }
        if (state.acn !== "") {
            q = state.acn
        }

        if (q === "") {
            setQuery(q);
            setContent([]);
            setSpin(false);
            return;
        }
        if (q === query) {
            setSpin(false);
            return;
        }

        setQuery(q);

    }, [state.entityID, state.acn])


    if (onSpin) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <CircularProgress size={50} />
            </Box>
        )
    } else {

        return (
            <div >
                <Box textAlign={'right'}>
                    <Button onClick={handlePrint} id="gbgview_print" startIcon={<PrintIcon />}>
                        Print
                    </Button>
                </Box>
                <Grid container spacing={2} id="gbgview_id">
                    <Grid item xs={8}>
                        <Typography variant='h5'>
                            ASIC Paid Data Search Result
                        </Typography>
                    </Grid>

                    <Grid item xs={4}>
                        <Box textAlign={'right'}>

                            <Button disabled={isDisabled || mounted || (query == "")} variant="contained" color="success" onClick={handlePay} sx={{ borderRadius: 10, height: 40 }} >
                                <LockOpenIcon sx={{ mr: 2 }} />
                                Pay {5} MERc
                            </Button>

                        </Box>

                    </Grid>

                    <Grid item xs={12}>
                        <Typography sx={{ pl: 4, pr: 2, textAlign: 'left' }}>
                            {"This pane displays the information of legal entity from Australian Securities & Investments Commission(ASIC) non-public data. It uses GBG Identity Verification api with payments. Currently, this service has been temporarily stopped."}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Paper sx={{ p: 4 }}>
                            {
                                query === "" ?
                                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                        <Alert severity="info">
                                            <AlertTitle>Info</AlertTitle>
                                            Not found ACN identifier.
                                        </Alert>
                                    </Paper>
                                    :
                                    content.error ?
                                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                            <Alert severity="info">
                                                <AlertTitle>Info</AlertTitle>
                                                {content.error}
                                            </Alert>
                                        </Paper>
                                        :
                                        CreateHeader(content, gbgHeadOrd, 3)
                            }
                            {

                            }

                        </Paper>
                    </Grid>
                </Grid>
            </div>

        )
    }
}