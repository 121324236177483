
export default function Toaster(props) {
    const {text, heading} = props
    return (
        <div
        className={`toast show`}
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
        style={{ opacity:1 }}
      >
        <div className="toast" role="alert" aria-live="assertive" aria-atomic="true">
          <div className="toast-header">
            <strong className="me-auto">{heading}</strong>
            <button
              type="button"
              className="btn-close"
            //   onClick={toggleShow}
            ></button>
          </div>
          <div className="toast-body">{text}</div>
        </div>
      </div>
    )

}
