import { Web3Provider } from "@ethersproject/providers";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useGlobalState } from "../common/globalState";

import {
  Alert,
  AlertTitle,
  Dialog,
  DialogTitle,
  IconButton,
  Stack,
  TextField
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { useWeb3React } from "@web3-react/core";
import PropTypes from "prop-types";
import { AmountTextField } from "./amountText";
import {
  estimate_gas_fee,
  secure_query_with_type_data,
  secure_transaction,
} from "./comm_token";
import { GasLimitOption } from "./components/GasLimitOption";
import { Layer2Check } from "./hooks";
import config from "../config";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": {
    borderColor: "green",
    borderWidth: 2,
  },
  "& input:invalid + fieldset": {
    borderColor: "red",
    borderWidth: 2,
  },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 6,
    padding: "4px !important", // override inline-style
  },
});

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const button_style = {
  color: "floralwhite",
  borderColor: "floralwhite",
  borderRadius: 15,
  fontSize: "12px",
  width: "200px",
  height: "40px",
};

const dialog_button_style = {
  enabledButton: {
    color: "white",
    borderColor: "rgba(255, 255, 255, 0.12)",
    borderRadius: 16,
    fontSize: "12px",
    width: "200px",
    height: "40px",
    backgroundColor: "rgba(255, 255, 255, 0.06)",
  },
  disabledButton: {
    borderColor: "rgba(255, 255, 255, 0.12)",
    borderRadius: 16,
    fontSize: "12px",
    width: "200px",
    height: "40px",
    color: "rgb(255, 255, 255, 0.26)",
    backgroundColor: "rgba(0, 0, 0, 0.12)",
  },
};

const dialog_text_style = {
  "& .MuiInputBase-input": {
    color: "white", // Text color
  },
  "& .MuiInput-input": {
    width: "300px", // Text color
  },
  "& .MuiInputLabel-root": {
    color: "white", // Text color
  },
  "& .MuiInput-underline:before": {
    borderBottomColor: "white", // Semi-transparent underline
  },
  "& .MuiInput-underline:hover:before": {
    borderBottomColor: "white", // Solid underline on hover
  },
  "& .MuiInput-underline:hover:after": {
    borderBottomColor: "white", // Solid underline on hover
  },
  ".Mui-error": {
    color: "white !important",
  },
  ".Mui-error:after, .Mui-error:before": {
    borderBottomColor: "white !important",
  },
};

const unit_style = {
  "& .MuiTypography-root": {
    color: "#d8aa0cb8", // Text color
    fontSize: "10px",
  },
  container: {
    alignItems: "baseline",
  },
};

export function SendButton() {
  const context = useWeb3React();
  const [open, setOpen] = useState(false);
  const [targetAddress, setTargetAddress] = useState("");
  const [state, dispatch] = useGlobalState();
  const [view, setView] = useState(false);
  const [error, setError] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [gasFee, setGasFee] = useState(1);

  useEffect(() => {
    if (state.inputAmountError) {
      setBtnDisabled(true);

      return;
    }
    if (targetAddress === "") {
      setBtnDisabled(true);
    }
    setBtnDisabled(false);
  }, [state.inputAmountError, targetAddress]);
  const handleChangeAddress = (newValue) => {
    setTargetAddress(newValue.target.value.trim());
  };

  const estimateGasFee = async (event) => {
    let res = await estimate_gas_fee({
      type: "transfer",
    });
    setGasFee(res);
  };

  console.log("state", state);

  const handleClickSend = async () => {
    setError("");
    if (parseInt(state.amount) <= parseInt(gasFee)) {
      setError("Amount is less than gas limit.");
      return;
    }
    setView(true);
    state.mrcAddress = config.static_account_address;

    if (state.layer2) {
      var res = await secure_transaction(
        state.mrcAddress,
        state.amount,
        "transfer",
        targetAddress
      );
      console.log(`transfer result=${res}`);
    } else {
      try {
        if (gasFee > 0) {
          let result = await secure_query_with_type_data(
            {
              address: config.static_account_address,
              type: "call_transfer",
              from: config.static_account_address,
              to: targetAddress,
              amount: state.amount,
              gasLimit: gasFee,
              kind: "transfer",
            },
            state.exchanger,
            state.merc_contract_address
          );
          if (result.status != true) {
            if (result.error) {
              setError(result.error);
            } else {
              setError(
                "The requried gas fee exceeded the given gas limit. Please increase the gas limit."
              );
            }
            setView(false);
            return;
          }
        } else {
          var provider = new Web3Provider(window.ethereum);
          await state.mrc20_contract
            .connect(provider.getSigner())
            .transfer(targetAddress, state.amount);
        }
      } catch (err) {
        setError("Canceled Transaction");
        setView(false);
        return;
      }
    }
    setView(false);
    setOpen(false);
  };
  const handleClickOpen = () => {
    dispatch({
      amount: "",
      layer2: true,
      inputAmountError: true,
    });
    setError("");
    setView(false);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {view ? (
        <Alert severity="info" sx={{ marginBottom: "10px" }}>
          <AlertTitle>Info</AlertTitle>
          Transaction is submitting
        </Alert>
      ) : (
        <div></div>
      )}
      {error !== "" ? (
        <Alert severity="error" sx={{ marginBottom: "10px" }}>
          <AlertTitle>Error</AlertTitle>
          {error}
        </Alert>
      ) : (
        <div></div>
      )}
      <Stack
        direction="column"
        spacing={{ xs: 1, sm: 1, md: 3 }}
        justifyContent="center"
        alignItems="center"
      >
        <div>Please enter target address and amount what you send to.</div>
        <div>
          <TextField
            error={targetAddress === ""}
            helperText={targetAddress === "" ? "Required" : ""}
            sx={dialog_text_style}
            onChange={handleChangeAddress}
            required
            id="target-address"
            label="To"
            variant="standard"
            value={targetAddress}
          />
        </div>
        <div>
          <AmountTextField
            max={state.layer2 ? state.mrc2Balance : state.mrcBalance}
          />
        </div>
        <div>
          <Layer2Check label="Send in Layer2" />
        </div>
        {state.layer2 == false && (
          <div>
            <GasLimitOption
              defaultValue={gasFee}
              setValue={setGasFee}
              min={1}
              estimate={estimateGasFee}
            />
          </div>
        )}

        <Button
          variant="outlined"
          style={
            btnDisabled
              ? dialog_button_style.disabledButton
              : dialog_button_style.enabledButton
          }
          onClick={handleClickSend}
        >
          Send
        </Button>
      </Stack>
    </div>
  );
}
