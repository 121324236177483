import { Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, MenuItem, Paper, Select, Stack, Table, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { Fragment, useEffect, useState } from "react";
import { useGlobalState } from "../common/globalState";
import { DataGrid } from '@mui/x-data-grid';
import axios from "axios";
import { BSEARCH_URI } from "../common/config";
import { CreateHeader, CreateProperty } from "../search/common-tool";

export function NFTCombo(props) {
    const [state, dispatch] = useGlobalState();
    const [nftName, setNFTName] = useState("");
    const [nftList, setNFTList] = useState([]);
    const [open, setOpen] = useState(false);
    const [nftTextVal, setNFTTextVal] = useState("");

    const handleOK = () => {
        let list = nftList.slice(0, nftList.length-1);
        list.push(nftTextVal);
        list.push('Add new name');
        setNFTList(list);
        setNFTName(nftTextVal);
        props.setNFTName(nftTextVal);
        setOpen(false);
    }

    const handleClickOpen = () => {
        setOpen(true);
    };
    
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(()=>{
        let list = [];
        if (state.nft) {
            list = state.nft.map(e=>e.Name);
        }
        list.push('Add new name');
        setNFTList(list);
    }, [state.nft])

    useEffect(()=>{
        if (state.nft) {
            for (var i = 0 ; i < state.nft.length; i++) {
                var e = state.nft[i];
                if (e.Name === nftName) {
                    if (props.setNFTID) props.setNFTID(e.NFTID);
                    if (props.setNFTURI)  props.setNFTURI(e.URI);
                    if (props.setTotalReward) props.setTotalReward(e['Total Rewards']);
                    if (props.onChange) props.onChange();
                    return;
                }
            }
        }
        
        if (props.setNFTID) props.setNFTID('');
        if (props.setNFTURI) props.setNFTURI('');
        if (props.setTotalReward) props.setTotalReward(0);
        if (props.onChange) props.onChange();
    }, [nftName])
    return (
        <Container>
            <FormControl
                sx={{
                width: '100%'
                }}
                size="small"
                disabled={props.disabled||false}
            >
                <Select
                value={nftName}
                onChange={(event) => {
                    const { value } = event.target;
                    if (value === 'Add new name') {
                        handleClickOpen();
                        return;
                    }
                    setNFTName(value);
                    props.setNFTName(value);
                }}
                >
                {
                    nftList.map((key, index) => (
                    <MenuItem
                        value={key}
                        key={index}
                    >
                        <Typography
                        color="textPrimary"
                        variant="body2"
                        >
                        {key}
                        </Typography>
                    </MenuItem>
                    ))
                }
                </Select>
            </FormControl>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Add New NFT Name</DialogTitle>
                <DialogContent>
                <DialogContentText>
                    To add new NFT name, please enter NFT name.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="NFT Name"
                    fullWidth
                    variant="standard"
                    value={nftTextVal}
                    onChange={(event)=>{setNFTTextVal(event.target.value)}}
                />
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleOK}>OK</Button>
                </DialogActions>
            </Dialog>

        </Container>
    )
}

function NFTDetails(props) {
    return (
        <Stack>
            <Box sx={{mt:4}}>
                <Typography variant="h6">
                    NFT ID
                </Typography>
            </Box>
            <Box sx={{pl:4}}>
                {
                    props.nftID === '' ? 
                    <Button variant="contained" sx={{width:'100px'}}>
                        Mint
                    </Button>
                    :
                    <Typography>
                        {props.nftID}
                    </Typography>
                }
            </Box>
            <Box sx={{mt:4}}>
                <Typography variant="h6">
                    NFT URI
                </Typography>
            </Box>
            <Box sx={{pl:4}}>
                {
                    props.nftURI
                }
            </Box>
            <Box sx={{mt:4}}>
                <Typography variant="h6">
                    Total Rewards
                </Typography>
            </Box>
            <Box sx={{pl:4}}>
                {
                    props.totalReward + " MERc"
                }
            </Box>
        </Stack>
            
    )
}

function NFTRecords(props) {
    return (
        <Stack>
            <Box sx={{mt:4}}>
                <Typography variant="h6">
                    Details
                </Typography>
            </Box>
            {props.loading ? <CircularProgress/> :
                 
                props.data.map((e,index)=>{
                    return (
                        <Paper key={index} sx={{mt:4}}>
                            {
                                CreateProperty("", e, null, false)
                            }
                        </Paper>
                    )
                })
            }
        </Stack>
    )
}

export function NFTView(props) {
    const [state, dispatch] = useGlobalState();
    const [nftName, setNFTName] = useState('');
    const [nftID, setNFTID] = useState('');
    const [nftURI, setNFTURI] = useState('');
    const [totalReward, setTotalReward] = useState(0);
    const [records, setRecords] = useState([]);
    const [loading, setLoading] = useState(false);

    const clickHyperLink = (e) => {
        console.log('click event')
        console.log(e);
        let attr = e.target.attributes;
        dispatch({
            subTitle: attr.metalabel.nodeValue,
            subURI: attr.metauri.nodeValue,
            openDetails: true,
        })
    };

    const search_nft = async (id) => {
        setLoading(true);
        console.log('loading=true');
        let result = await axios.post(BSEARCH_URI + "nftinfo", {
            id: id
        })
        setRecords(result.data);
        console.log('loading=false')
        setLoading(false);
    }

    useEffect(()=>{
        if (nftURI !== '') {
            search_nft(nftURI)
        } else {
            setRecords([])
        }
    }, [nftURI])

    if (state.logged === false) {
        return (
            <Container component={Paper} sx={{ mt: 4, mb: 4, display:props.show?'block':'none', minHeight:400}}>
                <Box position="sticky" top='20%'>
                <Typography >
                    Please sign in to explore your NFT information.
                </Typography>

                </Box>
            </Container>
        )
    } else {
        return (
            <Container component={Paper} direction="row"  sx={{ mt:4, display:props.show?'flex':'none', justifyContent:"center", minHeight:800 }}>
                <Box sx={{width:'80%'}}>
                    <Grid container sx={{mt:4, p:2, display:'flex', justifyContent:"left", textAlign:'left'}}>
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                NFT Name
                            </Typography>
                        </Grid>
                        <Grid item xs={12}  sx={{top:"-50px", right: "-50px", position:"relative"}}>
                            <Box display="flex" flexDirection="row-reverse">
                            {
                                props.back &&
                                <Button
                                    sx={{
                                        px: 5, mr: 2
                                    }}
                                    variant="contained"
                                    color="success"
                                    onClick={()=>{dispatch({pane: ''})}}
                                >
                                    Close
                                </Button>
                            }
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <NFTCombo setNFTName={setNFTName} setNFTID={setNFTID} setNFTURI={setNFTURI} setTotalReward={setTotalReward} />
                            {
                                nftURI !== '' && <NFTDetails nftID={nftID} nftURI={nftURI} totalReward={totalReward}/>
                            }
                            {
                                nftURI !== '' && <NFTRecords data={records} loading={loading} clickHyperLink={clickHyperLink}/>
                            }
                            
                        
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        )
    }
}