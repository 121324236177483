import { Button, Container, Grid, Paper, Table, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { Fragment, useEffect, useState } from "react";
import { useGlobalState } from "../common/globalState";
import { DataGrid } from '@mui/x-data-grid';

function ledger_to_string(type) {
    switch(type) {
      case 1:
        return "Layer1";
      case 2:
        return "Layer2";
      case 3:
        return "Satoshi";
      default:
        return "";
    }
}

function balance_from_ledger(bal, led) {
    switch(led) {
        case 1:
            if (bal.layer1) return bal.layer1;
            return "";
        case 2:
            if (bal.layer2) return bal.layer2;
            return "";
        case 3:
        return "";
        default:
        return "";
    }
}

const columns = [
    {
      field: 'time',
      headerName: 'Time',
      type: 'date',
      width: 200,
      editable: false,
    },
    {
      field: 'event',
      headerName: 'Transaction',
      width: 120,
      editable: false,
    },
    {
      field: 'ledger',
      headerName: 'Ledger',
      width: 120,
      editable: false,
    },
    {
      field: 'dr',
      headerName: 'Dr',
      width: 80,
      editable: false,
    },
    {
      field: 'cr',
      headerName: 'Cr',
      width: 80,
      editable: false,
    },
    {
      field: 'balance',
      headerName: 'Balance',
      width: 120,
      editable: false,
    },
    {
      field: 'detail',
      headerName: 'Description',
      width: 1200,
      editable: false,
      hideable: false,
    },
];

export function MERCView(props) {
    const [state, dispatch] = useGlobalState();
    const [rows, setHistory] = useState([]);

    useEffect(()=>{
        if (state.mercTransaction === null) return;
        var hist = state.mercTransaction.map(e=>({
            time: e.time,
            event: e.event,
            ledger: ledger_to_string(e.ledger),
            dr: e.event!=="balance" && e.fee && parseFloat(e.fee) > 0 ? e.fee.toLocaleString('en-US') : "",
            cr: e.event!=="balance" && e.fee && parseFloat(e.fee) < 0 ? (-parseFloat(e.fee)).toLocaleString('en-US') : "",
            balance: balance_from_ledger(e.balance, e.ledger).toLocaleString('en-US'),
            detail: e.detail
        }));
        setHistory(hist);
    }, [state.mercTransaction])

    if (state.logged === false) {
        return (
            <Container component={Paper} sx={{ mt: 4, mb: 4, display:props.show?'block':'none', minHeight:400}}>
                <Box position="sticky" top='20%'>
                <Typography >
                    Please sign in to explore your MERC wallet.
                </Typography>

                </Box>
            </Container>
        )
    } else {
        return (
            <Paper sx={{ display:props.show?'flex':'none'}}>
                <Grid container sx={{display: 'flex'}}>

                <Grid item xs={12}  sx={{top:"30px", position:"relative"}}>
                    <Box display="flex" flexDirection="row-reverse">
                    {
                        props.back &&
                        <Button
                            sx={{
                                px: 5, mr: 2
                            }}
                            variant="contained"
                            color="success"
                            onClick={()=>{dispatch({pane: ''})}}
                        >
                            Close
                        </Button>
                    }
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Container direction="row"  sx={{ display:props.show?'flex':'none', justifyContent:"center", minHeight:800 }}>
                        <Box sx={{ width: '80%' }}>
                        <Container component={Paper} sx={{mt:4, mb:4, p:2}}>

                            <Box sx={{display:'flex', justifyContent:'left', ml:4}}>
                                <Typography variant="h5" sx={{mb:4}}>
                                    Balance
                                </Typography>
                            </Box>
                        <Grid container sx={{mb:4}} spacing={1}>
                            <Grid item xs={5}>
                                <Typography>
                                    Total MERc
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>
                                    { state.mercBalance.layer1 && state.mercBalance.layer2 && (parseInt(state.mercBalance.layer1) + parseInt(state.mercBalance.layer2)).toLocaleString('en','US') + " MERc"}
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography sx={{ml:10}}>
                                    Layer1
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>
                                    { state.mercBalance.layer1 && state.mercBalance.layer1.toLocaleString('en', 'US') + " MERc"}
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography sx={{ml:10}}>
                                    Layer2
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>
                                    { state.mercBalance.layer2 && parseInt(state.mercBalance.layer2).toLocaleString('en', 'US') + " MERc"}
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography>
                                    Ether
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>
                                    {state.mercBalance.eth && parseFloat(state.mercBalance.eth).toFixed(4) + " ETHER"}
                                </Typography>
                            </Grid>
                        </Grid>
                        </Container>

                        <Container component={Paper} sx={{mt:4, mb:4, p:2}}>

                            <Box sx={{display:'flex', justifyContent:'left', ml:4}}>
                                <Typography variant="h5" sx={{mb:4}}>
                                    Transaction History
                                </Typography>
                            </Box>
                            <Box>
                                <DataGrid
                                    rows={rows}
                                    columns={columns}
                                    pageSize={5}
                                    rowsPerPageOptions={[5]}
                                    getRowId={(row) => row.time}
                                    initialState={{
                                    sorting: {
                                        sortModel: [{ field: 'time', sort: 'asc' }],
                                    },
                                    }}
                                    sx={{height:400}}
                                    disableSelectionOnClick
                                />

                            </Box>
                        </Container>
                            
                    </Box>
                    </Container>
                </Grid>
            </Grid>
                
            </Paper>
            
        )
    }
}