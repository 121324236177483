import { Web3Provider } from "@ethersproject/providers";
import {
  Alert,
  AlertTitle,
  Button,
  DialogActions,
  DialogContent,
  IconButton,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useGlobalState } from "../common/globalState";
import { BTCAccountTextField, Layer2Check } from "./hooks";
import { AmountTextField, MERcTextField } from "./amountText";
import button_style from "./style/button_style";
import dialog_button_style from "./style/dialog_button_style";
import {
  BootstrapDialog,
  BootstrapDialogTitle,
} from "./components/bootstrap_dialog";
import { GasLimitOption } from "./components/GasLimitOption";
import { estimate_gas_fee, secure_query_with_type_data } from "./comm_token";
import { useWeb3React } from "@web3-react/core";
import { Spinner } from "./Spiner";
import FlipCameraAndroidIcon from "@mui/icons-material/FlipCameraAndroid";

export function SellMERCButton() {
  const context = useWeb3React();
  const [open, setOpen] = useState(false);
  const [view, setView] = useState(false);
  const [error, setError] = useState("");
  const [state, dispatch] = useGlobalState();
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [gasFee, setGasFee] = useState(2);
  const [satoshiGasFee, setSatoshiGasFee] = useState(1040);
  const [running, setRun] = useState(false);

  useEffect(() => {
    if (state.inputAmountError) {
      setBtnDisabled(true);
      return;
    }
    if (state.btcAddress === "") {
      setBtnDisabled(true);
      return;
    }
    setBtnDisabled(false);
  }, [state.inputAmountError]);

  const estimateGasFee = async (event) => {
    let res = await estimate_gas_fee({
      type: "sell",
      address: context.account,
      ledger: state.layer2 ? 2 : 1,
      amount: state.amount,
    });
    setGasFee(res);
  };

  const handleEstimateClick = async (event) => {
    setRun(true);
    let res = await estimate_gas_fee({
      type: "sending",
      btc_address: state.btcAddress,
      amount: state.amount,
      mrc_tx: "TEST_ESTIMATE",
    });
    setSatoshiGasFee(res);
    setRun(false);
  };

  const handleClickSell = async () => {
    setError("");
    if (parseInt(state.amount) <= parseInt(gasFee)) {
      setError("Amount is less than gas limit.");
      return;
    }
    setView(true);
    try {
      if (gasFee > 0) {
        let result = await secure_query_with_type_data(
          {
            address: context.account,
            type: "call_sell_merc",
            coinType: "BTC",
            btcAddress: state.btcAddress,
            amount: state.amount,
            layer2: state.layer2,
            gasLimit: gasFee,
            kind: "sell",
            satoshiGasLimit: satoshiGasFee,
          },
          state.exchanger,
          state.merc_contract_address
        );
        if (result.status != true) {
          if (result.error) {
            setError(result.error);
          } else {
            setError(
              "The requried gas fee exceeded the given gas limit. Please increase the gas limit."
            );
          }
          setView(false);
          return;
        }
      } else {
        var provider = new Web3Provider(window.ethereum);
        var res = await state.mrc20_contract
          .connect(provider.getSigner())
          .sellMERC(
            "BTC",
            state.btcAddress,
            state.amount,
            state.layer2,
            gasFee,
            satoshiGasFee
          );
      }
    } catch (err) {
      setError("Canceled the transaction.");
      console.log(err);
      setView(false);
      return;
    }
    setView(false);
    setOpen(false);
  };

  const handleClickOpen = () => {
    dispatch({
      amount: "",
      layer2: true,
      inputAmountError: true,
    });
    setGasFee(2);
    setSatoshiGasFee(1040);
    setError("");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {view ? (
        <Alert severity="info">
          <AlertTitle>Info</AlertTitle>
          Transaction is submitting. You can close this dialog.
        </Alert>
      ) : null}
      {error !== "" ? (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {error}
        </Alert>
      ) : (
        <div></div>
      )}
      <Stack
        direction="column"
        spacing={{ xs: 1, sm: 1, md: 1 }}
        justifyContent="center"
        alignItems="center"
      >
        <div>Please enter Bitcoin address and amount.</div>
        <div>
          <BTCAccountTextField label="To" />
        </div>
        <div>
          <AmountTextField
            max={state.layer2 ? state.mrc2Balance : state.mrcBalance}
            min={satoshiGasFee + 1000}
          />
        </div>
        <div>
          <Layer2Check label="Withdraw from Layer2" />
        </div>
        <div>
          <GasLimitOption
            defaultValue={gasFee}
            setValue={setGasFee}
            min={2}
            estimate={estimateGasFee}
          />
        </div>
        <Stack direction="row">
          <MERcTextField
            value={satoshiGasFee}
            setValue={setSatoshiGasFee}
            min={0}
            label="Satoshi Gas Fee"
            unit="Satoshi"
          />
          <div>
            {running ? (
              <Spinner
                color={"red"}
                style={{ height: "50%", marginTop: "5px" }}
              />
            ) : (
              <IconButton onClick={handleEstimateClick} sx={{ color: "white" }}>
                <FlipCameraAndroidIcon />
              </IconButton>
            )}
          </div>
        </Stack>
        <Button
          disabled={btnDisabled}
          variant="outlined"
          style={
            btnDisabled
              ? dialog_button_style.disabledButton
              : dialog_button_style.enabledButton
          }
          onClick={handleClickSell}
        >
          Sell
        </Button>
      </Stack>
    </div>
  );

  return (
    <div>
      <Button variant="outlined" style={button_style} onClick={handleClickOpen}>
        Sell MERc
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth={"xs"}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Selling MERc
        </BootstrapDialogTitle>
        <DialogContent dividers>
          {view ? (
            <Alert severity="info">
              <AlertTitle>Info</AlertTitle>
              Transaction is submitting. You can close this dialog.
            </Alert>
          ) : null}
          {error !== "" ? (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {error}
            </Alert>
          ) : (
            <div></div>
          )}
          <Stack
            direction="column"
            spacing={{ xs: 1, sm: 1, md: 3 }}
            justifyContent="center"
            alignItems="center"
          >
            <div>Please enter Bitcoin address and amount.</div>
            <div>
              <BTCAccountTextField label="To" />
            </div>
            <div>
              <AmountTextField
                max={state.layer2 ? state.mrc2Balance : state.mrcBalance}
                min={satoshiGasFee + 1000}
              />
            </div>
            <div>
              <Layer2Check label="Withdraw from Layer2" />
            </div>
            <div>
              <GasLimitOption
                defaultValue={gasFee}
                setValue={setGasFee}
                min={2}
                estimate={estimateGasFee}
              />
            </div>
            <Stack direction="row">
              <MERcTextField
                value={satoshiGasFee}
                setValue={setSatoshiGasFee}
                min={0}
                label="Satoshi Gas Fee"
                unit="Satoshi"
              />
              <div>
                {running ? (
                  <Spinner
                    color={"red"}
                    style={{ height: "50%", marginTop: "5px" }}
                  />
                ) : (
                  <IconButton onClick={handleEstimateClick}>
                    <FlipCameraAndroidIcon />
                  </IconButton>
                )}
              </div>
            </Stack>
            <Button
              disabled={btnDisabled}
              variant="outlined"
              style={
                btnDisabled
                  ? dialog_button_style.disabledButton
                  : dialog_button_style.enabledButton
              }
              onClick={handleClickSell}
            >
              Sell
            </Button>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
