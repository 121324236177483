import { Alert, Button, Paper, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useState } from "react";
import { token_admin_server_url, token_server_url } from "../config";

export function AdminServer(props) {
  const [MercBuildInfo, setMercBuildInfo] = useState("")
  const [NFTBuildInfo, setNFTBuildInfo] = useState("")
  const [ServerInfo, setServerInfo] = useState("")
  
  const onBuild20 = (evt) => {
    axios.get(token_admin_server_url + '/build_mrc20')
      .then(async response => {
        if (response && response.data) {
          let res = response.data;
          if (res.status == true) {
            setMercBuildInfo(`Build successed. The new MERc contract address is ${res.data.address}`);
          }
          else {
            setMercBuildInfo(`Build failed. ${res.error}`);
          }
        }
      })
      .catch(err => {
        setMercBuildInfo(`Error: ${err}`);
        //alert("Can not connect to Admin Server.")
      })

  }
  const onBuild721 = (evt) => {
    axios.get(token_admin_server_url + '/build_mrc721')
      .then(async response => {
        if (response && response.data) {
          let res = response.data;
          if (res.status == true) {
            setNFTBuildInfo(`Build successed. The new Accziom NFT contract address is ${res.data.address}`);
          }
          else {
            setNFTBuildInfo(`Build failed. ${res.error}`);
          }
        }
      })
      .catch(err => {
        setNFTBuildInfo(`Error: ${err}`);
        //alert("Can not connect to Admin Server.")
      })

  }

  const check = async (resolve) => {
    try{
      var res = await axios.get(token_server_url+'/healthcheck')
      console.log(res.data)
      resolve(true)
    }
    catch(err) {
      resolve(false)
    }
  }

  const onRestart = (evt) => {
    axios.get(token_admin_server_url + '/restart_merc')
    .then(async response => {
      if (response && response.data) {
        let res = response.data;
        if (res.status == true) {
          setServerInfo(`Server restarting...`);
          while (true) {
            var result = await new Promise(resolve=>check(resolve));
            console.log(result)
            if (result == true) {
              setServerInfo(`Server restarted.`);
              break;
            }
            await new Promise(resolve=>setTimeout(()=>resolve(1), 3000))
          }
          
        }
        else {
          setServerInfo(`Failed: ${res.error}`);
        }
      }
    })
    .catch(err => {
      setServerInfo(`Error: ${err}`);
      //alert("Can not connect to Admin Server.")
    })
  }
  const onStop = (evt) => {
    axios.get(token_admin_server_url + '/stop_merc')
    .then(async response => {
      if (response && response.data) {
        let res = response.data;
        if (res.status == true) {
          setServerInfo(`Server stopped.`);
        }
        else {
          setServerInfo(`Failed: ${res.error}`);
        }
      }
    })
    .catch(err => {
      setServerInfo(`Error: ${err}`);
      //alert("Can not connect to Admin Server.")
    })
  }
  const onBack = (evt) => {
    window.location.href = '/mercoin/admin'
  }

  return (
    <Stack sx={{ alignItems: "center", m: 2 }}>
      <Paper elevation={24} sx={{ p: 5, alignItems: "center", m: 2, width: "80%", minWidth: "800px", maxWidth: "1200px" }}>
        {MercBuildInfo != "" && <Alert onClose={() => {setMercBuildInfo("")}}>{MercBuildInfo}</Alert>}
        {NFTBuildInfo != "" && <Alert onClose={() => {setNFTBuildInfo("")}}>{NFTBuildInfo}</Alert>}
        {ServerInfo != "" && <Alert onClose={() => {setServerInfo("")}}>{ServerInfo}</Alert>}
        <Stack direction="column" spacing={3}>
          <Box component="span" sx={{ p: 2, border: '1px dashed grey' }}>
            <Typography variant="h5">Build Smart Contracts</Typography>
            <Stack direction="row" justifyContent="center" spacing={3} sx={{ mt: 3 }}>
              <Button variant="contained" onClick={onBuild20} sx={{ width: "300px", alignSelf: "center" }}>
                Rebuild MERc Token Contract
              </Button>
              <Button variant="contained" onClick={onBuild721} sx={{ width: "300px", alignSelf: "center" }}>
                Rebuild Accziom NFT Contract
              </Button>
            </Stack>
          </Box>
          <Box component="span" sx={{ p: 2, border: '1px dashed grey' }}>
            <Typography variant="h5">Start/Stop Server</Typography>
            <Stack direction="row" justifyContent="center" spacing={3} sx={{ mt: 3 }}>
              <Button variant="contained" onClick={onRestart} sx={{ width: "300px", alignSelf: "center" }}>
                Restart MERc server
              </Button>
              <Button variant="contained" onClick={onStop} sx={{ width: "300px", alignSelf: "center" }}>
                Stop MERc server
              </Button>
            </Stack>
          </Box>

          <Button variant="contained" onClick={onBack} sx={{ width: "200px", alignSelf: "center" }}>
            Back
          </Button>
        </Stack>
      </Paper>
    </Stack>

  )
}