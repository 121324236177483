import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  FormControl,
  Grid,
  InputBase,
  InputLabel,
  Link,
  MenuItem,
  Pagination,
  Stack,
  Typography,
  Container,
  Paper,
  Select,
  List,
  ListItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { TAX_GENII_URI } from "../common/config";
import { useGlobalState } from "../common/globalState";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import axios from "axios";

function CommentView(props) {
  const back_cahin_v1 = (e, ei) => {
    let t = e.thought.split("\n")[0];
    if (t.startsWith("Thought: ")) {
      t = t.slice(9, t.length);
    }
    return (
      <Box key={ei}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Thought</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{t}</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Decision</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>Action: {e.action}</Typography>
            <Typography>Action Input: {e.action_input}</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Observation</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {e.observation.split("\n").map((e, ei) => {
              if (e === "") return <Divider key={ei} />;
              if (e.startsWith("[Reference] https://")) {
                return (
                  <Box key={ei}>
                    <Typography>Reference: </Typography>
                    <Link target="_blank" href={e.slice(12, e.length)}>
                      {e.slice(12, e.length)}
                    </Link>
                  </Box>
                );
              }
              return <Typography key={ei}>{e}</Typography>;
            })}
          </AccordionDetails>
        </Accordion>
      </Box>
    );
  };

  const back_cahin_v2 = (element, element_index) => {
    let role = element.role;
    let content = element.content;
    if (role == undefined) return null;

    return (
      <Box key={element_index}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography sx={{textAlign:"left"}}>{role}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {content.split("\n").map((e, ei) => {
              if (e === "") return <Divider key={ei} />;
              if (e.startsWith("[Reference] https://")) {
                return (
                  <Box key={ei}>
                    <Typography sx={{textAlign:"left"}}>Reference: </Typography>
                    <Link target="_blank" href={e.slice(12, e.length)}>
                      {e.slice(12, e.length)}
                    </Link>
                  </Box>
                );
              }
              return <Typography key={ei} sx={{m:2, textAlign:"left"}}>{e}</Typography>;
            })}
          </AccordionDetails>
        </Accordion>
      </Box>
    );
  };

  return (
    <Container>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Scenario</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{textAlign:"left"}}>{props.scenario}</Typography>
        </AccordionDetails>
      </Accordion>
      {props.version !== "2.0" && (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Query</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{textAlign:"left"}}>{props.query}</Typography>
          </AccordionDetails>
        </Accordion>
      )}
      <Box sx={{ mt: 2 }}>
        {props.backchain &&
          props.backchain.map((e, ei) => back_cahin_v2(e, ei))}
      </Box>
      {false && (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Final Answer</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {props.answer.split("\n").map((e, ei) => (
              <Typography key={ei}>{e}</Typography>
            ))}
          </AccordionDetails>
        </Accordion>
      )}
      <Divider sx={{ mt: 1, mb: 1 }} />
      <Paper
        component="form"
        min-width="80%"
        sx={{
          flex: 1,
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          borderRadius: 2,
        }}
      >
        <Stack direction="column" sx={{ flex: 1 }}>
          <Typography sx={{textAlign:"left", m:1}}>Comment</Typography>
          <InputBase
            sx={{ ml: 3, flex: 1, display: "flex" }}
            multiline
            minRows={6}
            maxRows={6}
            value={props.comment}
          />
          <Typography sx={{textAlign:"left", m:1}}>Commenter Name</Typography>
          <InputBase
            sx={{ ml: 3, flex: 1, display: "flex" }}
            value={props.user}
          />
        </Stack>
      </Paper>
    </Container>
  );
}

export function ChatbotHistoryView(props) {
  const [state, dispatch] = useGlobalState();
  const [names, setNames] = useState([]);
  const [titles, setTitles] = useState([]);
  const [chatContent, setChatContent] = useState([]);
  const [historyCount, setHistoryCount] = useState(0);
  const [content, setContent] = useState({});
  const [currentName, setCurrentName] = useState("");
  const [currentTitle, setCurrentTitle] = useState("");
  const [curPage, setCurPage] = useState(1);

  useEffect(() => {
    if (names.length !== 0) return;
    getNameList();
  }, []);

  useEffect(() => {
    if (state.chatHistoryReload) {
      getNameList();
      dispatch({
        chatHistoryReload: false
      })
    }
  }, [state.chatHistoryReload]);

  const getNameList = async () => {
    let response = await axios.get(TAX_GENII_URI + "commenter_list");
    let result = response.data;
    if (result.status) {
      setCurrentName("");
      setNames(result.result);
    } else {
      setCurrentName("");
      setNames([]);
    }
  };

  const getChatList = async (name, page) => {
    setHistoryCount(0);
    let response = await axios.post(TAX_GENII_URI + "chat_history", {
      user: name,
      page_num: page,
    });

    let data = response.data;
    setHistoryCount(data.total_count);
    setChatContent(data.records);
    setTitles(
      data.records.map((e, index) => ({
        id: index,
        query: e.query,
      }))
    );
  };

  const changeName = (evt) => {
    let name = evt.target.value;
    setCurrentName(name);
    getChatList(name, 1);
  };

  const changeTitle = (index) => {
    setContent(chatContent[index]);
    setCurrentTitle(titles[index]);
  };

  const changePage = (evt, value) => {
    setCurPage(value);
    getChatList(currentName, value);
  };

  return (
    <Container
      component={Paper}
      sx={{
        mt: 4,
        mb: 4,
        display: props.show ? "block" : "none",
        minHeight: 400,
      }}
    >
      <Grid container spacing={1} direction="row">
        <Grid item xs={3}>
          <SelectName
            value={currentName}
            handleChange={changeName}
            content={names}
          />
          <SelectTitle
            value={currentTitle}
            page={curPage}
            count={Math.ceil(parseFloat(historyCount) / 10)}
            onChange={changePage}
            handleChange={changeTitle}
            content={titles}
          />
        </Grid>
        <Grid item xs={8}>
          <CommentView {...content} />
        </Grid>
      </Grid>
    </Container>
  );
}

function SelectName(props) {
  return (
    <Box sx={{ minWidth: 120, mb:2 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Commenter Name</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={props.value}
          label="Commenter Name"
          onChange={props.handleChange}
        >
          {props.content &&
            props.content.map((e,idx) => (
              <MenuItem key={idx} value={e}>
                {e}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Box>
  );
}

function SelectTitle(props) {
  return (
    <Box>
      <List 
        sx={{ 
          minWidth: 120, 
          minHeight: 400, 
          alignItems:"baseline",
          cursor: 'pointer'
        }}
      >
        {props.content &&
          props.content.map((e, index) => (
            <ListItem
              key={index} 
              value={e.id}
              onClick={evt=>props.handleChange(e.id)}
            >
              {e.query}
            </ListItem>
        ))}
      </List>
      <Pagination
        page={props.page}
        onChange={props.onChange}
        count={props.count}
        shape="rounded"
      />
    </Box>
  );
}
