import { Alert, AlertTitle, Button, CircularProgress, Grid, Paper, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { BSEARCH_URI } from "../common/config";
import { useGlobalState } from "../common/globalState";
import { Box } from "@mui/system";
import { CreateHeader, CreateProperty, refine_html_text, setGlobalVal } from "./common-tool";
import { MintNFTTBPView } from "./mintnfttbp";
import { v4 as uuidv4 } from 'uuid';

async function search_single_tpb(query, callback_fn, state) {
    let params = {};
    if(state.abn){
        params.abn = state.abn
    } else {
        params.query = query;
    }
    try {
        //alert(JSON.stringify(params));
        axios.post(
            BSEARCH_URI + "tpb_query", params
        )
            .then(response => {
                if (response && response.data) {
                    var result = response.data;

                    if (!result || Object.keys(result).length === 0) {
                        result = { 'error': "The data does not exist in TPB database that we collected." };
                    }

                    callback_fn(result);
                }
                else {
                    result = { 'error': "Unknowned error." };
                }

            })
    }
    catch (err) {
        var result = { 'error': err };
        callback_fn(result);
    }
}

async function search_tpb(query, callback_fn, state) {
    var first_query = "", first_result = {};
    for (var i = 0; i < query.length; i++) {
        let qi = query[i];
        let result = await new Promise(resolve => search_single_tpb(qi, resolve, state));
        if (result.error) {
            if (i === 0) {
                first_query = qi;
                first_result = result;
            }
            continue;
        }
        callback_fn(qi, result);
        return;
    }
    callback_fn(first_query, first_result)
}

export function TPBView(props) {
    const [state, dispatch] = useGlobalState();
    const [onSpin, setSpin] = useState(false);
    const [content, setContent] = useState({});
    const [query, setQuery] = useState([]);

    const getRDFString = async () => {
        let uri = state.rdfHistory.result.uri;
        if (uri === undefined) {
            return '';
        }

        var convert_table = {
          
          
        };

        let tpb = content[0];
        if (Object.keys(tpb).length === 0) return;

        let record = '';
        let id = uuidv4();
        let time = new Date();
        let time_str = time.toISOString();
        id = id + time_str
        if (tpb._id) {
            record += `
                <${uri}> azp:TPBID [
                    azp:value "${tpb._id}" ;
                    azpr:public "N" ;
                    azpr:resource "${id}" ;
                ] . 

                <${uri}> azp:personalTimestamp [
                    azp:value "${time_str}" ;
                    azpr:resource "${id}" ;
                    azpr:public "Y" ;
                ] . 
            `;
        }

        var keys = Object.keys(tpb);
        keys.forEach(key => {
            if (convert_table[key] == undefined) return;
            record += `
                <${uri}> azp:${convert_table[key]} [
                    azp:value "${tpb[key]}" ;
                    azpr:public "N" ;
                    azpr:resource "${id}" ;
                ] .
            `;
        });

        if (tpb.BUS_ADDRESS) {
            var bus_addr = refine_html_text(tpb.BUS_ADDRESS);

            var res = await axios.post(BSEARCH_URI + "query_address", {
                params: {
                    address: bus_addr,
                }
            });
            res = res.data;

            if (res.entity) {
                var value = res.entity;

                if (res.detailed_address != '') {
                    record += `
                        <${uri}> azp:locateAt [
                            azp:value ${value} ;
                            azp:details <${res.detailed_address}>
                            azpr:public "N" ;
                            azpr:resource "${id}" ;
                        ] .
                    `;
                } else {
                    record += `
                        <${uri}> azp:locateAt [
                            azp:value ${value} ;
                            azpr:public "N" ;
                            azpr:resource "${id}" ;
                        ] .
                    `;
                }

            }
        }
        return { id, time: time_str, record: record };
    }

    useEffect(() => {
        var q = "";
        if (state.entityName.length > 0) {
            q = state.entityName
        }

        if (q === "") {
            setContent([]);
            setSpin(false);
            return;
        }

        setQuery(q);

    }, [state.entityName, query, dispatch, state.acnResult, state])

    useEffect(() => {
        if (query.length === 0) return;
        if (state.tpbHistory.query in query) {
            setContent(state.tpbHistory.result)
            setSpin(false);
            return;
        }
        setSpin(true);

        search_tpb(query, (qi, result) => {
            dispatch({
                tpbHistory: { query: qi, result: result }
            })
            var key_list = Object.keys(result)
            if (key_list.length === 0) {
                result.error = 'The data does not exist in TPB Database.'
            }
            key_list.forEach(k => {
                var val = result[k];
                setGlobalVal(k, val, state, dispatch);
            });
     
            setContent(result);
            setSpin(false);

        }, state)
    }, [query])

    if (onSpin) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <CircularProgress size={50} />
            </Box>
        )
    } else {

        return (
            <div>

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant='h5'>
                            TPB Search Result
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography sx={{ pl: 4, pr: 2, textAlign: 'left' }}>
                            This pane displays the information of legal entity from Tax Practitioners Board(TPB) databse.
                        </Typography>
                        <Typography sx={{ pl: 4, pr: 2, textAlign: 'left' }}>
                            <b>Caution</b>: The search result may not be what you expected, because it is retrieved by legal name.
                        </Typography>
                    </Grid>

                    <Grid item xs={30}>
                        <Paper sx={{ p: 4 }}>
                            {
                                content.error ?
                                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                        <Alert severity="info">
                                            <AlertTitle>Info</AlertTitle>
                                            {content.error}
                                        </Alert>
                                    </Paper>
                                    :
                                    CreateProperty('', content, null)
                            }

                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper sx={{ p: 4 }} >
                            <MintNFTTBPView uri={state.rdfHistory.result.uri} getRDFString={getRDFString} data={content} />
                        </Paper>
                    </Grid>
                </Grid>

            </div>

        )
    }
}