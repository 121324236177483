// module.exports = {
//   BSEARCH_URI: "http://localhost:8007/",
//   ANZIC_INFO_URI: "http://localhost:8011/",
//   ANZIC_WS_URL: "wss://localhost:8018",
//   MERC_TOKEN_WS_URL: "ws://localhost:8889",
//   PROFILE_SERVER_URL: "http://localhost:8999/",
//   TAX_GENII_URI: "https://localhost:5053/",
//   AZURE_SEARCH_URI: "https://localhost:5053/",
//   // AZURE_SEARCH_URI: "https://bsearchau.accziom.com:5053/",
//   // BSEARCH_URI: "https://bsearchau.accziom.com:8007/",
//   // ANZIC_INFO_URI: "https://bsearchau.accziom.com:8011/",
//   // ANZIC_WS_URL: 'wss://bsearchau.accziom.com:8018',
//   // MERC_TOKEN_WS_URL: 'wss://bsearchau.accziom.com:8889',
//   // PROFILE_SERVER_URL: "https://bsearchau.accziom.com:8999/",
//   GBG_API_COST: 52000,
//   BING_API_COST: 1000,
//   TAX_GENII_URI: "https://bsearchau.accziom.com:5053/"
// };

module.exports = {

  BSEARCH_URI: "https://backend.bsearchau.accziom.com/",
  ANZIC_INFO_URI: "https://backend.bsearchau.accziom.com:8011/",
  ANZIC_WS_URL: "wss://backend.bsearchau.accziom.com:8018",
  MERC_TOKEN_WS_URL: "wss://backend.bsearchau.accziom.com:8889",
  PROFILE_SERVER_URL: "https://backend.bsearchau.accziom.com:8999/",
  TAX_GENII_URI: "https://54.66.223.206:5053/",
  AZURE_SEARCH_URI: "https://54.66.223.206:5053/",

  // AZURE_SEARCH_URI: "https://bsearchau.accziom.com:5053/",
  // BSEARCH_URI: "https://bsearchau.accziom.com:8007/",
  // ANZIC_INFO_URI: "https://bsearchau.accziom.com:8011/",
  // ANZIC_WS_URL: 'wss://bsearchau.accziom.com:8018',
  // MERC_TOKEN_WS_URL: 'wss://bsearchau.accziom.com:8889',
  // PROFILE_SERVER_URL: "https://bsearchau.accziom.com:8999/",
  GBG_API_COST: 52000,
  BING_API_COST: 1000,
  // TAX_GENII_URI: "https://bsearchau.accziom.com:5053/"
};

// module.exports = {
//   BSEARCH_URI: "https://backend.bsearchau.accziom.com/",
//   ANZIC_INFO_URI: "https://backend.bsearchau.accziom.com:8011/",
//   ANZIC_WS_URL: "wss://backend.bsearchau.accziom.com:8018",
//   MERC_TOKEN_WS_URL: "wss://backend.bsearchau.accziom.com:8889",
//   PROFILE_SERVER_URL: "https://backend.bsearchau.accziom.com:8999/",
//   TAX_GENII_URI: "https://backend.bsearchau.accziom.com:5053/",
//   AZURE_SEARCH_URI: "https://backend.bsearchau.accziom.com:5053/",
//   // AZURE_SEARCH_URI: "https://bsearchau.accziom.com:5053/",
//   // BSEARCH_URI: "https://bsearchau.accziom.com:8007/",
//   // ANZIC_INFO_URI: "https://bsearchau.accziom.com:8011/",
//   // ANZIC_WS_URL: 'wss://bsearchau.accziom.com:8018',
//   // MERC_TOKEN_WS_URL: 'wss://bsearchau.accziom.com:8889',
//   // PROFILE_SERVER_URL: "https://bsearchau.accziom.com:8999/",
//   GBG_API_COST: 52000,
//   BING_API_COST: 1000,
//   // TAX_GENII_URI: "https://bsearchau.accziom.com:5053/"
// };