import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Alert, Button, Paper, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import "./TaxGeniiApi.css"
import { TAX_GENII_URI } from '../../common/config';
import axios from 'axios';
import MarkdownPreview from '@uiw/react-markdown-preview';
import api_doc from './api_doc.md'

const drawerWidth = 240;


const TaxGeniiRequestApiKey = (props) => {
  const {register, handleSubmit} = useForm();
  const [message, setMessage] = React.useState("");
  const [messageType, setMessageType] = React.useState("info")
  const [key, setKey] = React.useState("")

  const onSubmit = (d) => {
    axios.post(TAX_GENII_URI + "request_key", d)
    .then(response=>{
      let msg = response.data;
      setMessage(msg.message)
      if (msg.success && msg.data) {
        setKey(msg.data.key)
        if (msg.data.activated === true) {
          setMessageType("success")
        } else {
          setMessageType("warning")
        }
        
      } else {
        setKey("")
        setMessageType("error")
      }
    })
  }

  return (
    <Box sx={{display:"flex", justifyContent:'center', alignContent: 'center', alignItems: 'center', textAlign: "center", width: 600}}>

    <form onSubmit={handleSubmit(onSubmit)} id="msform">
      {
        message !== "" &&
        <Alert severity={messageType} sx={{mb:2}}>{message}</Alert>
      }
      <div id="field">
        <label>
          E-mail
          <input {...register('email')}/>
        </label>
        <label>
          IP address
          <input {...register('ip')}/>
        </label>
      </div>
      <input type="submit" value="Submit" className="next action-button"/>
      {
        key !== "" && 
        <div id="field">
          <label>
            API Key
            <input type={"text"} value={key}/>
          </label>
        </div>
      }
    </form>
    </Box>
    
  )
}

const TaxGeniiApiDoc = (props) => {
  const [content, setContent] = React.useState('')

  React.useEffect(()=>{
    fetch(api_doc)
    .then(data => data.text())
    .then(text => {
      setContent(text)
    })
  }, [])
  
  
  return (
    <Box sx={{textAlign: "left"}}>
      <MarkdownPreview
        source={content}
      >
      </MarkdownPreview>
    </Box>
    
  )
}

const itemlist = [
  {
    text: 'Request API Key', 
    icon: <MailIcon/>,
    view: <TaxGeniiRequestApiKey/>
  },
  {
    text: 'Using API', 
    icon: <InboxIcon/>,
    view: <TaxGeniiApiDoc/>
  },
]

export default function TaxGeniiApi(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [view, SetView] = React.useState(<TaxGeniiRequestApiKey/>);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        {itemlist.map((item, index) => (
          <ListItem key={item.text} disablePadding>
            <ListItemButton onClick={evt=>SetView(item.view)}>
              <ListItemIcon>
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Tax Genii API
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` }}}
      >
        <Toolbar />
        <Box >
        {
          view
        }
        </Box>
      </Box>
    </Box>
  );
}

TaxGeniiApi.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
