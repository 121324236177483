import React, { useEffect, useState } from "react";
import { Budget } from "./components/budget";
import { useGlobalState } from '../common/globalState';
import mercLogo from "../icons/merc-logo.png";

export const TotalSupply = () => {
    const [state, dispatch] = useGlobalState();
    const [val, setVal] = useState("...");
    const [subVal, setSubVal] = useState(null);



    useEffect(() => {
        try {
            setVal(state?.totalSupply?.toLocaleString("en-US"));

            console.log(`mercReserves=${state.mercReserves}`)
            console.log(`layer2Total=${state.layer2Total}`)
            var si = [];
            si.push({
                title: "MERc Reserves",
                content: state.mercReserves.toLocaleString("en-US"),
                tooltip: `When making a deposit to Layer2, the amounts of MERc will be reserved from user account to MERc Reserve acoount temporarily. 
    When withdrawing to Layer1, the amount of MERc will be returned to user account.
    MERc Reserves should equal to total amount of Layer2.`
            })
            si.push({
                title: "Sum of Layer1",
                content: (state?.totalSupply - state.layer2Total).toLocaleString("en-US"),
                tooltip: "Total amount of Layer1."
            })
            si.push({
                title: "Sum of Layer2",
                content: state.layer2Total.toLocaleString("en-US"),
                tooltip: "Total amount of Layer2."
            })

            setSubVal(si);
        } catch (error) {
            console.log(error)
        }

    }, [state?.totalSupply, state.mercReserves, state.layer2Total, state.exchangeBalance])

    return (
        <Budget
            title="MERc TotalSupply"
            value={val}
            up="true"
            percent="1%"
            image={mercLogo}
            alt="merc-reserve"
            sub_items={subVal}
            type="merc"
        />
    )

}